import { parseISO, isAfter } from "date-fns";
import { getFormattedDate, getOffsetDate } from "./dateUtils";

const formatDate = date => getFormattedDate(date, "EEEE, d MMMM");

export function getDayEntries(date, entries) {
  const dateString = formatDate(date);
  const dayEntries = entries.filter(
    entry => formatDate(parseISO(entry.time)) === dateString
  );

  return {
    slot: dayEntries.filter(entry => entry.type === "TASK"),
    scheduled: dayEntries.filter(
      entry => entry.type === "POST" && entry.post.status === "SCHEDULED"
    ),
    drafted: dayEntries
      .filter(entry => entry.type === "DRAFT_POST")
      .map(entry => {
        return {
          ...entry,
          post: entry.draft,
          isDraft: true
        };
      }),
    grouped: dayEntries
      .filter(entry => entry.type === "POST_GROUP")
      .map(entry => {
        return {
          ...entry,
          post: entry.post,
          isPostGroup: true
        };
      }),
    posted: dayEntries.filter(
      entry => entry.type === "POST" && entry.post.status === "SENT"
    )
  };
}

export function getEntriesGrouped(entries, period, labels = {}) {
  const { today, yesterday } = labels;
  const groups = [];
  let index = period.start;

  while (isAfter(period.end, index)) {
    const indexDate = formatDate(index);

    const dateEntries = entries.filter(
      entry => formatDate(parseISO(entry.time)) === indexDate
    );

    groups.push({
      date: index,
      dateName:
        indexDate === today
          ? "Today"
          : indexDate === yesterday
          ? "Yesterday"
          : indexDate,
      entries: dateEntries
    });

    index = getOffsetDate(index, { days: 1 });
  }

  return groups;
}
