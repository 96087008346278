import { Box } from "rebass";
import styled, { css } from "styled-components";

import { PostType } from "components/schedule/timeline/context";
import draftStackBanner from "assets/images/draft-stack-banner.jpg";
import defaultStackBanner from "assets/images/default-stack-banner.jpg";
import { PostContextMenuWrapper } from "components/schedule/common/postContextMenu/styles";

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Author = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
  line-height: 15.6px;
  font-weight: 400;
  color: ${props => props.theme.colors.text03};

  .author_name {
    font-weight: 600;
    margin-left: 2px;
  }
`;

interface IEntryWrapper {
  isGroup?: boolean;
  noHover?: boolean;
  isPhantom?: boolean;
  isDragging?: boolean;
  isContextMenuOpen?: boolean;
}

export const EntryWrapper = styled(Box)<IEntryWrapper>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: auto;
  width: 100%;
  padding: 16px;
  border-radius: 24px;
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadows.shadowXS};
  cursor: pointer;

  * {
    box-sizing: border-box;
  }

  ${props =>
    props.isContextMenuOpen &&
    css`
      outline: 1px solid ${props => props.theme.colors.black};
    `}

  ${props =>
    !props.noHover &&
    css`
      &:hover {
        outline: 1px solid ${props => props.theme.colors.grey04};
        box-shadow: ${props => props.theme.shadows.shadowS};
      }
    `}

  ${props =>
    props.isGroup &&
    css`
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14), 0 8px 0 -4px #ffffff,
        0 9px 2px -3px rgba(0, 0, 0, 0.12), 0 14px 0 -6px #ffffff,
        0 16px 4px -7px rgba(0, 0, 0, 0.16), 0 4px 8px 0 rgba(0, 0, 0, 0.13);

      &:hover {
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.14), 0 8px 0 -4px #ffffff,
          0 9px 2px -3px rgba(0, 0, 0, 0.12), 0 14px 0 -6px #ffffff,
          0 16px 4px -7px rgba(0, 0, 0, 0.16), 0 4px 8px 0 rgba(0, 0, 0, 0.26);
      }
    `};

  ${props =>
    props.isDragging &&
    css`
      opacity: 1;
      box-shadow: 0px 4px 24px rgba(28, 30, 31, 0.08),
        0px 1px 2px rgba(28, 30, 31, 0.04);
      outline: 1px solid ${props => props.theme.colors.black};
    `};

  ${props =>
    props.isPhantom &&
    css`
      opacity: 0.5;
      outline: 1px solid ${props => props.theme.colors.black};
    `};

  &:hover {
    ${PostContextMenuWrapper} {
      display: flex;
    }
  }
`;

export const PublishedEntryHeader = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const PublishedEntryInfo = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const PublisherInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;

  .username {
    color: ${props => props.theme.colors.text01};
    font-size: 14px;
    line-height: 17.5px;
    font-weight: 700;
  }

  .time {
    color: ${props => props.theme.colors.text02};
    font-size: 12px;
    line-height: 15.6px;
    font-weight: 400;
  }
`;

export const EntryContent = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const EntryStats = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(4, 1fr);
  height: 26px;

  .stats {
    display: flex;
    gap: 6px;
    align-items: center;

    > i {
      color: ${props => props.theme.colors.text03};
      font-size: 24px;
    }

    .merged-icons {
      display: flex;

      i {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        font-size: 12px;
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.status.red};
        border: 1.3px solid ${props => props.theme.colors.white};

        &:first-child {
          z-index: 1;
          background-color: ${props => props.theme.colors.blue};
        }

        &:last-child {
          margin-left: -3.78px;
        }
      }
    }
  }
`;

interface IEmptyEntryCover {
  postType: PostType | null;
}

export const EmptyEntryCover = styled.div<IEmptyEntryCover>`
  height: 160px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: -16px -16px 0 -16px;
  background-size: cover;
  ${props =>
    props.postType === "drafts"
      ? css`
        background-image: url("${draftStackBanner}");
      `
      : css`
        background-image: url("${defaultStackBanner}");
      `};
`;

export const EmptyContentBlock = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 32px 0 24px 0;

  .empty-title {
    color: ${props => props.theme.colors.text01};
    font-weight: 700;
    line-height: 22.5px;
  }

  .empty-subtitle {
    color: ${props => props.theme.colors.text03};
    font-weight: 400;
    line-height: 19.6px;
    width: 320px;
    text-align: center;
  }

  .button-grp {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 348px;
  }
`;

export const ImageAttachmentWrapper = styled.div`
  display: flex;
  max-width: 498px;
  flex: 1;
  border-radius: 8px;
  overflow: hidden;
`;
