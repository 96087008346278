import { Flex } from "rebass";
import React, { useMemo } from "react";
import styled, { css } from "styled-components";

import { MetaInformation } from "../styles";
import TagGroup from "components/common/tag";
import { ITag } from "components/common/tag/types";
import { useAppState } from "contextApi/appContext";
import ContentItemOperations from "../widgets/ContentItemActions.mobile";

interface IContentItemFooterProps {
  id: string;
  date: string;
  onHover?: boolean;
  viewOnly: boolean;
  wasSaved: boolean;
  wasLiked: boolean;
  wasPosted: boolean;
  orientation?: string;
  handleDismiss: () => void;
  predicted_tags: Array<ITag>;
  handleSaveClick: () => void;
  handleLikeContent: () => void;
  handleScheduleClick: () => void;
  handlePreviewArticle: () => void;
}

const Status = styled(Flex)<{ status: string | null }>`
  gap: 4px;
  align-items: center;
  justify-content: center;
  ${props => {
    switch (props.status) {
      case "posted":
        return css`
          color: ${props.theme.colors.green};
        `;
      case "saved":
      default:
        return css`
          color: ${props.theme.colors.orange};
        `;
    }
  }}

  > span {
    font-size: 18px;
  }
`;

const ContentItemFooter: React.FC<IContentItemFooterProps> = React.memo(
  ({
    id,
    viewOnly,
    wasSaved,
    wasLiked,
    wasPosted,
    orientation,
    handleDismiss,
    predicted_tags,
    handleSaveClick,
    handleLikeContent,
    handleScheduleClick,
    handlePreviewArticle
  }) => {
    const windowSize = useAppState(state => state.windowSize);

    const numberOfTags = useMemo(() => (orientation === "landscape" ? 2 : 1), [
      orientation
    ]);
    const isMobile = useMemo(
      () => ["small", "xsmall"].includes(windowSize ?? ""),
      [windowSize]
    );

    return (
      <MetaInformation
        // @ts-ignore
        hasTags={predicted_tags.length}
        isSaved={wasSaved || wasPosted}
      >
        <TagGroup tags={predicted_tags.slice(0, numberOfTags)} />
        {(wasPosted || wasSaved) && !isMobile ? (
          <Status status={wasPosted ? "posted" : wasSaved ? "saved" : null}>
            <span
              className={wasPosted ? "icon-send" : "icon-bookmark-filled-20"}
            />
            {wasPosted ? "Posted" : "Saved"}
          </Status>
        ) : null}
        {isMobile && !viewOnly ? (
          <ContentItemOperations
            id={id}
            saved={wasSaved}
            liked={wasLiked}
            onDislike={handleDismiss}
            onLike={handleLikeContent}
            onPost={handleScheduleClick}
            onBookmark={handleSaveClick}
            onPreviewArticle={handlePreviewArticle}
          />
        ) : null}
      </MetaInformation>
    );
  }
);

export default ContentItemFooter;
