import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { Flex, Box } from "rebass/styled-components";
import { useSelector, useDispatch } from "react-redux";
import { UserPlus } from "styled-icons/boxicons-regular";
import { Switch, Route, Redirect } from "react-router-dom";
import React, { useEffect, useState, useCallback } from "react";

import { callApi } from "utils/ContentoApi";
import { statsAggregator } from "utils/stats.js";
import { useAppState } from "contextApi/appContext";
import { useContentoApi } from "utils/useContentoApi";
import EmptyState from "components/common/EmptyState";
import AnalyticsMenu from "components/analytics/AnalyticsMenu";
import AnalyticsPosts from "components/analytics/AnalyticsPosts";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import AnalyticsOverview from "components/analytics/AnalyticsOverview";
import AnalyticsChannels from "components/analytics/AnalyticsChannels";
import { useNotificationActions } from "contextApi/notificationContext";
import { periodMenuItems } from "components/analytics/widgets/PeriodSelect";
import { willowExtensionFeatureNotification } from "components/modals/pop-ups/featureNotifications";

const AnalyticsContainer = styled(Box)`
  background-color: ${props => props.theme.colors.background};
  min-height: calc(100vh - 72px);
`;

const AnalyticsPage = React.memo(({ match }) => {
  const dispatch = useDispatch();
  const account = useSelector(state => state.account.data);
  const history = useHistory();
  const showWillowExtension = useAppState(state => state.showWillowExtension);
  const addFeatureNotification = useNotificationActions(
    actions => actions.addFeatureNotification
  );

  const [isLoading, setLoading] = useState(true);

  const [period, setPeriod] = useState(90);
  const [sortBy, setSortBy] = useState("engagements");

  const [selectedChannels, setSelectedChannels] = useState(
    account.channels.map(channel => channel.id)
  );

  const [perChannelStats, setPerChannelStats] = useState(null);
  const [aggregatedStats, setAggregatedStats] = useState(null);
  const [employeeStats, setEmployeeStats] = useState([]);
  const [postStats, setPostStats] = useState([]);

  useEffect(() => {
    dispatch(
      trackAnalyticsEvent("Analytics Viewed", {
        type:
          history.location.pathname.match(
            /^.*\/accounts\/[^/]*\/analytics\/([^/?]*).*$/
          )?.[1] ?? "",
        timeframe: periodMenuItems.find(item => item.value === period).label
      })
    );
  }, [dispatch, history.location.pathname, period]);

  const [fetchStats] = useContentoApi(
    `/accounts/${account.id}/stats/channels-new`
  );

  const handleTabClick = value => {
    history.push(match.url + "/" + value);
  };

  const refreshStats = useCallback(
    selectedChannels => {
      setLoading(true);

      fetchStats({ params: { periodInDays: period } }).then(
        stats => {
          const filteredStats = (stats || []).filter(c =>
            selectedChannels.includes(c.id)
          );

          if (account.channels.length > 0) {
            setAggregatedStats(statsAggregator(filteredStats, period));
          }
          setPerChannelStats(filteredStats);
          setEmployeeStats(
            filteredStats.filter(
              c => `${c.service}_${c.serviceType}` === "linkedin_profile"
            )
          );

          setLoading(false);
        },
        reason => console.error(reason)
      );
    },
    [fetchStats, period, account.channels.length]
  );

  const refreshPosts = useCallback(
    selectedChannels => {
      let postsUrl = `/accounts/${account.id}/stats/posts-new`;
      if (selectedChannels.length !== account.channels.length) {
        postsUrl += `?channelId=${selectedChannels.join("&channelId=")}`;
      }

      callApi({
        url: postsUrl,
        params: { periodInDays: period, sort: sortBy }
      })
        .then(posts => setPostStats(posts))
        .catch(err => console.error(err));
    },
    [period, sortBy, account.channels.length, account.id]
  );

  useEffect(() => {
    refreshStats(selectedChannels);
    refreshPosts(selectedChannels);
  }, [refreshStats, refreshPosts, selectedChannels]);

  useEffect(() => {
    if (!!account.id && showWillowExtension) {
      addFeatureNotification(willowExtensionFeatureNotification);
    }
  }, [account.id, addFeatureNotification, showWillowExtension]);

  if (account.channels.length === 0 || !aggregatedStats || !perChannelStats)
    return (
      <EmptyState
        loading={isLoading}
        header={"Connect your social channels"}
        description={
          "To see your analytics, please connect one social media account first."
        }
        icon={{
          component: UserPlus,
          width: "48px",
          height: "48px"
        }}
        cta={{
          text: "Add channel",
          onClick: () =>
            history.push(`/accounts/${account.id}/settings/channels`)
        }}
      />
    );

  return (
    <AnalyticsContainer>
      <AnalyticsMenu
        onTabClick={handleTabClick}
        onPeriodSelect={setPeriod}
        period={period}
        loading={isLoading}
      />
      <Flex flexDirection="column" alignItems="center">
        <Switch>
          <Route
            path={match.url + "/overview"}
            render={() => (
              <AnalyticsOverview
                loading={isLoading}
                data={aggregatedStats}
                period={period}
                channels={account.channels}
                perChannel={perChannelStats}
                employees={employeeStats}
                selectedChannels={selectedChannels}
                onChannelSelect={setSelectedChannels}
              />
            )}
          />
          <Route
            path={match.url + "/channels"}
            render={() => <AnalyticsChannels />}
          />
          <Route
            path={match.url + "/posts"}
            render={() => (
              <AnalyticsPosts
                loading={isLoading}
                data={postStats}
                channels={account.channels}
                selectedChannels={selectedChannels}
                sortBy={sortBy}
                onChannelSelect={setSelectedChannels}
                onSortChange={setSortBy}
              />
            )}
          />
          <Redirect to={match.url + "/overview"} />
        </Switch>
      </Flex>
    </AnalyticsContainer>
  );
});

export default AnalyticsPage;
