import React from "react";
import { ErrorMessage, HelperText, IconExclamationCircle, StyledTextArea } from './styles';

import { Box } from "rebass/styled-components";
import { Caption2 } from "../styles";



const TextAreaInput = ({ input, label, meta, type, helperText }) => {
  const { touched, error } = meta;

  return (
    <Box width={1} mb={24}>
      <Caption2 fontWeight="bold">{label}</Caption2>
      {helperText && <HelperText>{helperText}</HelperText>}
      <StyledTextArea type={type} {...input} />
      {error && touched && (
        <ErrorMessage>
          <div>
            <IconExclamationCircle />
            {error}
          </div>
        </ErrorMessage>
      )}
    </Box>
  );
};

export default TextAreaInput;
