import React, { useState, useEffect, useCallback } from "react";
import { Link, useHistory } from "react-router-dom";
import Carousel from "components/common/Carousel";
import styled, { css } from "styled-components";
import ContentItem from "../../content/ContentItem";
import { Box, Flex } from "rebass/styled-components";
import { callApi } from "utils/ContentoApi";
import { DashboardTitle } from "../../../containers/dashboard/styles";
import { RightArrowAlt } from "@styled-icons/boxicons-regular";
import RecommendedDomains from "components/content/recommendations/domains";
import RecommendedInfluencers from "components/content/recommendations/influencers";
import BuildingBlocks from "components/content/buildingBlocks";
import KeywordIcon from "assets/images/contents/Keyword-Monitor.png";

import { InfluenersWrapper } from "./styles";
import SeeMore from "components/common/seeMore";

const DashboardContentWrapper = styled(Box)`
  background-color: #f2f4f5;
  border-radius: 12px;
  padding: 16px 0;
`;

const DashboardContentList = styled(Box)`
  padding: 12px 32px 16px;
`;

const StyledInfluencer = styled(Flex)`
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  min-width: 80px;

  ${props =>
    props.isSelected &&
    css`
      &::after {
        border-color: transparent transparent #f2f4f5 transparent;
        border-style: solid;
        border-width: 0 14px 16px 14px;
        content: "";
        display: block;
        height: 0;
        width: 0;
      }
    `}
`;

const InfluencerAvatar = styled.figure`
  align-items: center;
  border-radius: 90px;
  background-color: ${props => props.theme.colors.grey01};
  box-shadow: ${props =>
    props.hasNewContent
      ? `0 0 0 1px ${props.theme.colors.grey02}, 0 0 0 2px white, 0 0 0 4px ${props.theme.colors.primary}`
      : props.isIcon
      ? "none"
      : `0 0 0 1px ${props.theme.colors.grey02}`};
  display: flex;
  height: 64px;
  justify-content: center;
  overflow: hidden;
  width: 64px;
`;

const InfluencerName = styled.span`
  color: ${props =>
    props.isSelected ? props.theme.colors.primary : props.theme.colors.text02};
  font-weight: ${props => (props.isSelected ? "700" : "400")};
  display: block;
  font-size: 10px;
  letter-spacing: -0.15px;
  margin: 8px 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  max-width: 64px;
  width: 100%;
`;

const InfluencerLink = styled(Link)`
  font-weight: bold;

  :hover {
    text-decoration: underline;
  }
`;

function Influencer({ idx, item, selectedInfluencer, onClick }) {
  const [isSeen, setSeen] = useState(!item.hasNewContent);
  const handleClick = () => {
    setSeen(true);
    onClick(idx);
  };

  return (
    <StyledInfluencer
      isSelected={
        selectedInfluencer && selectedInfluencer.section.id === item.section.id
      }
      onClick={handleClick}
    >
      <InfluencerAvatar hasNewContent={!isSeen}>
        {item.sectionType === "keywords" ? (
          <img src={KeywordIcon} alt={`${item.section.name}`} width="64" />
        ) : (
          <img
            src={item.section.image}
            alt={`${item.section.title ||
              item.section.handle ||
              item.section.domain}`}
            width="64"
          />
        )}
      </InfluencerAvatar>
      <InfluencerName
        isSelected={
          selectedInfluencer &&
          selectedInfluencer.section.id === item.section.id
        }
      >
        {item.section.title ||
          item.section.handle ||
          item.section.domain ||
          item.section.name}
      </InfluencerName>
    </StyledInfluencer>
  );
}

function InfluencerSeeMore({ onClick }) {
  return (
    <StyledInfluencer onCLick={onClick}>
      <InfluencerAvatar isIcon>
        <RightArrowAlt size={24} color={"rgb(51, 51, 51)"} />
      </InfluencerAvatar>
      <InfluencerName>See more</InfluencerName>
    </StyledInfluencer>
  );
}

function InfluencerEmptyState() {
  return (
    <>
      <BuildingBlocks exclude={["topics"]} />
      <RecommendedInfluencers />
      <RecommendedDomains hideSeeAll={false} />
    </>
  );
}

function InfluencersPage({ contents = [], accountId, hasTopBorder = true }) {
  const history = useHistory();
  const [influencerContent, setInfluencerContent] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [unseenCount, setUnseenCount] = useState(0);

  const handleInfluencerClick = idx => {
    let influencer = influencerContent[idx];
    setSelectedInfluencer(influencer);
    markInfluencerSeen(influencer.section.id);
  };

  const markInfluencerSeen = useCallback(
    sourceId => {
      callApi({
        method: "put",
        url: `/accounts/${accountId}/news/sections/sources/${sourceId}/last-seen-date`
      });
    },
    [accountId]
  );

  useEffect(() => {
    if (influencerContent.length > 0) {
      let updatedInfluencer = selectedInfluencer
        ? influencerContent.find(
            influencer =>
              influencer.section.id === selectedInfluencer.section.id
          )
        : influencerContent[0];
      setSelectedInfluencer(updatedInfluencer);
      markInfluencerSeen(updatedInfluencer.section.id);
    }
  }, [influencerContent, selectedInfluencer, markInfluencerSeen]);

  useEffect(() => {
    let sortedInfluencers = contents.sort((x, y) =>
      x.hasNewContent === y.hasNewContent ? 0 : x.hasNewContent ? -1 : 1
    );
    let limit = sortedInfluencers.reduce(
      (acc, cur) => (cur.hasNewContent ? acc + 1 : acc),
      0
    );
    setUnseenCount(limit);
    setInfluencerContent(sortedInfluencers);
  }, [contents]);

  const sourceLink = (function() {
    if (!selectedInfluencer?.section) return;

    let topicUrl = "";

    const { type, id } = selectedInfluencer.section;

    if (type === "twitter") {
      topicUrl = `/accounts/${accountId}/content/influencers/${id}`;
    } else if (type === "rss") {
      topicUrl = `/accounts/${accountId}/content/rss/${id}`;
    } else if (selectedInfluencer.sectionType === "keywords") {
      topicUrl = `/accounts/${accountId}/content/keywords/${id}`;
    } else {
      topicUrl = `/accounts/${accountId}/content/domains/${id}`;
    }
    return topicUrl;
  })();

  return influencerContent.length > 0 ? (
    <InfluenersWrapper hasTopBorder={hasTopBorder}>
      <DashboardTitle>
        Your influencers and sources
        <small>The latest stories shared by your industry leaders</small>
      </DashboardTitle>
      <Box>
        <Flex maxWidth="100%" paddingTop="4px" overflowX="auto">
          {influencerContent
            .slice(0, Math.max(9, unseenCount))
            .map((item, idx) => (
              <Influencer
                idx={idx}
                key={item.section.id}
                item={item}
                selectedInfluencer={selectedInfluencer}
                onClick={handleInfluencerClick}
              />
            ))}
          {influencerContent.length > Math.max(9, unseenCount) && (
            <InfluencerSeeMore
              onClick={() =>
                history.push(`/accounts/${accountId}/content/influencers`)
              }
            />
          )}
        </Flex>
        <Box>
          {selectedInfluencer && (
            <DashboardContentWrapper>
              <Flex
                paddingX={32}
                alignItems="center"
                justifyContent="space-between"
              >
                <span>
                  New links from{" "}
                  <InfluencerLink to={sourceLink}>
                    {selectedInfluencer.section.title ||
                      selectedInfluencer.section.handle ||
                      selectedInfluencer.section.domain ||
                      selectedInfluencer.section.name}
                  </InfluencerLink>
                </span>
                <SeeMore
                  handleClick={() => {
                    history.push(sourceLink);
                  }}
                />
              </Flex>
              <DashboardContentList>
                <Carousel
                  items={selectedInfluencer.contents}
                  defaultItemWidth={270}
                >
                  {selectedInfluencer.contents.map(item => (
                    <ContentItem
                      key={item.id}
                      content={item}
                      isNew={item.isNew ?? false}
                      sourceId={selectedInfluencer.section?.id}
                      showDescription={false}
                      tooltipPlacement="bottom"
                      variant="small"
                      enableDismiss={true}
                    />
                  ))}
                </Carousel>
              </DashboardContentList>
            </DashboardContentWrapper>
          )}
        </Box>
      </Box>
    </InfluenersWrapper>
  ) : (
    <InfluencerEmptyState />
  );
}

export default InfluencersPage;
