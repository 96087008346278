import React from "react";
import ReactLoading from "react-loading";
import styled, { css } from "styled-components";

const ReactLoader = styled(ReactLoading)`
  display: flex;
  padding: 0px 5px;
  z-index: 9;
  ${props =>
    props.location === "center" &&
    css`
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}

  ${props =>
    props.progress &&
    props.type === "spin" &&
    css`
    &:after {
      content: "${props.progress}%";
      position: absolute;
      bottom: -18px;
      left: 50%;
      font-size: 10px;
      transform: translate(-50%, -50%);
    }
  `}
`;

const Loader = ({
  type = "spin",
  color = "#bbb",
  location = "",
  size = 64,
  progress = null
}) => {
  return (
    <ReactLoader
      color={color}
      type={type}
      location={location}
      height={size}
      width={size}
      progress={progress}
    />
  );
};

export default Loader;
