import styled, { css } from "styled-components";

import Button from "components/common/Button";

export const SchedulerWrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  width: 100%;

  * {
    box-sizing: border-box;
  }
`;

export const SchedulerButton = styled(Button)`
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px 0px 0px 20px;

  &:focus {
    transition: none;
    box-shadow: unset !important;
  }

  > div {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    justify-content: center;

    > i {
      margin-right: 8px;
      font-size: 20px;
      position: relative;
    }

    > div.btn-content-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      position: relative;
      white-space: nowrap;

      &:after {
        content: attr(data-text);
        font-size: 10px;
        font-weight: normal;
        font-style: italic;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
        line-height: 0px;
        visibility: hidden;
      }

      > span.timezone-alert {
        position: absolute;
        font-size: 10px;
        font-weight: normal;
        font-style: italic;
        left: 0px;
        bottom: -14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-align: left;
      }
    }
  }
`;

SchedulerButton.defaultProps = {
  variant: "success"
};

export const SchedulerDropdown = styled(Button)`
  width: 42.5px;
  display: flex;
  padding-left: 9px;
  margin-left: 1.5px;
  align-items: center;
  justify-content: center;
  border-radius: 0px 24px 24px 0px;

  ${props =>
    props.isOptionsVisible &&
    css`
      background: ${props =>
        props.variant === "success"
          ? props.theme.variants.success.active.bg
          : props.theme.variants.primary.active.bg};

      &:hover {
        background: ${props =>
          props.variant === "success"
            ? props.theme.variants.success.active.bg
            : props.theme.variants.primary.active.bg};
      }
    `}

  &:focus {
    transition: none;
    box-shadow: unset !important;
  }

  > div {
    display: flex;
    font-size: 16px;
    font-weight: 600;
    align-items: center;
    justify-content: center;

    > i {
      font-size: 20px;
    }
  }
`;

SchedulerDropdown.defaultProps = {
  variant: "success"
};

export const SchedulerOptions = styled.div`
  right: 7px;
  z-index: 9;
  width: 288px;
  display: flex;
  position: absolute;
  border-radius: 12px;
  flex-direction: column;
  bottom: calc(100% + 4px);
  box-shadow: ${props => props.theme.shadows.shadowM};
  background: ${props => props.theme.colors.background1};
  border: 1px solid ${props => props.theme.colors.grey03};
`;

export const OptionGroup = styled.div`
  padding: 8px 6px;

  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.colors.grey02};
  }
`;

export const Option = styled.div`
  flex-grow: 1;
  display: flex;
  cursor: pointer;
  min-height: 36px;
  border-radius: 10px;
  align-items: flex-start;
  padding: 9px 12px 9px 10px;
  justify-content: space-between;
  color: ${props => props.theme.colors.text01};
  background: ${props =>
    props.isActive
      ? props.theme.colors.grey01
      : props.theme.colors.background1};

  &:hover {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primary};

    h6 {
      color: ${props => props.theme.colors.white};
    }

    .label > .description {
      color: ${props => props.theme.colors.darkText03};
    }
  }

  i {
    font-size: 20px;
  }
`;

export const OptionLabel = styled.div`
  display: flex;

  i {
    font-size: 20px;
    margin-right: 12px;
  }

  .label {
    gap: 4px;
    display: flex;
    max-width: 191px;
    flex-direction: column;

    .description {
      font-size: 12px;
      font-weight: 400;
      line-height: 15.6px;
      color: ${props => props.theme.colors.text03};
    }
  }
`;

export const DatePickerWrapper = styled.div`
  width: 284px;
  display: flex;
  overflow: hidden;
  padding: 16px 0px;
  position: absolute;
  border-radius: 16px;
  align-items: center;
  justify-content: center;
  transition: 0.25s height;
  box-shadow: ${props => props.theme.shadows.shadowM};
  background: ${props => props.theme.colors.background1};
  border: 1px solid ${props => props.theme.colors.grey02};
`;

export const Actions = styled.div`
  gap: 8px;
  display: flex;
  margin-top: 78px;

  button {
    flex: 1;
  }
`;

export const DeleteButton = styled(Button)`
  width: 40px;
  height: 40px;
  padding: unset;
  margin-right: 8px;
  border-radius: 50%;

  &:focus {
    transition: none;
    box-shadow: unset !important;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;
    }
  }
`;
