import React from "react";
import { Flex } from "rebass/styled-components";
import styled, { css } from "styled-components";
import { theme } from "../../theme";

// primary, secondary, green

const variants = {
  primaryMain: {
    bg: theme.colors.primary,
    color: theme.colors.text05,
    hover: {
      bg: theme.colors.primaryHover,
      color: theme.colors.text05
    },
    active: {
      bg: theme.colors.primaryActive,
      color: theme.colors.text05
    }
  },
  primary: {
    bg: theme.colors.grey01,
    color: theme.colors.text01,
    hover: {
      bg: theme.colors.grey02,
      color: theme.colors.text01
    },
    active: {
      bg: theme.colors.grey03,
      color: theme.colors.text01
    }
  },
  secondary: {
    bg: theme.colors.primaryTransparent,
    color: theme.colors.text02,
    hover: {
      bg: theme.colors.grey01,
      color: theme.colors.text01
    },
    active: {
      bg: theme.colors.primaryTransparent,
      color: theme.colors.text01
    }
  },
  secondaryLoud: {
    bg: theme.colors.grey02,
    color: theme.colors.text01,
    hover: {
      bg: theme.colors.grey03,
      color: theme.colors.text01
    },
    active: {
      bg: theme.colors.grey04,
      color: theme.colors.text01
    }
  },

  secondaryQuiet: {
    bg: theme.colors.primaryTransparent,
    color: theme.colors.text01,
    hover: {
      bg: theme.colors.grey02,
      color: theme.colors.text01
    },
    active: {
      bg: theme.colors.grey03,
      color: theme.colors.text01
    }
  },
  green: {
    bg: theme.colors.green,
    color: theme.colors.text05,
    hover: {
      bg: theme.colors.greenHover,
      color: theme.colors.text05
    },
    active: {
      bg: theme.colors.greenActive,
      color: theme.colors.text05
    }
  },
  black: {
    bg: theme.colors.text01,
    color: theme.colors.text05,
    hover: {
      bg: theme.colors.text02,
      color: theme.colors.text05
    },
    active: {
      bg: theme.colors.black,
      color: theme.colors.text05
    }
  },
  danger: {
    bg: "transparent",
    color: theme.colors.danger,
    hover: {
      bg: theme.colors.dangerTransparent,
      color: theme.colors.danger
    },
    active: {
      bg: theme.colors.dangerTransparent,
      color: theme.colors.danger
    }
  },
  orange: {
    bg: "transparent",
    color: theme.colors.orange,
    hover: {
      bg: theme.colors.dangerTransparent,
      color: theme.colors.orange
    },
    active: {
      bg: theme.colors.dangerTransparent,
      color: theme.colors.orange
    }
  },
  sparkle: {
    bg: theme.colors.lightBlue,
    color: theme.colors.blue,
    hover: {
      bg: theme.colors.orange,
      color: theme.colors.white
    },
    active: {
      bg: theme.colors.orange,
      color: theme.colors.white
    }
  }
};

const ButtonWrapper = styled(Flex)`
  align-items: center;
  background-color: ${props =>
    props.isActive
      ? variants[props.variant].active.bg
      : variants[props.variant].bg};
  border-radius: ${props => (props.borderStyle === "square" ? "4px" : "20px")};
  color: ${props => variants[props.variant].color};
  cursor: pointer;
  font-size: ${props => {
    return `${
      props.iconSize ? props.iconSize : props.size > 32 ? 24 : props.size
    }px`;
  }};

  justify-content: center;
  padding: ${props => props.padding ?? 8}px;

  ${props =>
    !props.disabled &&
    css`
      &:hover {
        background-color: ${props => variants[props.variant].hover.bg};
        color: ${props => variants[props.variant].hover.color};
      }
      &:active {
        background-color: ${props => variants[props.variant].active.bg};
        color: ${props => variants[props.variant].active.color};
      }
      &:focus {
        box-shadow: 0 0 1px ${props => props.theme.colors.background1},
          0 0 0 1px ${props => props.theme.colors.background1},
          0 0 0 2px ${props => props.theme.colors.iconFill4} !important;
        background-color: ${props => variants[props.variant].hover.bg};
        transition: box-shadow 0.2s ease;
      }
    `}

  ${props =>
    props.isActive &&
    css`
      background-color: ${variants[props.variant].active.bg};
      color: ${variants[props.variant].active.color};
      box-shadow: 0 0 1px ${props => props.theme.colors.background1},
        0 0 0 1px ${props => props.theme.colors.background1},
        0 0 0 2px ${props => props.theme.colors.iconFill4} !important;
    `};

  ${props =>
    props.disabled &&
    css`
      color: ${props => props.theme.colors.grey04};
    `}
`;

const Icon = styled(({ component, ...props }) =>
  React.cloneElement(component, props)
)`
  color: ${props => props.color};
`;

const IconButton = React.memo(
  React.forwardRef(
    ({ variant = "primary", icon, iconColor, children, ...props }, ref) => {
      const className =
        typeof icon === "string"
          ? props.isActive && props.activeIcon
            ? props.activeIcon
            : icon
          : null;

      return (
        <ButtonWrapper
          variant={variant}
          className={className}
          sx={{ width: props.size ?? 36, height: props.size ?? 36 }}
          {...props}
        >
          {typeof icon !== "string" && (
            <Icon
              component={React.createElement(icon)}
              size={props.iconSize || 20}
              color={iconColor}
              strokeWidth="2.5px"
            />
          )}
        </ButtonWrapper>
      );
    }
  )
);

export default IconButton;
