import { useCallback, useRef } from "react";

import axios from "axios";
import config from "./../config";
import authService from "./Auth";

const CancelToken = axios.CancelToken;

export function useContentoApi(
  url,
  method = "get",
  headers = null,
  loginRedirect = true
) {
  const sourceRef = useRef(null);

  if (url[0] !== "/") {
    url = "/" + url;
  }

  const callApi = useCallback(
    async function(otherOptions = {}) {
      const defaults = {
        baseURL: `${config.apiUrl}${url}`,
        method: method,
        headers: headers ? { ...headers } : {}
      };

      const token = authService.getAccessToken();
      if (token) {
        defaults.headers[
          "Authorization"
        ] = `Bearer ${authService.getAccessToken()}`;
      }

      try {
        sourceRef.current = CancelToken.source();

        const options = {
          ...defaults,
          ...otherOptions,
          cancelToken: sourceRef.current.token
        };

        const response = await axios(options);
        sourceRef.current = null; //Reset cancel token
        return response.data;
      } catch (error) {
        sourceRef.current = null; //Reset cancel token
        if (axios.isCancel(error)) {
          let newError = new Error(
            "Request cancelled with message " + error.message
          );
          newError.name = "RequestCancelled";
          throw newError;
        }

        if (!error.response || !error.response.status) {
          let newError = new Error("Network error");
          newError.name = "NetworkError";
          throw newError;
        }

        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          let newError = new Error(error.response.data.message);
          if (error.response.status === 400) {
            newError.name = "ValidationError";
          } else if (error.response.status === 401 && loginRedirect) {
            newError.name = "AuthorizationError";
            //Force reload. Ugly but practical.
            window.location.reload();
            return;
          }
          throw newError;
        } else {
          throw error;
        }
      }
    },
    [sourceRef, url, method, headers, loginRedirect]
  );

  const cancelCall = useCallback(
    msg => {
      if (sourceRef.current !== null) {
        sourceRef.current.cancel(msg);
      }
    },
    [sourceRef]
  );

  return [callApi, cancelCall];
}
