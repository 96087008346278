import styled from "styled-components";
import { Link } from "react-router-dom";
import Button from "components/common/Button";
import { Box, Flex, Image } from "rebass/styled-components";

export const ForgotBox = styled(Box)<{
  mt?: number;
}>`
  text-align: center;
  margin-top: ${props => props.mt ?? 24}px;
`;

export const ForgotLink = styled(Link)`
  color: ${props => props.theme.colors.blue};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const ForgotNativeLink = styled.a`
  color: ${props => props.theme.colors.blue};
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

export const FormFooter = styled(Box)`
  text-align: center;
  margin-top: 32px;
`;

export const FormLogo = styled(Box)`
  box-sizing: content-box;
  height: 40px;
  margin-bottom: auto;
  margin-top: 24px;
  padding-bottom: 16px;
`;

export const FormContainer = styled(Flex)`
  align-items: center;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  transition: 0.5s;
`;

export const SocialButtonContainer = styled(Flex)`
  margin-bottom: 16px;
  max-width: 400px;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const SocialButtonWrapper = styled(Button)`
  padding: 12px;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  /* Grey/02 */

  border: 1px solid rgba(96, 116, 128, 0.12);
  box-sizing: border-box;
  /* shadow XS */
  box-shadow: ${props => props.theme.color} 0px 0px 0px 1px
      rgba(28, 30, 31, 0.05),
    0px 3px 6px rgba(28, 30, 31, 0.05);

  color: ${props => props.theme.colors.text01};

  &:hover {
    background: #ffffff;
    border: 1px solid rgba(102, 119, 128, 0.2);
    box-sizing: border-box;
    /* Shadow S */
    box-shadow: 0px 4px 24px rgba(28, 30, 31, 0.08),
      0px 1px 2px rgba(28, 30, 31, 0.04);
  }
  &:active {
    background: #ffffff;
    color: ${props => props.theme.colors.text01};
  }
  &:focus {
    background: #ffffff;
    color: ${props => props.theme.colors.text01};
  }
`;

export const SocialLogo = styled(Image)`
  width: 24px;
  height: 24px;
`;

export const SocialButtonContent = styled(Flex)`
  flex-direction: row;
  gap: 8px;
  justify-content: center;
`;
