import { Box, Flex } from "rebass";
import styled, { css } from "styled-components";
import { Headline2 } from "../../components/common/styles";

export const DashboardContent = styled(Box)`
  box-sizing: border-box;
  max-width: 1436px;
  padding: 0 3.25vw;
  margin: 0 24px 32px;
  flex: 1;
  width: 100%;

  &::before {
    content: "";
    background-color: ${props => props.theme.colors.grey01};
    display: block;
    width: 100%;
    height: 420px;
    position: absolute;
    left: 0;
    z-index: -1;
  }
`;

export const DashboardSection = styled(Box)`
  box-sizing: border-box;
  width: 10%;
  margin-bottom: 32px;
  flex: 1;
  width: 100%;

  ${props =>
    props.hasTopBorder &&
    css`
      border-top: 2px solid ${props.theme.colors.text01};
    `};
`;
DashboardSection.defaultProps = {
  as: "section"
};

export const DashboardTitle = styled(Headline2)`
  margin-bottom: 16px;

  & small {
    color: ${props => props.theme.colors.text02};
    display: block;
    font-size: 14px;
    font-family: ${props => props.theme.fonts.body};
    font-weight: 400;
    letter-spacing: 0;
    margin: 4px 0;
  }
`;

export const DashboardHeader = styled.header`
  margin: 24px 0;
`;

export const DashboardFooter = styled(Flex)`
  justify-content: center;
  padding: 56px 0;
`;

export const EmptyCalendarCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 17.5px;
  letter-spacing: -0.005em;
  border-radius: 16px;
  color: ${props => props.theme.colors.text03};
  background: ${props => props.theme.colors.grey01};
  cursor: pointer;

  > i {
    transform: rotate(180deg);
  }

  &:hover {
    background-color: ${props => props.theme.colors.grey02};
  }
`;

export const PostTypeSelection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  margin-bottom: 24px;
`;

export const CalendarContentView = styled.div`
  width: 100%;
  height: 278px;
  margin: -20px;
  padding: 20px;
  position: relative;
`;

export const CardList = styled(Flex)`
  margin: -20px !important;
  padding: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  scrollbar-width: none;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }

  ${props =>
    props.isLoading
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
        `
      : css`
          display: flex;
          gap: 8px;
        `};
`;

export const CardWrapper = styled.div`
  display: flex;
  height: 278px;

  > div {
    width: 298px;
  }
`;

export const NewsContentView = styled.div`
  width: 100%;

  ${props =>
    props.isLoading
      ? css`
          display: flex;
          justify-content: center;
          align-items: center;
        `
      : css`
          display: grid;
          grid-template-columns: repeat(12, 1fr);
          gap: 24px;

          > div {
            &:nth-child(1) {
              grid-column: 1 / span 4;
            }
            &:nth-child(2) {
              grid-column: 5 / span 4;
            }
            &:nth-child(3) {
              grid-column: 9 / span 4;
            }
            &:nth-child(4) {
              grid-column: 1 / span 3;
            }
            &:nth-child(5) {
              grid-column: 4 / span 3;
            }
            &:nth-child(6) {
              grid-column: 7 / span 3;
            }
            &:nth-child(7) {
              grid-column: 10 / span 3;
            }
          }

          @media screen and (max-width: 767px) {
            grid-template-columns: repeat(2, 1fr);

            > div {
              &:nth-child(1) {
                grid-column: 1 / span 2;
              }
              &:nth-child(2) {
                grid-column: 1 / span 2;
              }
              &:nth-child(3) {
                grid-column: 1 / span 2;
              }
              &:nth-child(4) {
                grid-column: 1 / span 1;
              }
              &:nth-child(5) {
                grid-column: 2 / span 1;
              }
              &:nth-child(6) {
                grid-column: 1 / span 1;
              }
              &:nth-child(7) {
                grid-column: 2 / span 1;
              }
            }
          }
        `};
`;
