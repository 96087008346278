import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";

import Popper from "components/common/Popper";
import ContextMenu from "components/common/contextMenu";
import { CategoryData as Category } from "../constants/category";

const KeywordWrapper = styled.div<{ isActive?: boolean }>`
  background: #ffffff;
  ${props => {
    if (props.isActive) {
      return css`
        border: 1px solid ${props.theme.colors.blue};
      `;
    } else {
      return css`
        border: 1px solid transparent;
        box-shadow: 0px 0px 0px 1px rgba(28, 30, 31, 0.05),
          0px 3px 6px rgba(28, 30, 31, 0.05);
      `;
    }
  }}

  border-radius: 4px;
  display: inline-flex;
  flex-direction: row;
  gap: 4px;
`;

const KeywordActionButton = styled.span<{ isActive: boolean }>`
  box-sizing: border-box;
  border-left: 1px solid ${props => props.theme.colors.grey02};
  padding: 8px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  line-height: 125%;
  color: ${props => props.theme.colors.text04};

  :hover {
    background-color: ${props => props.theme.colors.grey01};
    font-weight: 600;
    color: ${props => props.theme.colors.text01};
  }

  ${props =>
    props.isActive &&
    css`
      background-color: ${props => props.theme.colors.grey01};
      font-weight: 600;
    `}
`;

const KeywordItem = styled.div`
  padding: 8px;
  box-sizing: border-box;
  cursor: pointer;

  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 125%;

  color: ${props => props.theme.colors.text01};
`;

const KeywordInput = styled.input<{ width?: number }>`
  outline: none;
  border: none;
  border-radius: 4px;
  padding: 8px;
  ${props =>
    props.width &&
    css`
      width: ${props.width + "px"};
    `}
`;

const HelperText = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;
`;

const LearnMore = styled.a`
  display: block;
  color: ${props => props.theme.colors.text03};
  cursor: pointer;

  :hover {
    color: ${props => props.theme.colors.white};
  }
`;

interface IKeywordSelectedProps {
  alertId: string;
  id: string;
  label: string;
  editable?: boolean;
  disabled?: boolean;
  category: Category;
  onDelete: (alertId: string, keyword: string) => void;
  onEdit: (alertId: string, keywordId: string, value: string) => void;
}

type CategoryData = {
  label: string;
  icon: string;
  variant?: string;
  helperElement?: JSX.Element;
  onClick?: () => void;
  disabled?: boolean;
  hidden?: boolean;
};

const KeywordSelected: React.FC<IKeywordSelectedProps> = ({
  id,
  alertId,
  label,
  onEdit,
  onDelete,
  disabled,
  editable,
  category
}) => {
  const [inputWidth, setInputWidth] = useState<number>(0);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  const [activateEdit, setActivateEdit] = useState<boolean>(false);

  const [keywordValue, setKeywordValue] = useState(label);

  const [referenceElement, setReferenceElement] = useState<any>(null);

  const KEYWORD_ACTIONS: Array<CategoryData> = [
    {
      label: "Edit Keyword",
      icon: "icon-edit",
      onClick: () => setActivateEdit(true),
      hidden: !editable
    },
    {
      label: "Convert to partial match",
      icon: "icon-partial-match",
      onClick: () => onEdit(alertId, id, keywordValue.concat("*")),
      hidden: !editable,
      helperElement: (
        <HelperText>
          For example: employ* will match employee, employer, employment etc...
          <LearnMore
            target="_blank"
            rel="noopener noreferrer"
            href={
              "https://support.willow.co/knowledge/keyword-monitor-tips-for-pros"
            }
          >
            Learn more
          </LearnMore>
        </HelperText>
      )
    },
    {
      label:
        category.value.toLowerCase() === "language"
          ? "Remove language"
          : "Delete keyword",
      variant: "danger",
      onClick: () => onDelete(alertId, id),
      icon: "icon-delete"
    }
  ];

  const onKeywordKeyDown = (e: any) => {
    if (e.key === "Enter" || e.key === "Escape") {
      setActivateEdit(false);
      onEdit(alertId, id, keywordValue);
    }
  };

  useEffect(() => {
    setKeywordValue(label);
  }, [label]);

  return (
    <KeywordWrapper isActive={activateEdit}>
      {activateEdit ? (
        <KeywordInput
          value={keywordValue}
          autoFocus
          onChange={e => setKeywordValue(e.target.value)}
          onKeyDown={onKeywordKeyDown}
          onBlur={() => setActivateEdit(false)}
          width={inputWidth - 17}
        />
      ) : (
        <KeywordItem
          onClick={(e: any) => {
            if (!disabled && editable) {
              setActivateEdit(true);
              setInputWidth(e.target.offsetWidth ? e.target.offsetWidth : 0);
            }
          }}
        >
          {keywordValue}
        </KeywordItem>
      )}
      {/* @ts-ignore */}
      <div ref={setReferenceElement} onClick={toggleShowOptions}>
        <KeywordActionButton
          // @ts-ignore
          ref={setReferenceElement}
          className="icon-dots"
          onClick={toggleShowOptions}
          isActive={showOptions}
        />
        {/* @ts-ignore */}
        <Popper
          referenceElement={referenceElement}
          visible={showOptions}
          onClose={() => setShowOptions(false)}
          exceptions={[referenceElement]}
        >
          <ContextMenu
            close={() => setShowOptions(false)}
            options={KEYWORD_ACTIONS}
          />
        </Popper>
      </div>
    </KeywordWrapper>
  );
};

export default KeywordSelected;
