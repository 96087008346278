import React from "react";
import styled from "styled-components";
import ReactLoading from "react-loading";
import { Box, Flex } from "rebass/styled-components";

import Metrics from "./widgets/Metrics";
import { Headline2 } from "../common/styles";
import Leaderboard from "./widgets/Leaderboard";
import ChannelToggle from "../common/ChannelToggle";
import WidgetContainer from "./widgets/WidgetContainer";
import { AvatarWrapper } from "components/common/styles";
import AnalyticsSubplot from "./widgets/AnalyticsSubplot";
import ChannelBreakdown from "./widgets/ChannelBreakdown";
import { BreakdownContainer } from "./widgets/ChannelBreakdown";
import { ChannelToggleContainer } from "../common/ChannelToggle";

const AnalyticsView = styled(Flex)`
  flex-direction: column;
  margin: 16px 24px;
  max-width: 1128px;
  width: 100%;

  .report {
    & * {
      box-shadow: unset !important;
    }

    ${WidgetContainer}, ${BreakdownContainer}, ${AvatarWrapper} {
      border: 1px solid ${props => props.theme.colors.grey04};
    }

    ${ChannelToggleContainer} {
      visibility: hidden;
    }
  }
`;

const AnalyticsSection = styled(Box)`
  flex: 1;
  margin: 24px 0;
`;

const AnalyticsPlotGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px 16px;
`;

const AnalyticsBreakdownGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 16px 16px;
`;

function AnalyticsOverview({
  data,
  period,
  channels,
  selectedChannels,
  onChannelSelect,
  loading,
  perChannel,
  employees
}) {
  if (!data) return null;
  const { engagements, impressions, followers } = data;

  const isLinkedinOnly =
    channels.length > 0 &&
    channels.every(c => `${c.service}_${c.serviceType}` === "linkedin_profile");

  return (
    <AnalyticsView>
      <div id="analytics-report">
        <div className="analytics-report-page">
          <AnalyticsSection>
            <Flex
              flex="1"
              justifyContent={"space-between"}
              alignItems="center"
              height={40}
            >
              <Headline2>Analytics Summary</Headline2>
              <ChannelToggle
                channels={channels}
                selectedIds={selectedChannels}
                onSave={onChannelSelect}
              />
            </Flex>
          </AnalyticsSection>

          {loading ? (
            <Flex justifyContent={"center"} my={32}>
              <ReactLoading type={"spin"} color={"#bbb"} width={32} />
            </Flex>
          ) : (
            <>
              <AnalyticsSection>
                <Metrics isLinkedinOnly={isLinkedinOnly} data={data} />
              </AnalyticsSection>
              <AnalyticsSection>
                <AnalyticsPlotGrid>
                  <AnalyticsSubplot
                    title="Impressions"
                    data={impressions.points}
                    metricId="impressions"
                    series="variation"
                    period={period}
                    description="Total times someone saw a post"
                  />
                  <AnalyticsSubplot
                    title="Engagement"
                    metricId="engagements"
                    series="variation"
                    data={engagements.points}
                    period={period}
                    description="Likes, shares & clicks"
                  />
                  {!isLinkedinOnly && (
                    <>
                      <AnalyticsSubplot
                        title="Total audience"
                        data={followers.points}
                        metricId="audience"
                        period={period}
                        series="total"
                        type="line"
                      />
                      <AnalyticsSubplot
                        title="New followers"
                        data={followers.points}
                        metricId="followers"
                        period={period}
                        series="variation"
                        type="bar"
                      />
                    </>
                  )}
                </AnalyticsPlotGrid>
              </AnalyticsSection>
            </>
          )}
        </div>
        {!loading && !isLinkedinOnly && (
          <div className="analytics-report-page">
            <AnalyticsSection>
              <Flex flexDirection="column">
                <Headline2>Channel Breakdown</Headline2>
              </Flex>
              <AnalyticsBreakdownGrid>
                {perChannel
                  .filter(
                    c => `${c.service}_${c.serviceType}` !== "linkedin_profile"
                  )
                  .map(channel => (
                    <ChannelBreakdown key={channel.id} data={channel} />
                  ))}
              </AnalyticsBreakdownGrid>
            </AnalyticsSection>
          </div>
        )}
        {!loading && employees.length > 0 && (
          <div className="analytics-report-page">
            <AnalyticsSection>
              <Flex flexDirection="column">
                <Headline2>Employee Engagement</Headline2>
              </Flex>
              <Flex>
                <Leaderboard data={employees} period={30} />
              </Flex>
            </AnalyticsSection>
          </div>
        )}
      </div>
    </AnalyticsView>
  );
}

export default AnalyticsOverview;
