import { connect } from "react-redux";
import styled from "styled-components";
import React, { useState } from "react";
import { Box, Flex } from "rebass/styled-components";
import { useToaster } from "@hellocontento/maillard";

import Button from "../common/Button";
import { Headline3 } from "../common/styles";
import * as modalActions from "state/actions/ModalActions";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import { PageList, Page, PageName, PageIcon, Notice } from "./styles";

const Heading = styled(Headline3)`
  text-align: center;
  margin: 16px 0 46px;
`;

const LinkedInBusinessList = ({
  account,
  onClose,
  openModal,
  businesses,
  connectBusinesses
}) => {
  const addToast = useToaster();
  let defaultSelectedBusinessIds = businesses.map(business => business.id);
  if (businesses.length > 5) {
    defaultSelectedBusinessIds = [];
  }

  const [selectedBusinessIds, setSelectedBusinessIds] = useState(
    defaultSelectedBusinessIds
  );

  const handleToggle = businessId => {
    let newSelectedBusinesses = [];
    if (selectedBusinessIds.includes(businessId)) {
      newSelectedBusinesses = selectedBusinessIds.filter(
        selectedBusinessId => selectedBusinessId !== businessId
      );
    } else {
      newSelectedBusinesses = selectedBusinessIds.concat([businessId]);
    }
    setSelectedBusinessIds(newSelectedBusinesses);
  };

  const handleAddBusiness = () => {
    const selectedBusinesses = businesses.filter(business =>
      selectedBusinessIds.includes(business.id)
    );
    if (selectedBusinesses.length === 0) {
      return addToast("Please select one business at least.", "error");
    }
    connectBusinesses(selectedBusinesses);
    if (
      !account.onboardingProgress.includes(OnboardingStepsEnum.CONNECT_CHANNEL)
    ) {
      openModal("ONBOARDING_INFO_MODAL", {
        id: OnboardingStepsEnum.CONNECT_CHANNEL,
        triggeredBy: OnboardingStepsEnum.CONNECT_CHANNEL
      });
    }

    onClose();
  };

  return (
    <Box>
      {businesses.length < 1 ? (
        <Heading>Linkedin businesses not available for the profile</Heading>
      ) : (
        <>
          <Heading>Select the Linkedin businesses you want to add</Heading>
          <PageList>
            {businesses.map(business => (
              <LinkedInBusiness
                key={business.id}
                business={business}
                selected={selectedBusinessIds.includes(business.id)}
                onToggle={() => handleToggle(business.id)}
              />
            ))}
          </PageList>
        </>
      )}
      <Notice></Notice>
      <Flex justifyContent="center">
        <Button mr="12px" variant="secondary" size="lg" onClick={onClose}>
          {businesses.length < 1 ? "Close" : "Cancel"}
        </Button>
        {businesses.length > 0 && (
          <Button size="lg" onClick={handleAddBusiness}>
            Add Business
          </Button>
        )}
      </Flex>
    </Box>
  );
};

const LinkedInBusiness = ({ business, selected, onToggle }) => {
  return (
    <Page>
      <PageIcon src={business.avatar} height={36} width={36} />
      <PageName>
        <label htmlFor={`checkbox-${business.id}`}>{business.vanityName}</label>
      </PageName>
      <div className="mdc-checkbox">
        <input
          type="checkbox"
          className="mdc-checkbox__native-control"
          id={`checkbox-${business.id}`}
          defaultChecked={selected}
          onChange={onToggle}
        />
        <div className="mdc-checkbox__background">
          <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
            <path
              className="mdc-checkbox__checkmark-path"
              fill="none"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"
            />
          </svg>
          <div className="mdc-checkbox__mixedmark"></div>
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {
  openModal: modalActions.openModal
})(LinkedInBusinessList);
