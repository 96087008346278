import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback
} from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Text } from "rebass/styled-components";
import { Flex, Box } from "rebass/styled-components";

import {
  useNewsDispatch,
  resetStateAction,
  setContentAction
} from "contextApi/newsContext";
import useOnScreen from "hooks/useOnScreen";
import Button from "components/common/Button";
import { deleteKeyword } from "services/keyword";
import { Headline1 } from "components/common/styles";
import IconButton from "components/common/IconButton";
import { ContentTopicImage } from "components/content/styles";
import KeywordIcon from "assets/images/contents/Keyword-Monitor.png";
import TaskConfirmationModal from "components/schedule/form/TaskConfirmationModal";

const Title = styled(Headline1)`
  color: ${props => props.theme.colors.text01};
`;

const ButtonGroup = styled(Flex)`
  flex-direction: row;
  gap: 8px;
`;

const ButtonContent = styled(Flex)`
  flex-direction: row;
  gap: 8px;

  > span {
    font-weight: 600;
    font-size: 18px;
  }
`;

export const KeywordSectionWrapper = styled(Flex)`
  justify-content: center;
  border-radius: 16px;
  background: ${props => props.theme.colors.grey02};
  /* min-height: 242px; */
  padding: 32px 0px;
`;

interface IContentDetailBannerProps {
  pageData: any;
}

const KeywordDetailBanner: React.FC<IContentDetailBannerProps> = React.memo(
  ({ pageData }) => {
    const ref = useRef();
    const history = useHistory();
    const isVisible = useOnScreen(ref);
    const dispatch = useNewsDispatch();
    const account = useSelector((state: any) => state.account.data);
    const [isProcessing, setIsProcessing] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(
      false
    );

    const toggleConfirmationModal = useCallback(
      () =>
        setShowConfirmationModal(
          showConfirmationModal => !showConfirmationModal
        ),
      []
    );

    const navigateTo = useCallback(
      (url: string) => {
        history.push(`/accounts/${account.id}/content/${url}`);
      },
      [account.id, history]
    );

    const isLocalFollowed = useMemo(() => pageData.source.isFollowed, [
      pageData.source.isFollowed
    ]);

    const title = pageData.source.name;

    const rulesText = useMemo(() => {
      const includes = Object.keys(pageData.source.rules).reduce(
        (acc: Array<string>, value: string) => {
          const includeValues = pageData.source.rules[value].include;

          if (includeValues) {
            includeValues.forEach((element: any) => {
              acc.push(...element.values);
            });
          }
          return acc;
        },
        [] as Array<string>
      );

      if (includes.length < 2) return includes.join(" and ");
      else return `${includes.slice(0, 2).join(", ")} and many more`;
    }, [pageData.source.rules]);

    useEffect(() => {
      if (!isVisible) {
        dispatch(
          setContentAction({
            id: pageData.source.id,
            type: "keywords",
            title: title,
            meta: pageData.source,
            isFollowing: isLocalFollowed
          })
        );
      } else {
        dispatch(resetStateAction());
      }
    }, [isVisible, isLocalFollowed, dispatch, pageData.source, title]);

    const handleRemoveKeyword = useCallback(async () => {
      try {
        setIsProcessing(true);
        await deleteKeyword(pageData.source.id);
        setIsProcessing(false);
        toggleConfirmationModal();
      } catch (_) {
        setIsProcessing(false);
      }
    }, [pageData.source.id, toggleConfirmationModal]);

    const deletionModalProps = useMemo(
      () => ({
        title: "Unfollow",
        description: "You're deleting a keyword feed.",
        type: "series",
        showOptions: false,
        toggle: toggleConfirmationModal,
        buttonProps: {
          variant: "danger",
          label: "Delete",
          action: async () => {
            await handleRemoveKeyword();
            navigateTo("/for-you");
          }
        }
      }),
      [handleRemoveKeyword, navigateTo, toggleConfirmationModal]
    );

    return (
      <>
        <TaskConfirmationModal
          isOpen={showConfirmationModal}
          {...deletionModalProps}
        />
        <KeywordSectionWrapper mb={40}>
          <Flex flexDirection="column" alignItems="center" margin={"auto"}>
            <ContentTopicImage
              ref={ref}
              src={KeywordIcon}
              // @ts-ignore
              borderRadius="50%"
            />
            <Title sx={{ textTransform: "capitalize", textAlign: "center" }}>
              {title}
            </Title>
            {pageData?.source ? (
              <Text
                fontSize="14px"
                marginTop={"4px"}
                color="#646769"
                fontWeight={400}
              >
                The latest articles that include the words: {rulesText}
              </Text>
            ) : null}

            <Box mt={32}>
              <ButtonGroup>
                {/* @ts-ignore */}
                <Button
                  variant="secondary"
                  onClick={() =>
                    navigateTo(`keywords/${pageData.source.id}/edit`)
                  }
                >
                  <ButtonContent>
                    <span className="icon-edit" />
                    Edit
                  </ButtonContent>
                </Button>
                <IconButton
                  // @ts-ignore
                  size={40}
                  iconSize={24}
                  icon={"icon-delete"}
                  variant="secondaryLoud"
                  onClick={toggleConfirmationModal}
                  disabled={isProcessing}
                />
              </ButtonGroup>
            </Box>
          </Flex>
        </KeywordSectionWrapper>
      </>
    );
  }
);

export default KeywordDetailBanner;
