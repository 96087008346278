import _ from "lodash";
import React, { useState } from "react";
import styled from "styled-components";
import { MenuContainer } from "../../navigation/Menu";
import TimelineDatePicker from "../common/timelineDatePicker";
import { useSelector } from "react-redux";
import {
  getDate,
  getTheWeekADateBelongsTo,
  getDayInWords,
  getDateAfterNMonths
} from "../common/timelineDatePicker/utils/dateUtils";
import { nthWeekMode, REPETITION_TYPE } from "../constants/repetitions";

import Switch from "../../common/Switch";
import { Box, Flex } from "rebass";
import RadioButton from "components/common/form/RadioButton";
import { Label } from "../../common/styles";
import Button from "../../common/Button";
import Icon from "../../common/Icon";
import Divider from "../../common/Divider";
import { set, format, getHours, getMinutes, parse, parseISO } from "date-fns";
import TimezoneDatePicker from "../../common/CustomDatePicker";

import numeral from "numeral";
import ScheduleButton from "../form/ScheduleButton";
import RepetitionOptions from "./RepetitionOptions";

const DateSelectInputGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 120px);
  column-gap: 8px;
`;

const RadioButtonGroup = styled(Flex)`
  flex-direction: column;
  gap: 10px;
`;

const DateFieldset = styled(Flex)`
  margin-bottom: 12px !important;
  justify-content: space-between;
  align-items: center;
`;

function DateSelect({ date, dateStart, dateEnd, settings, onChange }) {
  const account = useSelector(state => state.account.data);
  const isRepeating = !!settings;

  const [previewDate, setPreviewDate] = useState(
    getDate({ date: dateStart || date, timezone: account.timezone })
  );

  const handleSetDaysOfTheWeek = value => {
    onChange({
      settings: {
        repetitionMode: settings.repetitionMode,
        daysOfTheWeek: value
      }
    });
  };

  const handleRepetitionModeChange = (val, days = [], monthSub = null) => {
    if (val === REPETITION_TYPE.WEEKLY || val === REPETITION_TYPE.BIWEEKLY) {
      onChange({
        settings: {
          repetitionMode: val,
          daysOfTheWeek:
            settings.daysOfTheWeek?.length > 0
              ? settings.daysOfTheWeek
              : [format(new Date(dateStart), "eeee").toUpperCase()]
        }
      });
    } else if (val === REPETITION_TYPE.MONTHLY) {
      onChange({
        settings: {
          repetitionMode: monthSub ? `${val}_${monthSub}` : val,
          ...(monthSub ? { daysOfTheWeek: days } : {})
        }
      });
    } else {
      onChange({
        settings: {
          repetitionMode: `${val}`
        }
      });
    }
  };

  const handleReset = () => {
    onChange({
      dateEnd: null,
      settings: null
    });
  };

  const dayInWords = getDayInWords(parseISO(isRepeating ? dateStart : date));

  const nthWeek = getTheWeekADateBelongsTo(
    parseISO(isRepeating ? dateStart : date)
  );
  let nthWeekFormat = numeral(nthWeek).format("0o");

  const handleDateChange = newDateValue => {
    const time = format(new Date(isRepeating ? dateStart : date), "HH:mm");
    const parsedTime = parse(time, "HH:mm", newDateValue);
    const hours = getHours(parsedTime);
    const minutes = getMinutes(parsedTime);
    const update = set(newDateValue, {
      hours,
      minutes
    }).toISOString();

    if (isRepeating) {
      const mode = settings.repetitionMode.split("_");

      // if the repitition mode is MONTHLY_XXX
      if (mode && mode.length > 1 && mode[0] === "MONTHLY") {
        const monthSub =
          nthWeekMode[getTheWeekADateBelongsTo(new Date(update))];
        const days = [getDayInWords(new Date(update))];

        onChange({
          dateStart: update,
          settings: {
            repetitionMode: `MONTHLY_${monthSub}`,
            daysOfTheWeek: days
          }
        });
      } else {
        onChange({
          dateStart: update
        });
      }
    } else {
      onChange({
        date: update
      });
    }
  };

  const onToggleRepeating = () => {
    if (isRepeating) {
      onChange({
        date: dateStart,
        dateStart: null,
        dateEnd: null,
        settings: null
      });
    } else {
      onChange({
        dateStart: date,
        dateEnd: null,
        settings: {
          repetitionMode: REPETITION_TYPE.WEEKLY,
          daysOfTheWeek: [format(new Date(date), "eeee").toUpperCase()]
        }
      });
    }
  };

  const monthlyRepetitionOptions = isRepeating
    ? [
        {
          value: dateStart,
          label: `On the ${nthWeekFormat} ${_.capitalize(dayInWords)}`,
          mode: REPETITION_TYPE.MONTHLY,
          monthSub: nthWeekMode[nthWeek],
          days: [dayInWords],
          checked: settings.repetitionMode.split("_").length > 1
        },
        {
          value: dateStart,
          label: `On the ${numeral(format(new Date(dateStart), "d")).format(
            "0o"
          )} day`,
          mode: REPETITION_TYPE.MONTHLY,
          day: [],
          checked: settings.repetitionMode.split("_").length <= 1
        }
      ]
    : [];

  return (
    <MenuContainer width={284}>
      <Box px={3} py={2}>
        <DateSelectInputGrid>
          <Flex flexDirection={"column"}>
            <TimezoneDatePicker
              size={"sm"}
              type={"text"}
              selected={parseISO(isRepeating ? dateStart : date)}
              onChange={value => {
                isRepeating
                  ? onChange({ dateStart: value.toISOString() })
                  : onChange({ date: value.toISOString() });
              }}
              timezone={account.timezone}
            />
          </Flex>
          {dateEnd !== null && isRepeating ? (
            <Flex flexDirection={"column"}>
              <TimezoneDatePicker
                size={"sm"}
                type={"date"}
                selected={dateEnd ? parseISO(dateEnd) : null}
                onChange={value => {
                  onChange({ dateEnd: value ? value.toISOString() : null });
                }}
                timezone={account.timezone}
                isClearable
              />
            </Flex>
          ) : (
            isRepeating && (
              <ScheduleButton
                icon="icon-plus"
                radius={8}
                boldOnHover
                moreOptions={false}
                variant={"tertiart"}
                onClick={() => {
                  onChange({
                    dateEnd: getDateAfterNMonths(new Date(dateStart), 3)
                  });
                }}
              >
                End date
              </ScheduleButton>
            )
          )}
        </DateSelectInputGrid>
        <TimelineDatePicker
          account={account}
          focusDate={new Date(isRepeating ? dateStart : date)}
          dateEnd={dateEnd ? new Date(dateEnd) : null}
          previewDate={previewDate}
          repetitionMode={settings?.repetitionMode.split("_")[0]}
          nthWeek={
            settings?.repetitionMode.split("_")[0] === REPETITION_TYPE.MONTHLY
              ? nthWeek
              : null
          }
          weeklyRange={isRepeating && (settings?.daysOfTheWeek ?? [])}
          onDateChange={handleDateChange}
          onDatePreview={setPreviewDate}
          inline
          size={"sm"}
          disablePastDate
        />
        <Divider my={2} />
        <DateFieldset>
          <Label isLight>
            <Icon icon={"icon-repeat"} /> Repeats
          </Label>
          <Switch checked={isRepeating} onChange={onToggleRepeating} />
        </DateFieldset>
        {isRepeating && (
          <Box>
            <DateFieldset>
              <Label isLight>Every</Label>
              <RepetitionOptions
                settings={settings}
                handleRepetitionModeChange={handleRepetitionModeChange}
              />
            </DateFieldset>
            {(settings.repetitionMode === REPETITION_TYPE.WEEKLY ||
              settings.repetitionMode === REPETITION_TYPE.BIWEEKLY) && (
              <Flex py={2} flexDirection={"column"}>
                <Label isLight>On these days</Label>
                <DaySelect
                  value={settings.daysOfTheWeek ?? []}
                  onChange={handleSetDaysOfTheWeek}
                />
              </Flex>
            )}
            {settings.repetitionMode.split("_")[0] ===
              REPETITION_TYPE.MONTHLY && (
              <RadioButtonGroup>
                {monthlyRepetitionOptions.map(option => {
                  return (
                    <RadioButton
                      key={option.label}
                      label={option.label}
                      name="month_radio"
                      value={option.label}
                      checked={option.checked}
                      gap={0}
                      onChange={() => {
                        handleRepetitionModeChange(
                          option.mode,
                          option.days,
                          option.monthSub
                        );
                      }}
                    />
                  );
                })}
              </RadioButtonGroup>
            )}
          </Box>
        )}
        {isRepeating && (
          <>
            <Divider my={2} />
            <Box>
              <Button variant="secondary" onClick={handleReset}>
                Clear All
              </Button>
            </Box>
          </>
        )}
      </Box>
    </MenuContainer>
  );
}

const daysOfWeek = [
  {
    label: "Monday",
    value: "MONDAY"
  },
  {
    label: "Tuesday",
    value: "TUESDAY"
  },
  {
    label: "Wednesday",
    value: "WEDNESDAY"
  },
  {
    label: "Thursday",
    value: "THURSDAY"
  },
  {
    label: "Friday",
    value: "FRIDAY"
  },
  {
    label: "Saturday",
    value: "SATURDAY"
  },
  {
    label: "Sunday",
    value: "SUNDAY"
  }
];

const DaySelectGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  column-gap: 8px;
  margin: 12px 0;
`;

const DaySelectButton = styled(Button)`
  box-shadow: ${props =>
    props.variant === "primary" ? props.theme.shadows.shadowXS : "none"};
  color: ${props =>
    props.variant !== "primary"
      ? props.theme.colors.text04
      : "white"} !important;
  height: 28px;
  padding: 4px;
  width: 28px;
`;

DaySelectButton.default = {
  size: "xs",
  isRounded: true
};

function DaySelect({ value, onChange }) {
  const handleDayToggle = day => {
    if (value.includes(day)) onChange(value.filter(d => d !== day));
    else onChange([...value, day]);
  };

  return (
    <DaySelectGrid>
      {daysOfWeek.map((day, idx) => (
        <DaySelectButton
          key={idx}
          variant={value.includes(day.value) ? "primary" : "tertiary"}
          onClick={() => handleDayToggle(day.value)}
        >
          {day.value[0]}
        </DaySelectButton>
      ))}
    </DaySelectGrid>
  );
}

export default DateSelect;
