import dompurify from "dompurify";

export const required = (value, allValues, { t }) =>
  value == null || value === "" ? "Required" : undefined;

export const isValidEmail = value => {
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  return value && emailRegex.test(value);
};

export const validEmail = (value, allValues, { t }) => {
  return !isValidEmail(value) ? "Please provide a valid email" : undefined;
};

export const validWebsite = (value, allValues, { t }) => {
  const websiteRegex = /[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,256}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)$/i;
  return value && !websiteRegex.test(value)
    ? "Please provide a valid website"
    : undefined;
};

export const urlRegex = /(http|https):\/\/([\w+?.\w+])+([a-zA-Z0-9~!@#$%^&*()_\-=+\\/?.:;',]*)?/;

export const validUrl = (value, allValues, { t }) => {
  return value && !new RegExp(urlRegex, "i").test(value)
    ? "Please provide a valid url. http:// or https:// is required."
    : undefined;
};

export const validPassword = value => {
  return value &&
    (value.length < 8 || !/[a-z]+/i.test(value) || !/\d+/.test(value))
    ? "You password must be at least 8 characters long and contain a letter and a digit"
    : undefined;
};

export const repeatPassword = (value, allValues) => {
  return value === allValues.password ? undefined : "Passwords do not match";
};

export const sanitizeHtml = html => {
  return dompurify.sanitize(html);
};

export const isTelenetCustomerId = value => {
  if (!value) {
    return undefined;
  }
  if (/^\d*$/.test(value)) {
    return value.length !== 10
      ? "Please provide a valid Telenet Customer ID (10 digits)"
      : undefined;
  } else {
    return "Please provide a number input";
  }
};
