import { useSelector } from "react-redux";
import React, { useRef, useEffect, useState } from "react";

import {
  CalendarSlotTitle,
  CalendarEntryFooter,
  CalendarSlot as Slot,
  CalendarSlotDescription,
  CalendarSlotInfoWrapper
} from "./styles";
import { useComposerActions } from "contextApi/composerContext";
import Popper from "components/common/Popper";
import TaskOptions from "../common/TaskOptions";
import {
  PostContextMenuWithButton,
  RightClickPostContextMenu
} from "../common/postContextMenu/PostContextMenu";
import CalendarEntryHeader from "./CalendarEntryHeader";
import CalendarEntryActions from "./CalendarEntryActions";
import contentTypes from "components/common/contentTypes/data/content-types";
import ContentTypeBadge from "components/common/contentTypes/ContentTypeBadge";
import { TaskTitleWrapper, PostCreateButton, ToolTip } from "../common/styles";

const CalendarTask = ({ entry, hover }) => {
  const { draggingItemId } = useSelector(state => state.dragDrop);
  const createPostByTask = useComposerActions(
    actions => actions.createPostByTask
  );

  const ref = useRef(null);
  const [contextMenuPos, setContextMenuPos] = useState([]);

  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  const isDragging = draggingItemId === entry.task.id;

  const closeMenu = () => {
    setContextMenuPos([]);
  };

  const onPostCreateByTask = e => {
    e.stopPropagation();

    createPostByTask(entry.task);
  };

  // Context menu handler on right click
  useEffect(() => {
    const element = ref.current;

    const handleContextMenu = e => {
      e.preventDefault();
      setContextMenuPos([e.clientX, e.clientY]);
    };

    element.addEventListener("contextmenu", handleContextMenu);

    return () => {
      element.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // Remove context menu on scroll
  useEffect(() => {
    const handleScroll = () => {
      setContextMenuPos([]);
    };

    if (!!contextMenuPos.length) {
      document.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("contextmenu", handleScroll);
    };
  }, [contextMenuPos]);

  return (
    <Slot
      contentType={entry.task.contentTypeId}
      onClick={toggleShowOptions}
      ref={ref}
      border={showOptions}
      isPhantom={entry.isPhantom}
      isDragging={isDragging}
      isPast={entry.isPast}
      isContextMenuOpen={contextMenuPos.length > 0}
    >
      <Popper
        placement={"right"}
        offset={[50, -50]}
        referenceElement={ref.current}
        visible={showOptions}
        onClose={() => setShowOptions(false)}
        exceptions={[ref.current]}
      >
        <TaskOptions entry={entry} close={() => setShowOptions(false)} />
      </Popper>
      <CalendarSlotInfoWrapper>
        <CalendarEntryHeader
          status={"TASK_SCHEDULE"}
          time={entry.time}
          channels={entry.channels}
        />
        <TaskTitleWrapper>
          <PostCreateButton
            data-tip
            data-for={`${entry.id}_POST`}
            className="icon-task-check action__create-post"
            onClick={onPostCreateByTask}
          >
            <span className="path1"></span>
            <span className="path2"></span>
          </PostCreateButton>
          <ToolTip
            id={`${entry.id}_POST`}
            place="left"
            effect="solid"
            className="tooltip"
            variant="info"
          >
            Click here to create and write your post
          </ToolTip>
          <CalendarSlotTitle>{entry.task.title}</CalendarSlotTitle>
        </TaskTitleWrapper>
        {entry.task.description && (
          <CalendarSlotDescription>
            {entry.task.description.length > 100
              ? entry.task.description.substring(0, 100) + "..."
              : entry.task.description}
          </CalendarSlotDescription>
        )}
      </CalendarSlotInfoWrapper>
      <RightClickPostContextMenu
        entry={entry}
        closeMenu={closeMenu}
        contextMenuPos={contextMenuPos}
      />
      <PostContextMenuWithButton entry={entry} contextWithPortal={true} />
      <CalendarEntryFooter>
        {entry.task.contentTypeId !== "other" && (
          <ContentTypeBadge
            contentTypeId={entry.task.contentTypeId}
            title={contentTypes[entry.task.contentTypeId].title}
            variant="dark"
            size="sm"
          />
        )}
        <CalendarEntryActions visible={hover} entry={entry} />
      </CalendarEntryFooter>
    </Slot>
  );
};

export default CalendarTask;
