import React, { useEffect } from "react";
import { Headline3 } from "components/common/styles";
import { BuildingBlockWrapper, BuildingBlockLayout, Headline } from "./styles";

import { IBlock, FEATURE } from "./buildingBlocks.types";

import RSSIcon from "assets/images/contents/RSS.png";
import TopicIcon from "assets/images/contents/Topic.png";
import WebsiteIcon from "assets/images/contents/Website.png";
import TwitterIcon from "assets/images/contents/Twitter.png";
import KeywordIcon from "assets/images/contents/Keyword-Monitor.png";

import Block from "./Block";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useAlertModal, {
  IAlertContextPayload
} from "components/modals/pop-ups/useAlertModal";
import GoogleKeywordBanner from "assets/images/contents/google-keyword.png";

interface IBuildingBlocksProps {
  exclude?: string[];
  soft: boolean;
}

const BuildingBlocks: React.FC<IBuildingBlocksProps> = ({
  exclude = [],
  soft = false
}) => {
  const history = useHistory();
  const account = useSelector((state: any) => state.account.data);

  const navigateTo = (url: string) => {
    history.push(`/accounts/${account.id}/content/${url}`);
  };

  const addFeatureAlert = useAlertModal();

  useEffect(() => {
    const newFeature: IAlertContextPayload = {
      id: "keyword",
      steps: [
        {
          title: "Introducing Keyword Monitor",
          description:
            "Find articles that match your keywords. We’ll look for articles that match your keyword in our Willow Database and on Google News.",
          helpUrl: "https://support.willow.co/knowledge/recommended-content",
          banner: GoogleKeywordBanner,
          onProceed: () => navigateTo("keywords")
        }
      ]
    };

    addFeatureAlert(newFeature);
  }, []);

  const blockData: Array<IBlock> = [
    {
      title: "Topics",
      description: "Curated topics around specific interests powered by AI",
      image: TopicIcon,
      action: () => navigateTo("explore/?type=topics"),
      active: FEATURE.ACTIVE
    },
    {
      title: "Websites",
      description: "Find the latest news from trusted news websites",
      image: WebsiteIcon,
      action: () => navigateTo("explore?type=domains"),
      active: FEATURE.ACTIVE
    },
    {
      title: "RSS",
      description: "Manually add the RSS feed of your favorite blog or website",
      image: RSSIcon,
      action: () => navigateTo("explore?type=rss-sources"),
      active: FEATURE.ACTIVE
    },
    {
      title: "Twitter",
      description:
        "Follow influencers on Twitter & read the articles they share",
      image: TwitterIcon,
      action: () => navigateTo("explore?type=twitter-sources"),
      active: FEATURE.ACTIVE
    },
    {
      title: "Keyword Monitor",
      description:
        "Monitor the web for interesting content based on your keywords",
      image: KeywordIcon,
      action: () => {
        navigateTo("keywords");
      },
      active: FEATURE.NEW
    }
  ];

  return (
    <BuildingBlockWrapper soft={soft}>
      {" "}
      {!soft ? (
        <Headline>
          Start Building your News Feed with Willow’s Building Blocks
        </Headline>
      ) : (
        <Headline3>Building Blocks</Headline3>
      )}
      <BuildingBlockLayout>
        {blockData
          .filter((block: IBlock) => {
            return !exclude.includes(block.title.toLowerCase());
          })
          .map((block: IBlock, index: number) => (
            <Block key={index} {...block} />
          ))}
      </BuildingBlockLayout>
    </BuildingBlockWrapper>
  );
};

export default BuildingBlocks;
