import decorateComponentWithProps from "decorate-component-with-props";

import ShortenableUrl from "./components/ShortenableUrl";

import UrlHandler from "./UrlHandler";
import findUrlEntities from "./findUrlEntities";

function createUrlPlugin(callbacks) {
  const store = {
    getEditorState: undefined,
    setEditorState: undefined,
    getEditorRef: undefined
  };

  const urlHandler = new UrlHandler(store, callbacks);

  return {
    initialize: ({ getEditorState, setEditorState, getEditorRef }) => {
      store.getEditorState = getEditorState;
      store.setEditorState = setEditorState;
      store.getEditorRef = getEditorRef;
    },
    decorators: [
      {
        strategy: findUrlEntities,
        component: decorateComponentWithProps(ShortenableUrl, { urlHandler })
      }
    ],
    urlHandler: urlHandler,
    handlePastedText: () => urlHandler.processUrls(),
    handleDrop: () => urlHandler.processUrls(),
    onBlur: () => urlHandler.processUrls(),
    keyBindingFn: function(e) {
      let handled;
      switch (e.key) {
        case " ":
        case "Enter":
          urlHandler.processUrls();
          break;
        case "Backspace":
        case "Delete":
          handled = urlHandler.handleDelete();
          break;
        default:
          break;
      }
      return handled;
    }
  };
}

export default createUrlPlugin;
