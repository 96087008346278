import React, { useState, useEffect, useRef } from "react";
import { TooltipContainer, TooltipTitle } from "./styles";

function Tooltip({
  title,
  children,
  width = null,
  placement = "top",
  hidden = false
}) {
  const [isHovered, setIsHovered] = useState(false);
  const [offset, setOffset] = useState({});

  const ref = useRef(null);

  useEffect(() => {
    setOffset({
      x: ref.current.clientWidth / 2,
      y: ref.current.clientHeight / 2
    });
  }, [isHovered]);

  return (
    <TooltipContainer
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setIsHovered(false)}
    >
      <TooltipTitle
        ref={ref}
        placement={placement}
        style={{
          left: `calc(-${offset.x}px + 18px)`,
          top: `calc((-2*${offset.y}px - 6px) * ${
            placement === "bottom" ? "-1.5" : "1"
          })`
        }}
        visible={!hidden && isHovered}
        width={width}
      >
        <span>{title}</span>
      </TooltipTitle>
      {children}
    </TooltipContainer>
  );
}

export default Tooltip;
