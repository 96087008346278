import styled from "styled-components";
import { Box } from "rebass/styled-components";
import React, { useEffect, useState } from "react";
import { useToaster } from "@hellocontento/maillard";

import {
  SettingsIcon,
  SettingsBrick,
  SettingsSection,
  SettingsSubtitle
} from "./styles";
import Button from "../common/Button";
import { WILLOW_PLAN } from "constants/plan";
import { useAppState } from "contextApi/appContext";
import { usageLimitsInfo } from "utils/UsageLimits";
import { useContentoApi } from "utils/useContentoApi";
import iconSocialSet from "assets/images/icon-social-set.png";
import { Headline3, Paragraph } from "components/common/styles";
import iconTeam from "assets/images/icon-settings-usage-team.png";
import iconChannels from "assets/images/icon-settings-usage-channels.png";

const UsageLabel = styled(Paragraph)`
  color: ${props =>
    parseInt(props.remaining) === 0
      ? props.theme.colors.red
      : props.theme.colors.green};
  font-weight: bold;
  margin-bottom: 0;
`;

const IconSocialSet = styled(Box)`
  display: inline-block;
  transform: translateY(2px);
`;

const BillingUsage = ({ account, onUpgrade }) => {
  const allowedChannels = useAppState(
    state => state.clientSettings.allowedChannels
  );
  const addToast = useToaster();
  const [accountUsers, setAccountUsers] = useState([]);
  const [fetchAccountUsers, cancelFetchAccountUsers] = useContentoApi(
    `accounts/${account.id}/users`
  );
  useEffect(() => {
    fetchAccountUsers()
      .then(setAccountUsers)
      .catch(() => {
        addToast("Error loading users. Please try again later.", "error");
      });
    return cancelFetchAccountUsers;
  }, [fetchAccountUsers, cancelFetchAccountUsers, addToast]);

  if (!account.billing || !WILLOW_PLAN[account.billing.willowPlan]) {
    return null;
  }

  const usageInfo = usageLimitsInfo(account, accountUsers);

  if (account.billing.willowPlan === WILLOW_PLAN.SMALL_BIZ) {
    return (
      <BillingUsageSmallBiz
        channels={Math.max(0, allowedChannels - usageInfo.channels.used)}
      />
    );
  }

  return (
    <SettingsSection id="usage">
      <SettingsSubtitle>Usage</SettingsSubtitle>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "10px"
        }}
      >
        <SettingsBrick>
          <SettingsIcon size={48} src={iconTeam} />
          <Box mt={8} minHeight="100px">
            <Box mb={8}>
              <Headline3 font={"body"}>Team Management</Headline3>
            </Box>
            <Paragraph variant={"lightest"} height={"70px"}>
              Give people of your team access to Willow so they can manage.
            </Paragraph>
          </Box>

          <Box my={40}>
            <UsageLabel
              mb={"2px"}
              remaining={Math.max(0, usageInfo.teamMembers.remaining)}
            >
              {Math.max(0, usageInfo.teamMembers.remaining)} users remaining
            </UsageLabel>
            <Paragraph variant={"lightest"}>
              {usageInfo.teamMembers.available} user in your plan
            </Paragraph>
          </Box>

          <Button variant="primaryLite" onClick={() => onUpgrade()}>
            Upgrade Plan
          </Button>
        </SettingsBrick>
        <SettingsBrick>
          <SettingsIcon size={48} src={iconChannels} />
          <Box mt={8} minHeight="100px">
            <Box mb={8}>
              <Headline3 font={"body"}>Social Media Channels</Headline3>
            </Box>
            <Paragraph variant={"lightest"}>
              Connect your Facebook Page, Instagram Business, LinkedIn and
              Twitter accounts.{" "}
            </Paragraph>
          </Box>
          <Box my={40}>
            <UsageLabel remaining={Math.max(0, usageInfo.channels.remaining)}>
              {Math.max(0, usageInfo.channels.remaining)} channels remaining
            </UsageLabel>
            <Paragraph variant={"lightest"}>
              {usageInfo.socialSets.available} social set in your plan{" "}
              <IconSocialSet>
                <img
                  src={iconSocialSet}
                  alt={"Social set"}
                  width={"66"}
                  height={"16"}
                />
              </IconSocialSet>
            </Paragraph>
          </Box>

          <Button variant="primaryLite" onClick={() => onUpgrade()}>
            Upgrade Plan
          </Button>
        </SettingsBrick>
      </Box>
    </SettingsSection>
  );
};

export const BillingUsageSmallBiz = ({ channels }) => {
  const allowedChannels = useAppState(
    state => state.clientSettings.allowedChannels
  );

  return (
    <SettingsSection id="usage">
      <SettingsSubtitle>Usage</SettingsSubtitle>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gridGap: "10px"
        }}
      >
        <SettingsBrick>
          <SettingsIcon size={48} src={iconTeam} />
          <Box mt={8} minHeight="100px">
            <Box mb={8}>
              <Headline3 font={"body"}>Team Management</Headline3>
            </Box>
            <Paragraph variant={"lightest"} height={"70px"}>
              Give people of your team access to Willow so they can manage.
            </Paragraph>
          </Box>

          <Box my={40}>
            <UsageLabel mb={"2px"} remaining={1}>
              Unlimited users remaining
            </UsageLabel>
            <Paragraph variant={"lightest"}>
              Unlimited users in your plan
            </Paragraph>
          </Box>
        </SettingsBrick>
        <SettingsBrick>
          <SettingsIcon size={48} src={iconChannels} />
          <Box mt={8} minHeight="100px">
            <Box mb={8}>
              <Headline3 font={"body"}>Social Media Channels</Headline3>
            </Box>
            <Paragraph variant={"lightest"}>
              Connect your Facebook Page, Instagram Business, LinkedIn and
              Twitter accounts.{" "}
            </Paragraph>
          </Box>
          <Box my={40}>
            <UsageLabel remaining={1}>
              {/* {Math.max(0, usageInfo.channels.remaining)} channels remaining */}
              {channels} channels remaining
            </UsageLabel>
            <Paragraph variant={"lightest"}>
              {`${allowedChannels} social set in your plan `}
              <IconSocialSet>
                <img
                  src={iconSocialSet}
                  alt={"Social set"}
                  width={"66"}
                  height={"16"}
                />
              </IconSocialSet>
            </Paragraph>
          </Box>
        </SettingsBrick>
      </Box>
    </SettingsSection>
  );
};

export default BillingUsage;
