import React from "react";
import { connect } from "react-redux";
import { UserPassword } from "./forms";
import {
  SettingsHeader,
  SettingsDescription,
  SettingsTitle,
  SettingsSection,
  SettingsSubtitle,
  SettingsBrick
} from "./styles";
import { callApi } from "../../utils/ContentoApi";

import { useToaster } from "@hellocontento/maillard";

function Security({ currentUser }) {
  const addToast = useToaster();

  const changePasswordOfUser = async ({ userId, currentPassword, password }) => {
    try {
      await callApi({
        method: "put",
        url: `/users/${userId}`,
        data: {
          currentPassword, password
        }
      });
      addToast("Password updated successfully", "success");
    } catch(err) {
      addToast(err.message, "error");
    }
  }

  return <>
    <SettingsHeader> 
      <SettingsTitle>
        Security
      </SettingsTitle>
      <SettingsDescription>Choose a strong password and don't reuse it for other accounts.</SettingsDescription>
    </SettingsHeader> 

    <SettingsSection>
      <SettingsSubtitle>
        Password
      </SettingsSubtitle>
      <SettingsBrick>
        <UserPassword
          initialValues={{ userId: currentUser.id }}
          onSubmit={changePasswordOfUser}
        />
      </SettingsBrick>
    </SettingsSection>
  </>;
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.auth.currentUser,
    account: state.account.data,
  };
};

export default connect(mapStateToProps, {})(Security);
