import React from "react";
import { Flex } from "rebass/styled-components";
import {
  EmptyStateContainer,
  EmptyStateIconContainer,
  EmptyStateHeading,
  EmptyStateDescription,
  EmptyStateHelper
} from "./styles";
import Button from "./Button";
import { LoadingContent } from "../content/styles";
import ReactLoading from "react-loading";

export default function EmptyState({
  description,
  header,
  icon,
  cta,
  image,
  center = true,
  loading,
  helper = null
}) {
  if (loading) {
    return (
      <LoadingContent>
        <ReactLoading color="#bbb" width={32} type="spin" />
      </LoadingContent>
    );
  }

  const Icon = icon ? (
    <icon.component width={icon.width} height={icon.height} />
  ) : (
    image ?? null
  );

  return (
    <Flex marginTop={"132px"} alignItems={"center"} justifyContent={"center"}>
      <EmptyStateContainer>
        <EmptyStateIconContainer>{Icon}</EmptyStateIconContainer>
        <EmptyStateHeading>{header}</EmptyStateHeading>
        {description && (
          <EmptyStateDescription>
            {description}
            {helper && (
              <EmptyStateHelper onClick={helper.onClick}>
                {helper.text}
              </EmptyStateHelper>
            )}
          </EmptyStateDescription>
        )}
        {cta && (
          <Flex alignItems={"center"} justifyContent={"center"} mt={40}>
            <Button
              variant={cta.variant ?? "primary"}
              size="m"
              onClick={cta.onClick}
            >
              {cta.text}
            </Button>
          </Flex>
        )}
      </EmptyStateContainer>
    </Flex>
  );
}
