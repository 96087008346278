import React, { useState } from "react";
import { Flex, Box } from "rebass/styled-components";
import ImageUpload from "components/composer/attachment/ImageUpload";

import DefaultCompanyAvatar from "assets/images/defaults/company-default-avatar.png";

import { Logo, OnlyIconButton } from "./styles";

function AvatarUpload({ value, label, onUpload, openFileModalByDefault, ...props }) {
  const [isDropzoneVisible, setDropzoneVisible] = useState(false);

  const handleUpload = (images) => {
    setDropzoneVisible(false);
    onUpload(images);
  };

  const onUploadCancel = () => {
    setDropzoneVisible(false);
  }

  return (
    <Box mb={24} {...props}>
      <Flex  alignItems="center" style={{ position: 'relative' }}>
        {isDropzoneVisible ? 
          <ImageUpload onUpload={handleUpload} onCancel={onUploadCancel} openFileModalByDefault={openFileModalByDefault} />
          : <>
              <Logo height={72} width={72} src={value || DefaultCompanyAvatar} margin={0}
                onError={(e)=>{e.target.onerror = null; e.target.src=DefaultCompanyAvatar }}
              />
              <OnlyIconButton
                  onClick={() => setDropzoneVisible(true)}
                >
                  <span className="icon-edit"></span>
                </OnlyIconButton>
            </>
        }
      </Flex>
    </Box>
  );
}

export default AvatarUpload;