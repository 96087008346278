import _ from "lodash";
import styled from "styled-components";
import ReactLoading from "react-loading";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Box, Flex, Button } from "rebass/styled-components";
import { ChevronDown, EditAlt } from "styled-icons/boxicons-regular";

import PostEntry from "./widgets/PostEntry";
import EmptyState from "../common/EmptyState";
import Popper from "components/common/Popper";
import ChannelToggle from "../common/ChannelToggle";
import { PostContainer } from "./widgets/PostEntry";
import Menu, { MenuContainer } from "../navigation/Menu";
import { Headline2, Description } from "../common/styles";
import { ChannelToggleContainer } from "../common/ChannelToggle";

const AnalyticsSection = styled(Box)`
  flex: 1;
  margin: 24px 0;
`;

const AnalyticsView = styled(Flex)`
  flex-direction: column;
  margin: 16px 24px;
  max-width: 1128px;
  width: 100%;

  .report {
    & * {
      box-shadow: unset !important;
    }

    ${PostContainer} {
      border: 1px solid ${props => props.theme.colors.grey04};
    }

    ${ChannelToggleContainer} {
      visibility: hidden;
    }
  }
`;

const HeadingWrapper = styled(Flex)`
  display: inline-flex;
  gap: 4px;
`;

const SortButton = styled(Button)`
  align-items: center;
  appearance: none;
  background: transparent;
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
  display: inline-flex;
  font-weight: bold;
  font-family: ${props => props.theme.fonts.headline};
  letter-spacing: -0.2px;
  padding: 0;
`;

const sortOptions = [
  {
    title: "Newest",
    label: "Date",
    value: "date"
  },
  {
    title: "Top scored",
    label: "Score",
    value: "score"
  },
  {
    title: "Highest engagement",
    description: "Doesn't include LinkedIn Profiles",
    label: "Engagement",
    value: "engagements"
  },
  {
    title: "Most impressions",
    description: "Doesn't include LinkedIn Profiles",
    label: "Impressions",
    value: "impressions"
  },
  {
    title: "Engagement rate",
    description: "Doesn't include LinkedIn Profiles",
    label: "Engagement rate",
    value: "engagementRate"
  }
];

function AnalyticsPosts({
  data,
  channels,
  selectedChannels,
  loading,
  sortBy,
  onChannelSelect,
  onSortChange
}) {
  const account = useSelector(state => state.account.data);
  const history = useHistory();
  const [sortOption, setSortOption] = useState(sortBy);

  const [referenceElement, setReferenceElement] = useState(null);
  const [showOptions, setShowOptions] = useState(false);
  const toggleShowOptions = () => setShowOptions(!showOptions);

  useEffect(() => {
    setSortOption(sortOptions.find(opt => opt.value === sortBy));
  }, [sortBy]);

  if (loading)
    return (
      <Flex justifyContent={"center"} my={32}>
        <ReactLoading type={"spin"} color={"#bbb"} width={32} />
      </Flex>
    );

  if (data.length === 0)
    return (
      <EmptyState
        loading={loading}
        header={"No posts in this period"}
        description={
          "It seems that you have not been posting much. Please create a post to see some analytics."
        }
        icon={{
          component: EditAlt,
          width: "48px",
          height: "48px"
        }}
        cta={{
          text: "Schedule a post",
          onClick: () => history.push(`/accounts/${account.id}/schedule/week`)
        }}
      />
    );

  const groupedData = _.chunk(data, 6);

  return (
    <AnalyticsView>
      <div id="analytics-report">
        <div className="analytics-report-page">
          <AnalyticsSection>
            <Flex
              flex="1"
              alignItems="center"
              justifyContent={"space-between"}
              height={40}
            >
              <HeadingWrapper>
                <Headline2 mb={0}>Posts sorted by</Headline2>
                <Headline2 ref={setReferenceElement}>
                  <SortButton onClick={toggleShowOptions}>
                    {sortOption.title} <ChevronDown size={24} />
                  </SortButton>
                  <Popper
                    referenceElement={referenceElement}
                    visible={showOptions}
                    onClose={() => setShowOptions(false)}
                    exceptions={[referenceElement]}
                  >
                    <MenuContainer width={480}>
                      <Menu
                        items={sortOptions}
                        onSelect={data => {
                          onSortChange(data);
                          setShowOptions(false);
                        }}
                      />
                    </MenuContainer>
                  </Popper>
                </Headline2>
              </HeadingWrapper>
              <ChannelToggle
                channels={channels}
                selectedIds={selectedChannels}
                onSave={onChannelSelect}
              />
            </Flex>
            <Description>
              The analytics for your most recent posts may be delayed by a
              couple of hours.
            </Description>
          </AnalyticsSection>
          <AnalyticsSection>
            {groupedData[0].map(post => (
              <PostEntry key={post.id} data={post} />
            ))}
          </AnalyticsSection>
        </div>
        {groupedData.slice(1).map((posts, index) => (
          <div key={`grouped-${index}`} className="analytics-report-page">
            <AnalyticsSection>
              {posts.map(post => (
                <PostEntry key={post.id} data={post} />
              ))}
            </AnalyticsSection>
          </div>
        ))}
      </div>
    </AnalyticsView>
  );
}

export default AnalyticsPosts;
