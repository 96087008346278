import React from "react";
import styled, { css } from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import { Headline1 } from "../../common/styles";
import impressionsIcon from "../../../assets/images/impressions@2x.png";
import engagementIcon from "../../../assets/images/engagement@2x.png";
import totalAudienceIcon from "../../../assets/images/total-audience@2x.png";
import newFollowersIcon from "../../../assets/images/new-followers@2x.png";
import metricTickerIcon from "../../../assets/images/ticker-icon.svg";
import { useSelector } from "react-redux";
import { calculateGrowthRate } from "../../../utils/stats";

const MetricGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const MetricIcon = styled(Box)`
  color: ${props => props.theme.colors.text03};
  display: block;
  font-size: 36px;
  height: 36px;
  width: 36px;
`;

const MetricBody = styled(Box)`
  margin-top: 16px;
`;

const MetricFigure = styled(Headline1)`
  color: ${props =>
    props.isUnavailable
      ? props.theme.colors.text03
      : props.theme.colors.text01};
  font-family: ${props => props.theme.fonts.body};
`;

const MetricLabel = styled.span`
  color: ${props => props.theme.colors.text02};
  display: block;
  font-size: 14px;
  margin-top: 3px;
`;

export const StyledMetricTicker = styled.span`
  align-items: center;
  color: ${props =>
    props.value < 0
      ? props.theme.colors.red
      : props.value > 0
      ? props.theme.colors.green
      : props.theme.colors.text03};
  display: inline-flex;
  font-weight: bold;
`;

const TickerIcon = styled.div`
  background-color: ${props =>
    props.value < 0
      ? props.theme.colors.red
      : props.value > 0
      ? props.theme.colors.green
      : props.theme.colors.text03};
  height: 10px;
  margin: 0 4px 0 8px;
  mask: url(${metricTickerIcon}) no-repeat center;
  transform: rotate(${props => (props.value < 0 ? 180 : 0)}deg);
  width: 10px;
`;

const TickerIdle = styled.span`
  margin: 0 4px 0 4px;
`;

export const MetricTicker = ({ value }) => {
  return (
    value !== null && (
      <StyledMetricTicker value={value}>
        {Math.abs(parseFloat(value)) > 1e-5 ? (
          <>
            <TickerIcon value={value} /> <span>{value}%</span>
          </>
        ) : (
          <TickerIdle>-</TickerIdle>
        )}
      </StyledMetricTicker>
    )
  );
};

export const WidgetMetrics = styled(Box)`
  background: white;
  box-shadow: ${props =>
    props.withShadow ? "0 -4px 6px -6px rgba(0, 0, 0, 0.12)" : "none"};
  display: flex;
  justify-content: space-evenly;
  margin-top: auto;
  position: relative;
  z-index: 2;
`;

export const WidgetMetric = styled(Flex)`
  align-items: ${props => (props.centered ? "center" : "flex-start")};
  border-radius: 8px;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  max-width: 30%;
  padding: 8px 12px 10px;

  &:hover {
    background-color: ${props => props.theme.colors.grey01};
  }

  ${props =>
    props.align === "left" &&
    css`
      padding: 8px 8px 12px 12px;

      & span {
        text-align: left;
      }
    `}
`;

export const WidgetMetricValue = styled.span`
  color: ${props =>
    props.theme.colors.socialHealth[props.metric] ?? props.theme.colors.text01};
  font-size: 16px;
  font-weight: bold;
  padding: 4px 0;

  ${props =>
    props.isAvailable === false &&
    css`
      color: ${props => props.theme.colors.grey03};
    `}
`;

export const WidgetMetricLabel = styled.span`
  color: ${props => props.theme.colors.text02};
  letter-spacing: -0.12px;
  font-size: 13px;
  text-align: ${props => (props.align === "center" ? "center" : "left")};
  width: 100%;

  & small {
    color: ${props => props.theme.colors.grey03};
    display: block;
    font-size: 10px;
    font-weight: bold;
    margin: 2px 0;
    text-transform: uppercase;
  }
`;

export const TooltipContainer = styled(Box)`
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.05), 0 5px 20px rgba(17, 17, 17, 0.08);
  max-width: 240px;
  padding: 4px 20px;

  &::before {
    content: "";
    display: block;
    position: absolute;
    top: -16px;
    border-width: 8px;
    border-color: transparent transparent white transparent;
    border-style: solid;
  }
`;

export const TooltipTitle = styled.h4`
  border-bottom: 1px solid ${props => props.theme.colors.grey02};
  color: ${props => props.theme.colors.text01};
  font-size: 14px;
  font-weight: bold;
  padding: 12px 0;
`;

export const TooltipDescription = styled.p`
  color: ${props => props.theme.colors.text01};
  font-size: 14px;
  padding: 12px 0;
  margin-bottom: 0;
`;

export const WidgetExplainToggle = styled.div`
  cursor: pointer;
  color: ${props => props.theme.colors.text03};
  margin-left: auto;
`;

export const WidgetExplainTitle = styled.h4`
  color: ${props => props.theme.colors.text01};
  font-size: 16px;
  font-weight: bold;
  font-family: ${props => props.theme.fonts.headline};
  text-align: center;
  margin: 8px 0;
`;

export const WidgetExplainParagraph = styled.p`
  color: ${props => props.theme.colors.text02};
  font-size: 14px;
  text-align: center;
`;

export const WidgetExplainFooter = styled(Flex)`
  border-top: 1px solid ${props => props.theme.colors.grey01};
  justify-content: space-between;
  padding: 12px 2px;

  & > * {
    font-weight: bold;
  }
`;

export default function Metrics({ data, isLinkedinOnly }) {
  const { impressions, engagements, followers } = data;
  const statRates = calculateGrowthRate(data);
  const account = useSelector(state => state.account.data);

  const locale = account.billingCountry === "US" ? "en-US" : "nl-BE";
  const metricToLocale = val => new Intl.NumberFormat(locale).format(val);

  return (
    <MetricGrid>
      <Box p={16}>
        <MetricIcon>
          <img
            src={impressionsIcon}
            alt={"Impressions"}
            width={"36"}
            height={"36"}
          />
        </MetricIcon>
        <MetricBody>
          <MetricFigure>{metricToLocale(impressions.period)}</MetricFigure>
          <MetricLabel>
            Impressions
            <MetricTicker value={statRates.impressions}>
              {statRates.impressions
                ? Math.abs(statRates.impressions) + "%"
                : "N/A"}
            </MetricTicker>
          </MetricLabel>
        </MetricBody>
      </Box>
      <Box p={16}>
        <MetricIcon>
          <img
            src={engagementIcon}
            alt={"Engagement"}
            width={"36"}
            height={"36"}
          />
        </MetricIcon>
        <MetricBody>
          <MetricFigure>{metricToLocale(engagements.period)}</MetricFigure>
          <MetricLabel>
            Engagement
            <MetricTicker value={statRates.engagements}>
              {statRates.engagements
                ? Math.abs(statRates.engagements) + "%"
                : "N/A"}
            </MetricTicker>
          </MetricLabel>
        </MetricBody>
      </Box>
      <Box p={16}>
        <MetricIcon>
          <img
            src={totalAudienceIcon}
            alt={"Total audience"}
            width={"36"}
            height={"36"}
          />
        </MetricIcon>
        <MetricBody>
          <MetricFigure isUnavailable={isLinkedinOnly}>
            {isLinkedinOnly ? "n/a" : metricToLocale(followers.total)}
          </MetricFigure>
          <MetricLabel>
            Total Audience
            <MetricTicker value={statRates.followers}>
              {statRates.followers}
            </MetricTicker>
          </MetricLabel>
        </MetricBody>
      </Box>
      <Box p={16}>
        <MetricIcon>
          <img
            src={newFollowersIcon}
            alt={"New followers"}
            width={"36"}
            height={"36"}
          />
        </MetricIcon>
        <MetricBody>
          <MetricFigure isUnavailable={isLinkedinOnly}>
            {isLinkedinOnly ? "n/a" : metricToLocale(followers.period)}
          </MetricFigure>
          <MetricLabel>New followers</MetricLabel>
        </MetricBody>
      </Box>
    </MetricGrid>
  );
}
