import styled, { css } from "styled-components";
import { Flex, Box } from "rebass/styled-components";

import Button from "components/common/Button";

export const AuthLayout = styled(Flex)`
  height: 100vh;
  position: relative;
`;

export const AuthImage = styled(Box)<{
  fadeOut?: boolean;
  bgImage?: string;
  position?: string;
}>`
  background-image: url(${(props: any) => props.bgImage});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: ${props => props.position ?? "center"};
  flex: 1;
  z-index: 0;
  /* max-width: 444px; */
  position: relative;

  &::after {
    background-color: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 1)
    );
    bottom: 0;
    content: "";
    display: ${(props: any) => (props.fadeOut ? "block" : "none")};
    height: 50%;
    position: absolute;
    width: 100%;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const AuthBody = styled(Flex)`
  flex: 1;
  height: 100%;
  padding: 0 16px;
  /* min-width: max-content; */
  max-width: 864px;
  overflow-y: auto;
  background: #ffffff;
  box-shadow: 0px 30px 60px -30px rgba(0, 0, 0, 0.15),
    0px 50px 100px rgba(0, 0, 0, 0.2), 0px 0px 1px rgba(0, 0, 0, 0.08);

  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const Seperation = styled.div`
  display: flex;
  flex-direction: row;
  width: 400px;
  color: ${props => props.theme.colors.text03};
  margin-bottom: 6px;

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid rgba(102, 119, 128, 0.16);
    margin: auto;
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const FormBox = styled(Box)<{
  maxWidth?: string;
  minWidth?: string;
  isTransitioned?: boolean;
}>`
  align-items: center;
  background: white;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: ${props => props.maxWidth ?? "480px"};
  min-width: ${props => props.minWidth ?? "0px"};
  padding: 10px 0px;
  position: relative;
  width: 100%;

  & form {
    margin-top: 0;
    width: 100%;
  }

  ${(props: any) =>
    props.isTransitioned &&
    css`
      transition: 0.5s;
      opacity: ${({ state }: any) => (state === "entered" ? 1 : 0)};
      transform: ${({ state }: any) =>
        state === "entering"
          ? "translateX(64px)"
          : state === "exiting"
          ? "translateX(-64px)"
          : "translateX(0)"};
      display: ${({ state }: any) => (state === "exited" ? "none" : "block")};
    `};
`;

export const FormLogo = styled(Box)<{ mb?: string }>`
  box-sizing: content-box;
  height: 40px;
  margin-bottom: ${(props: any) => props.mb || "auto"};
  margin-top: 24px;
  padding-bottom: 16px;
  vertical-align: middle;
`;

export const FormContainer = styled(Flex)`
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.5s;
`;

export const RoundedButton = styled(Button)`
  position: relative;
  border-radius: 12px;
`;

export const ContentWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
`;

export const FieldWrapper = styled.div`
  width: 100%;
  & input {
    border: 1px solid transparent;
  }

  & input:placeholder-shown {
    background-color: ${(props: any) => props.theme.colors.grey01};
  }

  & input:hover {
    border: 1px solid ${(props: any) => props.theme.colors.text03};
  }

  & input:not(:placeholder-shown),
  & input:active {
    background-color: ${(props: any) => props.theme.colors.grey01};
  }
  & input:focus {
    background-color: ${(props: any) => props.theme.colors.grey01};
    box-shadow: 0 0 1px ${props => props.theme.colors.background1},
      0 0 0 1px ${props => props.theme.colors.background1},
      0 0 0 2px ${props => props.theme.colors.iconFill4} !important;
    border: none;
  }
`;

export const InputWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`;

export const FormMessage = styled.p<{ mt?: number; error?: boolean }>`
  margin-top: ${(props: any) => props.mt ?? 8}px;
  margin-bottom: 0px;
  text-align: center;
  color: ${props =>
    props.error ? props.theme.colors.red : props.theme.colors.text03};
`;

export const CheckboxWrapper = styled(Flex)<{ justifyContent?: string }>`
  flex-direction: row;
  justify-content: ${props => props.justifyContent ?? "center"};
  gap: 10px;
  align-items: center;
  margin-bottom: 35px;

  span {
    font-size: 16px;
  }
`;

export const LogoImg = styled.img`
  vertical-align: middle;
  height: 40px;
`;

export const TelenetSeparator = styled.span`
  vertical-align: middle;
  margin: 10px;
`;
