import React, { useState } from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";
import { Flex } from "rebass/styled-components";

import { ShelveImage, TopicTitle } from "../styles";

import { IEnrichedContent } from "@hellocontento/contento-common";

import ContentList from "../ContentList";
import { ISource } from "services/news";

import SeeMore from "components/common/seeMore";
import TwitterSource from "assets/images/social/twitter-black-circle.png";
import KeywordSource from "assets/images/contents/Keyword.png";
import RSSSource from "assets/images/social/rss-black-circle.png";
import { Headline3 } from "components/common/styles";

interface IShelveProps {
  section: any;
  type: string;
  contents: Array<any>;
  onRefresh: () => void;
  accountId: string;
}

export const SourceType = styled.div<{ source?: string }>`
  box-sizing: border-box;
  background: ${props => props.theme.colors.white};
  ${props =>
    props.source &&
    css`
      background: url(${props.source}) no-repeat center center;
      background-size: cover;
      background-repeat: no-repeat;
    `}
  border-radius: 50%;
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateX(25%);
`;

export const SourceWrapper = styled(Flex)`
  position: relative;
  margin-right: 16px;
`;

export const SourceKeywordImage = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: ${props => props.theme.colors.black};
  border-radius: 16px;
  border: 1px solid rgba(96, 116, 128, 0.12);
  transform-origin: center;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);

  > span {
    color: white;
    font-size: 24px;
    font-weight: 600;
  }
`;

const Shelve: React.FC<IShelveProps> = ({
  type,
  section,
  contents,
  accountId,
  onRefresh
}) => {
  const history = useHistory();

  const [localContents, setLocalContents] = useState<Array<IEnrichedContent>>(
    contents
  );

  const navigateTo = (url: string) => {
    history.push(url);
  };

  const onDismiss = (contentId: string, domain: string | null = null) => {
    if (domain) {
      setLocalContents(localContents.filter(item => item.domain !== domain));
    } else {
      setLocalContents(localContents.filter(item => item.id !== contentId));
    }
  };

  const sourceImage = (function() {
    if (type === "keywords") return KeywordSource;
    const data = section as ISource;

    switch (data.type) {
      case "twitter":
        return TwitterSource;
      case "rss":
        return RSSSource;
      default:
        return null;
    }
  })();

  const sourceLink = (function() {
    let topicUrl = "";

    if (type === "topics") {
      topicUrl = `/accounts/${accountId}/content/${type}/${section.id}/${section.language}`;
    } else if (type === "keywords") {
      topicUrl = `/accounts/${accountId}/content/${type}/${section.id}`;
    } else {
      if (section.type === "twitter") {
        topicUrl = `/accounts/${accountId}/content/influencers/${section.id}`;
      } else if (section.type === "rss") {
        topicUrl = `/accounts/${accountId}/content/rss/${section.id}`;
      } else {
        topicUrl = `/accounts/${accountId}/content/domains/${section.id}`;
      }
    }
    return topicUrl;
  })();

  return (
    <>
      <Flex mb={20} justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <SourceWrapper>
            {type === "keywords" ? (
              <SourceKeywordImage>
                <span className="icon-search" />
              </SourceKeywordImage>
            ) : (
              // @ts-ignore
              <ShelveImage src={section.image} type={type} />
            )}
            {sourceImage && <SourceType source={sourceImage} />}
          </SourceWrapper>
          <TopicTitle to={sourceLink}>
            <Headline3>{section.title || section.name}</Headline3>
          </TopicTitle>
        </Flex>
        <SeeMore handleClick={() => navigateTo(sourceLink)} />
      </Flex>
      {/* @ts-ignore */}
      <ContentList
        items={localContents}
        onScheduled={onRefresh}
        onDismiss={onDismiss}
      />
    </>
  );
};

export default Shelve;
