import {
  OPEN_COMPOSER,
  CLOSE_COMPOSER,
  TOGGLE_PRO_MODE,
  SET_IN_DRAFT_MODE,
  SET_POST_DATA,
  RESET_POST_DATA,
  ENABLE_SPLIT_MODE,
  DISABLE_SPLIT_MODE,
  SET_TAGS,
  SET_EDITOR_STATE_OBJ,
  SET_VISIBLE_CAPTION,
  SET_VALIDATION_ERRORS,
  SET_CHOSEN_SUGGESTED_CAPTION,
  SET_POST_CONCEPTS,
  SET_POST_IDEA_CAPTIONS,
  SET_ATTACHMENT_TYPE,
  SET_IS_UPLOADING_ATTACHMENTS,
  ADD_ATTACHMENTS,
  REMOVE_ATTACHMENT,
  REMOVE_ARTICLE_ATTACHMENT,
  REORDER_ATTACHMENT,
  SET_ATTACHMENT_VALIDATION_ERROR,
  SET_ATTACHMENTS,
  SET_ATTACHMENT_OPTIONS,
  SET_IS_SUBMITTING,
  SET_IS_DELETING,
  SET_WAS_SCHEDULE_CHANGED,
  SET_PICKED_DATE,
  SET_MOOD_FILTER,
  SET_IS_LOADING_SUGGESTED_IMAGES,
  SET_NEW_IMAGES_INDICATION,
  SET_IMAGES_SEARCH_TERM,
  SET_SUGGESTED_IMAGES,
  SET_IMAGES_QUERY,
  SET_ACTIVE_TOOLKIT,
  SET_CONFIRMATION_MODAL_PROPS,
  SET_IS_COMPOSER_CLOSE_CONFIRMATION_OPEN
} from "./actions";
import { IChannelAttachments, ICaption, IComposerState } from "./reducer";

export interface IOpenComposerAction {
  type: typeof OPEN_COMPOSER;
}

export const openComposerAction = (): IOpenComposerAction => ({
  type: OPEN_COMPOSER
});

export interface ICloseComposerAction {
  type: typeof CLOSE_COMPOSER;
}

export const closeComposerAction = (): ICloseComposerAction => ({
  type: CLOSE_COMPOSER
});

export interface IToggleProModeAction {
  type: typeof TOGGLE_PRO_MODE;
  payload?: IComposerState["inProMode"];
}

export const toggleProModeAction = (
  payload?: IComposerState["inProMode"]
): IToggleProModeAction => ({
  type: TOGGLE_PRO_MODE,
  payload
});

export interface ISetInDraftModeAction {
  type: typeof SET_IN_DRAFT_MODE;
  payload: IComposerState["inDraftMode"];
}

export const setInDraftModeAction = (
  payload: IComposerState["inDraftMode"]
): ISetInDraftModeAction => ({
  type: SET_IN_DRAFT_MODE,
  payload
});

export interface ISetPostDataAction {
  type: typeof SET_POST_DATA;
  payload: IComposerState["postData"];
}

export const setPostDataAction = (
  payload: IComposerState["postData"]
): ISetPostDataAction => ({
  type: SET_POST_DATA,
  payload
});

export interface IResetPostDataAction {
  type: typeof RESET_POST_DATA;
}

export const resetPostDataAction = (): IResetPostDataAction => ({
  type: RESET_POST_DATA
});

export interface IEnableSplitModeAction {
  type: typeof ENABLE_SPLIT_MODE;
}

export const enableSplitModeAction = (): IEnableSplitModeAction => ({
  type: ENABLE_SPLIT_MODE
});

export interface IDisableSplitModeAction {
  type: typeof DISABLE_SPLIT_MODE;
}

export const disableSplitModeAction = (): IDisableSplitModeAction => ({
  type: DISABLE_SPLIT_MODE
});

export interface ISetTagsAction {
  type: typeof SET_TAGS;
  payload: IComposerState["tags"];
}

export const setTagsAction = (
  payload: IComposerState["tags"]
): ISetTagsAction => ({
  type: SET_TAGS,
  payload
});

export interface ISetEditorStateObjAction {
  type: typeof SET_EDITOR_STATE_OBJ;
  payload: IComposerState["editorStateObj"];
}

export const setEditorStateObjAction = (
  payload: IComposerState["editorStateObj"]
): ISetEditorStateObjAction => ({
  type: SET_EDITOR_STATE_OBJ,
  payload
});

export interface ISetVisibleCaptionAction {
  type: typeof SET_VISIBLE_CAPTION;
  payload: IComposerState["visibleCaption"];
}

export const setVisibleCaptionAction = (
  payload: IComposerState["visibleCaption"]
): ISetVisibleCaptionAction => ({
  type: SET_VISIBLE_CAPTION,
  payload
});

export interface ISetValidationErrorsAction {
  type: typeof SET_VALIDATION_ERRORS;
  payload: IComposerState["validationErrors"];
}

export const setValidationErrorsAction = (
  payload: IComposerState["validationErrors"]
): ISetValidationErrorsAction => ({
  type: SET_VALIDATION_ERRORS,
  payload
});

export interface ISetChosenSuggestedCaptionAction {
  type: typeof SET_CHOSEN_SUGGESTED_CAPTION;
  payload: IComposerState["chosenSuggestedCaption"];
}

export const setChosenSuggestedCaptionAction = (
  payload: IComposerState["chosenSuggestedCaption"]
): ISetChosenSuggestedCaptionAction => ({
  type: SET_CHOSEN_SUGGESTED_CAPTION,
  payload
});

export interface ISetPostConceptsAction {
  type: typeof SET_POST_CONCEPTS;
  payload: IComposerState["postConcepts"];
}

export const setPostConceptsAction = (
  payload: IComposerState["postConcepts"]
): ISetPostConceptsAction => ({
  type: SET_POST_CONCEPTS,
  payload
});

export interface ISetPostIdeaCaptionsAction {
  type: typeof SET_POST_IDEA_CAPTIONS;
  postIdeaId: string;
  payload: ICaption[];
}

export const setPostIdeaCaptionsAction = (
  postIdeaId: string,
  payload: ICaption[]
): ISetPostIdeaCaptionsAction => ({
  type: SET_POST_IDEA_CAPTIONS,
  postIdeaId,
  payload
});

export interface ISetAttachmentTypeAction {
  type: typeof SET_ATTACHMENT_TYPE;
  payload: IChannelAttachments["type"];
  channel: string;
}

export const setAttachmentTypeAction = (
  payload: IChannelAttachments["type"],
  channel?: string
): ISetAttachmentTypeAction => ({
  type: SET_ATTACHMENT_TYPE,
  payload,
  channel: channel || "all"
});

export interface ISetIsUploadingAttachmentsAction {
  type: typeof SET_IS_UPLOADING_ATTACHMENTS;
  payload: boolean;
  attachmentType: NonNullable<IChannelAttachments["type"]>;
  channel: string;
}

export const setIsUploadingAttachmentsAction = (
  payload: boolean,
  attachmentType: NonNullable<IChannelAttachments["type"]>,
  channel?: string
): ISetIsUploadingAttachmentsAction => ({
  type: SET_IS_UPLOADING_ATTACHMENTS,
  payload,
  attachmentType,
  channel: channel || "all"
});

export interface IAddAttachmentsAction {
  type: typeof ADD_ATTACHMENTS;
  payload: any;
  attachmentType: NonNullable<IChannelAttachments["type"]>;
  channel: string;
}

export const addAttachmentsAction = (
  payload: any,
  attachmentType: NonNullable<IChannelAttachments["type"]>,
  channel?: string
): IAddAttachmentsAction => ({
  type: ADD_ATTACHMENTS,
  payload,
  attachmentType,
  channel: channel || "all"
});

export interface IRemoveAttachmentAction {
  type: typeof REMOVE_ATTACHMENT;
  index: number;
  attachmentType: Omit<NonNullable<IChannelAttachments["type"]>, "article">;
  channel: string;
}

export const removeAttachmentAction = (
  index: number,
  attachmentType: Omit<NonNullable<IChannelAttachments["type"]>, "article">,
  channel?: string
): IRemoveAttachmentAction => ({
  type: REMOVE_ATTACHMENT,
  index,
  attachmentType,
  channel: channel || "all"
});

export interface IRemoveArticleAttachmentAction {
  type: typeof REMOVE_ARTICLE_ATTACHMENT;
  channel: string;
}

export const removeArticleAttachmentAction = (
  channel?: string
): IRemoveArticleAttachmentAction => ({
  type: REMOVE_ARTICLE_ATTACHMENT,
  channel: channel || "all"
});

export interface IReorderAttachmentAction {
  type: typeof REORDER_ATTACHMENT;
  sourceIndex: number;
  destinationIndex: number;
  attachmentType: NonNullable<IChannelAttachments["type"]>;
  channel: string;
}

export const reorderAttachmentAction = (
  sourceIndex: number,
  destinationIndex: number,
  attachmentType: NonNullable<IChannelAttachments["type"]>,
  channel?: string
): IReorderAttachmentAction => ({
  type: REORDER_ATTACHMENT,
  sourceIndex,
  destinationIndex,
  attachmentType,
  channel: channel || "all"
});

export interface ISetAttachmentValidationAction {
  type: typeof SET_ATTACHMENT_VALIDATION_ERROR;
  payload: any[];
  channel: string;
}

export const setAttachmentValidationAction = (
  payload: any[],
  channel?: string
): ISetAttachmentValidationAction => ({
  type: SET_ATTACHMENT_VALIDATION_ERROR,
  payload,
  channel: channel || "all"
});

export interface ISetAttachmentsAction {
  type: typeof SET_ATTACHMENTS;
  payload: Partial<IChannelAttachments>;
  channel: string;
}

export const setAttachmentsAction = (
  payload: Partial<IChannelAttachments>,
  channel?: string
): ISetAttachmentsAction => ({
  type: SET_ATTACHMENTS,
  payload,
  channel: channel || "all"
});

export interface ISetAttachmentOptionsAction {
  type: typeof SET_ATTACHMENT_OPTIONS;
  payload: { [key: string]: any };
  channel: string;
}

export const setAttachmentOptionsAction = (
  payload: { [key: string]: any },
  channel?: string
): ISetAttachmentOptionsAction => ({
  type: SET_ATTACHMENT_OPTIONS,
  payload,
  channel: channel || "all"
});

export interface ISetIsSubmittingAction {
  type: typeof SET_IS_SUBMITTING;
  payload: IComposerState["scheduler"]["isSubmitting"];
}

export const setIsSubmittingAction = (
  payload: IComposerState["scheduler"]["isSubmitting"]
): ISetIsSubmittingAction => ({
  type: SET_IS_SUBMITTING,
  payload
});

export interface ISetIsDeletingAction {
  type: typeof SET_IS_DELETING;
  payload: IComposerState["scheduler"]["isDeleting"];
}

export const setIsDeletingAction = (
  payload: IComposerState["scheduler"]["isDeleting"]
): ISetIsDeletingAction => ({
  type: SET_IS_DELETING,
  payload
});

export interface ISetWasScheduleChangedAction {
  type: typeof SET_WAS_SCHEDULE_CHANGED;
  payload: IComposerState["scheduler"]["wasScheduleChanged"];
}

export const setWasScheduleChangedAction = (
  payload: IComposerState["scheduler"]["wasScheduleChanged"]
): ISetWasScheduleChangedAction => ({
  type: SET_WAS_SCHEDULE_CHANGED,
  payload
});

export interface ISetPickedDateAction {
  type: typeof SET_PICKED_DATE;
  payload: IComposerState["scheduler"]["pickedDate"];
}

export const setPickedDateAction = (
  payload: IComposerState["scheduler"]["pickedDate"]
): ISetPickedDateAction => ({
  type: SET_PICKED_DATE,
  payload
});

export interface ISetMoodFilterAction {
  type: typeof SET_MOOD_FILTER;
  payload: IComposerState["moodFilter"];
}

export const setMoodFilterAction = (
  payload: IComposerState["moodFilter"]
): ISetMoodFilterAction => ({
  type: SET_MOOD_FILTER,
  payload
});

export interface ISetIsLoadingSuggestedImagesAction {
  type: typeof SET_IS_LOADING_SUGGESTED_IMAGES;
  payload: IComposerState["suggestedImages"]["isLoading"];
}

export const setIsLoadingSuggestedImagesAction = (
  payload: IComposerState["suggestedImages"]["isLoading"]
): ISetIsLoadingSuggestedImagesAction => ({
  type: SET_IS_LOADING_SUGGESTED_IMAGES,
  payload
});

export interface ISetNewImagesIndicationAction {
  type: typeof SET_NEW_IMAGES_INDICATION;
  payload: IComposerState["suggestedImages"]["newImagesIndication"];
}

export const setNewImagesIndicationAction = (
  payload: IComposerState["suggestedImages"]["newImagesIndication"]
): ISetNewImagesIndicationAction => ({
  type: SET_NEW_IMAGES_INDICATION,
  payload
});

export interface ISetImagesSearchTermAction {
  type: typeof SET_IMAGES_SEARCH_TERM;
  payload: IComposerState["suggestedImages"]["searchTerm"];
}

export const setImagesSearchTermAction = (
  payload: IComposerState["suggestedImages"]["searchTerm"]
): ISetImagesSearchTermAction => ({
  type: SET_IMAGES_SEARCH_TERM,
  payload
});

export interface ISetSuggestedImagesAction {
  type: typeof SET_SUGGESTED_IMAGES;
  payload: IComposerState["suggestedImages"]["images"];
}

export const setSuggestedImagesAction = (
  payload: IComposerState["suggestedImages"]["images"]
): ISetSuggestedImagesAction => ({
  type: SET_SUGGESTED_IMAGES,
  payload
});

export interface ISetImagesQueryAction {
  type: typeof SET_IMAGES_QUERY;
  payload: IComposerState["suggestedImages"]["query"];
}

export const setImagesQueryAction = (
  payload: IComposerState["suggestedImages"]["query"]
): ISetImagesQueryAction => ({
  type: SET_IMAGES_QUERY,
  payload
});

export interface ISetActiveToolkitAction {
  type: typeof SET_ACTIVE_TOOLKIT;
  payload: IComposerState["activeToolkit"];
}

export const setActiveToolkitAction = (
  payload: IComposerState["activeToolkit"]
): ISetActiveToolkitAction => ({
  type: SET_ACTIVE_TOOLKIT,
  payload
});

export interface ISetConfirmationModalPropsAction {
  type: typeof SET_CONFIRMATION_MODAL_PROPS;
  payload: IComposerState["confirmationModalProps"];
}

export const setConfirmationModalPropsAction = (
  payload: IComposerState["confirmationModalProps"]
): ISetConfirmationModalPropsAction => ({
  type: SET_CONFIRMATION_MODAL_PROPS,
  payload
});

export interface ISetIsComposerCloseConfirmationOpenAction {
  type: typeof SET_IS_COMPOSER_CLOSE_CONFIRMATION_OPEN;
  payload: IComposerState["isComposerCloseConfirmationOpen"];
}

export const setIsComposerCloseConfirmationOpenAction = (
  payload: IComposerState["isComposerCloseConfirmationOpen"]
): ISetIsComposerCloseConfirmationOpenAction => ({
  type: SET_IS_COMPOSER_CLOSE_CONFIRMATION_OPEN,
  payload
});

export type IActions =
  | IOpenComposerAction
  | ICloseComposerAction
  | IToggleProModeAction
  | ISetInDraftModeAction
  | ISetPostDataAction
  | IResetPostDataAction
  | IEnableSplitModeAction
  | IDisableSplitModeAction
  | ISetTagsAction
  | ISetEditorStateObjAction
  | ISetVisibleCaptionAction
  | ISetValidationErrorsAction
  | ISetChosenSuggestedCaptionAction
  | ISetPostConceptsAction
  | ISetPostIdeaCaptionsAction
  | ISetAttachmentTypeAction
  | ISetIsUploadingAttachmentsAction
  | IAddAttachmentsAction
  | IRemoveAttachmentAction
  | IRemoveArticleAttachmentAction
  | IReorderAttachmentAction
  | ISetAttachmentValidationAction
  | ISetAttachmentsAction
  | ISetAttachmentOptionsAction
  | ISetIsSubmittingAction
  | ISetIsDeletingAction
  | ISetWasScheduleChangedAction
  | ISetPickedDateAction
  | ISetMoodFilterAction
  | ISetIsLoadingSuggestedImagesAction
  | ISetNewImagesIndicationAction
  | ISetImagesSearchTermAction
  | ISetSuggestedImagesAction
  | ISetImagesQueryAction
  | ISetActiveToolkitAction
  | ISetConfirmationModalPropsAction
  | ISetIsComposerCloseConfirmationOpenAction;
