import queryString from "query-string";
import { useSelector } from "react-redux";
import { getTimezoneOffset } from "date-fns-tz";
import { useToaster } from "@hellocontento/maillard";
import React, { useState, useCallback, useEffect } from "react";

import {
  OptionGroup,
  DeleteButton,
  SchedulerButton,
  SchedulerWrapper,
  SchedulerOptions,
  SchedulerDropdown
} from "./styles";
import {
  today,
  getTime,
  setDate,
  isToday,
  tomorrow,
  getDayOfWeek,
  dayAfterTomorrow,
  getRandomMinutes,
  formatForScheduler
} from "utils/date";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import SchedulerModal from "./SchedulerModal";
import SchedulerOption from "./SchedulerOption";
import Loader from "components/common/loading/Loader";
import CloseComposerModal from "components/modals/closeComposerModal";
import OutsideClickDetector from "components/common/OutsideClickDetector";
import { fetchBestTimes } from "components/schedule/services/taskServices";

const Scheduler = () => {
  const addToast = useToaster();
  const account = useSelector(state => state.account.data);
  const inProMode = useComposerState(state => state.inProMode);
  const post = useComposerState(state => state.postData);
  const inDraftMode = useComposerState(state => state.inDraftMode);
  const isPosting = useComposerState(state => state.scheduler.isSubmitting);
  const isDeleting = useComposerState(state => state.scheduler.isDeleting);
  const pickedDate = useComposerState(state => state.scheduler.pickedDate);
  const wasScheduleChanged = useComposerState(
    state => state.scheduler.wasScheduleChanged
  );
  const attachments = useComposerState(state => state.attachments);
  const validationErrors = useComposerState(state => state.validationErrors);
  const isComposerCloseConfirmationOpen = useComposerState(
    state => state.isComposerCloseConfirmationOpen
  );
  const createPost = useComposerActions(actions => actions.createPost);
  const deleteEntry = useComposerActions(actions => actions.deleteEntry);
  const closeComposer = useComposerActions(actions => actions.closeComposer);
  const setPickedDate = useComposerActions(actions => actions.setPickedDate);
  const setWasScheduleChanged = useComposerActions(
    actions => actions.setWasScheduleChanged
  );
  const setIsComposerCloseConfirmationOpen = useComposerActions(
    actions => actions.setIsComposerCloseConfirmationOpen
  );

  const {
    type,
    photoAttachments,
    videoAttachments,
    articleAttachments,
    validationErrors: attachmentValidationErrors
  } = attachments["all"]; // ? use channel as param when splitting attachments wrt channels
  const [isLoading, setIsLoading] = useState(false);
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const [isSchedulerModalVisible, setIsSchedulerModalVisible] = useState(false);
  const toggleShowOptions = () => setIsOptionsVisible(!isOptionsVisible);

  const getServices = useCallback(() => {
    const uniqueActiveChannels = account.channels.reduce((acc, val) => {
      if (val.active && post.channels.includes(val.id)) {
        acc.add(val.service);
      }
      return acc;
    }, new Set());

    return queryString.stringify({ service: [...uniqueActiveChannels] });
  }, [account.channels, post.channels]);

  const fetchFavourableTime = useCallback(
    async date => {
      const serviceQry = getServices();
      const dayQry = queryString.stringify({
        dayOfTheWeek: getDayOfWeek(date).toUpperCase()
      });

      try {
        const data = await fetchBestTimes(
          account.id,
          `${serviceQry}&${dayQry}`
        );

        if (isToday(date)) {
          const { hour } = data.find(({ hour }) => hour > getTime(date).hours);

          return setDate(date, { hours: hour, minutes: getRandomMinutes() });
        } else {
          return setDate(date, {
            hours: data[0].hour,
            minutes: getRandomMinutes()
          });
        }
      } catch (err) {
        addToast("Something went wrong fetching best times.", "error");
        setPickedDate(tomorrow());
        throw err;
      }
    },
    [account.id, addToast, getServices]
  );

  const getOptionById = useCallback(
    id => {
      const SCHEDULER_OPTIONS = [
        {
          id: "set_date_time",
          label: "Set date & time",
          activeLabel: "Change date & time",
          optionIconClassName: "icon-calendar",
          callback: () => setIsSchedulerModalVisible(true)
        },
        {
          id: "auto_schedule",
          label: inDraftMode ? "Auto-schedule Draft" : "Auto-schedule",
          optionIconClassName: "icon-autopilot",
          description:
            "Finds the next empty task or the best time within 72 hours."
        },
        {
          id: "best_time_today",
          label: "Best time Today",
          optionIconClassName: inDraftMode ? "icon-draft-clock" : "icon-clock",
          callback: async () => {
            try {
              const date = await fetchFavourableTime(today());
              setPickedDate(date);
            } catch (_) {}
          }
        },
        {
          id: "best_time_tomorrow",
          label: "Best time Tomorrow",
          optionIconClassName: inDraftMode ? "icon-draft-clock" : "icon-clock",
          callback: async () => {
            try {
              const date = await fetchFavourableTime(tomorrow());
              setPickedDate(date);
            } catch (_) {}
          }
        },
        {
          id: "best_time_day_after_tomorrow",
          optionIconClassName: inDraftMode ? "icon-draft-clock" : "icon-clock",
          label: `Best time ${getDayOfWeek(dayAfterTomorrow())}`,
          callback: async () => {
            try {
              const date = await fetchFavourableTime(dayAfterTomorrow());
              setPickedDate(date);
            } catch (_) {}
          }
        },
        {
          id: "post_now",
          label: inDraftMode ? "Save Draft" : "Post now",
          optionIconClassName: inDraftMode ? "icon-draft" : "icon-send",
          buttonIconClassName: inDraftMode
            ? "icon-draft-filled"
            : "icon-send-filled"
        }
      ];

      if (Array.isArray(id)) {
        return SCHEDULER_OPTIONS.filter(option => id.includes(option.id));
      } else {
        return SCHEDULER_OPTIONS.find(option => option.id === id);
      }
    },
    [inDraftMode, fetchFavourableTime]
  );

  const SCHEDULER_OPTION_GROUPS = [
    getOptionById(["set_date_time", "auto_schedule"]),
    getOptionById([
      "best_time_today",
      "best_time_tomorrow",
      "best_time_day_after_tomorrow"
    ]),
    getOptionById(["post_now"])
  ];

  const isNewPost = !post.id;

  const [selectedOption, setSelectedOption] = useState(
    getOptionById(
      !post.id && post.contentId
        ? "auto_schedule"
        : !!post.scheduledAt || !!pickedDate
        ? "set_date_time"
        : "post_now"
    )
  );

  const setSchedulerOption = useCallback(
    optionId => {
      setSelectedOption(getOptionById(optionId ?? selectedOption.id));
      if (!wasScheduleChanged && optionId && selectedOption.id !== optionId) {
        setWasScheduleChanged(true);
      }
    },
    [getOptionById, selectedOption.id, wasScheduleChanged]
  );

  useEffect(() => {
    setSchedulerOption();
  }, [inDraftMode, setSchedulerOption]);

  const setCustomDate = date => {
    setPickedDate(date);
    setSchedulerOption("set_date_time");
  };

  const onOptionSelect = async option => {
    toggleShowOptions();

    if (option.callback) {
      setIsLoading(true);
      await option.callback();
      setIsLoading(false);
    }
    if (option.id !== "set_date_time") {
      setSchedulerOption(option.id);
    }
  };

  const handleSubmit = (fromModal = false) => {
    const scheduleTime =
      selectedOption.id === "post_now"
        ? "NOW"
        : selectedOption.id === "auto_schedule"
        ? "AUTO_SCHEDULE"
        : "CUSTOM";
    const scheduledAt = selectedOption.id !== "post_now" ? pickedDate : null;
    createPost(scheduleTime, scheduledAt, fromModal);
  };

  const buttonIcon = (() => {
    if (["post_now", "auto_schedule"].includes(selectedOption.id)) {
      return selectedOption.buttonIconClassName
        ? selectedOption.buttonIconClassName
        : selectedOption.optionIconClassName;
    } else {
      return "icon-clock-filled";
    }
  })();

  const buttonLabel = (() => {
    if (["post_now", "auto_schedule"].includes(selectedOption.id)) {
      return selectedOption.label;
    } else {
      if (!wasScheduleChanged && !isNewPost) {
        return `Save ${inDraftMode ? "draft changes" : "post"}`;
      }
      return `${
        inDraftMode ? `Save draft` : `Schedule`
      } for ${formatForScheduler(pickedDate)}`;
    }
  })();

  const isUploadingAttachments =
    type === "photo"
      ? photoAttachments.isUploading
      : type === "video"
      ? videoAttachments.isUploading
      : articleAttachments.isScrapping;

  const hasValidationErrors =
    !!Object.values(validationErrors).find(val => !!val) ||
    attachmentValidationErrors.filter(v => v.type === "error").length > 0;

  const isDisabled =
    isLoading ||
    isPosting ||
    isDeleting ||
    hasValidationErrors ||
    isUploadingAttachments;
  const canBeDeleted = !isNewPost && post.status === "SCHEDULED";

  const onCloseConfirmation = () => {
    setIsComposerCloseConfirmationOpen(false);
    closeComposer();
  };

  const style = {
    ...(inProMode ? { width: "100%" } : { flexGrow: 1 })
  };

  const isTimezoneDifferent =
    getTimezoneOffset(account.timezone) !==
    getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone);

  const timezoneAlertMsg =
    selectedOption.id !== "post_now" && isTimezoneDifferent
      ? "Account timezone different than browser timezone"
      : "";

  return (
    <>
      <CloseComposerModal
        isEdit={!!post.id}
        onClose={onCloseConfirmation}
        onSave={() => handleSubmit(true)}
        isOpen={isComposerCloseConfirmationOpen}
        isPostEmpty={!post.caption?.all && !post.attachment}
        onCancel={() => setIsComposerCloseConfirmationOpen(false)}
      />
      <OutsideClickDetector
        style={{ ...style }}
        onClose={() => setIsOptionsVisible(false)}
      >
        <SchedulerWrapper>
          {canBeDeleted && (
            <DeleteButton
              variant="danger"
              disabled={isDisabled}
              onClick={() => deleteEntry({ post })}
            >
              {isDeleting ? (
                <Loader size={24} />
              ) : (
                <i className="icon-delete" />
              )}
            </DeleteButton>
          )}
          <SchedulerButton
            size="m"
            isDraft={inDraftMode}
            disabled={isDisabled}
            onClick={() => handleSubmit()}
            variant={inDraftMode ? "primary" : "success"}
          >
            {isPosting && <Loader size={24} />}
            <i className={buttonIcon} />
            <div className="btn-content-wrapper" data-text={timezoneAlertMsg}>
              {buttonLabel}
              {!!timezoneAlertMsg && (
                <span className="timezone-alert">{timezoneAlertMsg}</span>
              )}
            </div>
          </SchedulerButton>
          <SchedulerDropdown
            size="m"
            disabled={isDisabled}
            onClick={toggleShowOptions}
            isOptionsVisible={isOptionsVisible}
            variant={inDraftMode ? "primary" : "success"}
          >
            <i className="icon-select" />
          </SchedulerDropdown>
          {isOptionsVisible && (
            <SchedulerOptions>
              {SCHEDULER_OPTION_GROUPS.map((optionGroup, index) => (
                <OptionGroup key={index}>
                  {optionGroup.map(option => {
                    if (!isNewPost && option.id === "auto_schedule") {
                      return null;
                    }

                    return (
                      <SchedulerOption
                        key={option.id}
                        label={
                          selectedOption.id === option.id &&
                          !!option.activeLabel
                            ? option.activeLabel
                            : option.label
                        }
                        description={option.description}
                        onClick={() => onOptionSelect(option)}
                        isActive={selectedOption.id === option.id}
                        iconClassName={option.optionIconClassName}
                      />
                    );
                  })}
                </OptionGroup>
              ))}
            </SchedulerOptions>
          )}
          {isSchedulerModalVisible && (
            <SchedulerModal
              post={post}
              account={account}
              pickedDate={pickedDate}
              setCustomDate={setCustomDate}
              onClose={() => setIsSchedulerModalVisible(false)}
            />
          )}
        </SchedulerWrapper>
      </OutsideClickDetector>
    </>
  );
};

export default Scheduler;
