import { useEffect } from "react";

export const useEventListener = (target, type, listener, ...options) => {
  useEffect(
    () => {
      // eslint-disable-next-line no-prototype-builtins
      const targetIsRef = target.hasOwnProperty("current");
      const currentTarget = targetIsRef ? target.current : target;

      if (currentTarget)
        currentTarget.addEventListener(type, listener, ...options);
      return () => {
        if (currentTarget)
          currentTarget.removeEventListener(type, listener, ...options);
      };
    },
    [target, type, listener, options]
  );
};