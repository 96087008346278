import { connect } from "react-redux";
import ReactLoading from "react-loading";
import { Flex } from "rebass/styled-components";
import React, { useState, useEffect } from "react";
import { useToaster } from "@hellocontento/maillard";

import {
  FullCheckbox,
  SettingsTitle,
  SettingsHeader,
  SettingsSection,
  FullCheckboxTitle,
  SettingsDescription,
  FullCheckboxContainer,
  FullCheckboxDescription
} from "./styles";
import config from "config";
import { emails } from "constants/settings";
import Switch from "components/common/form/Switch";
import IconButton from "components/common/IconButton";
import { useContentoApi } from "utils/useContentoApi";
import * as accountActions from "state/actions/AccountActions";

const NotificationSetting = ({
  onToggle,
  selected,
  emailName,
  emailDescription
}) => {
  return (
    <FullCheckboxContainer>
      <Flex flexDirection="column">
        <FullCheckboxTitle>{emailName}</FullCheckboxTitle>
        <FullCheckboxDescription mt={10}>
          {emailDescription}
        </FullCheckboxDescription>
      </Flex>
      <FullCheckbox>
        <Switch htmlFor={emailName} checked={selected} onToggle={onToggle} />
      </FullCheckbox>
    </FullCheckboxContainer>
  );
};

const Notifications = ({ account, currentUser }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [disabledEmailNotifications, setDisabledEmailNotifications] = useState(
    []
  );
  const [fetchUserAccount, cancelFetchUserAccount] = useContentoApi(
    `users/${currentUser.id}/accounts/${account.id}`
  );
  const [updateUserAccount] = useContentoApi(
    `users/${currentUser.id}/accounts/${account.id}`,
    "put"
  );
  const addToast = useToaster();

  useEffect(() => {
    fetchUserAccount()
      .then(userAccount => {
        if (userAccount && userAccount.disabledEmailNotifications) {
          setDisabledEmailNotifications(userAccount.disabledEmailNotifications);
        }
        setIsLoading(false);
      })
      .catch(error => {
        addToast(error.message, "error");
      });

    return cancelFetchUserAccount;
  }, [
    account,
    currentUser,
    fetchUserAccount,
    cancelFetchUserAccount,
    addToast
  ]);

  const toggleEmail = emailId => {
    let updatedDisabledEmails = [...disabledEmailNotifications];
    if (updatedDisabledEmails.includes(emailId)) {
      updatedDisabledEmails = updatedDisabledEmails.filter(
        id => id !== emailId
      );
    } else {
      updatedDisabledEmails = [...updatedDisabledEmails, emailId];
    }
    setDisabledEmailNotifications(updatedDisabledEmails);

    updateUserAccount({
      data: {
        disabledEmailNotifications: updatedDisabledEmails
      }
    })
      .then(userAccount => {
        if (userAccount && userAccount.disabledEmailNotifications) {
          setDisabledEmailNotifications(userAccount.disabledEmailNotifications);
        }
      })
      .catch(error => {
        addToast(error.message, "error");
      });
  };

  /// Using Webcal instead of plain https url breaks syncing of the calenders on our users calender apps
  const webcalLink = `${config.apiUrl}/users/${currentUser.id}/accounts/${account.id}/ics`;

  const copyLink = () => {
    navigator.clipboard.writeText(webcalLink);
    addToast("Copied url to clipboard.", "success");
  };

  const handleSubcribeCalendarClick = () => {
    window.location.href = webcalLink;
    copyLink();
  };

  return (
    <>
      <SettingsHeader>
        <SettingsTitle>Notifications</SettingsTitle>
        <SettingsDescription>
          Choose notification preferences and how you want to be contacted.
        </SettingsDescription>
      </SettingsHeader>

      <SettingsSection>
        {isLoading ? (
          <ReactLoading color="#bbb" type="cylon" />
        ) : (
          <>
            {emails.map(email => (
              <NotificationSetting
                key={email.id}
                emailName={email.name}
                emailDescription={email.description}
                selected={!disabledEmailNotifications.includes(email.id)}
                onToggle={() => toggleEmail(email.id)}
              />
            ))}
            <FullCheckboxContainer>
              <Flex flexDirection="column">
                <FullCheckboxTitle>Calendar Integration</FullCheckboxTitle>
                <FullCheckboxDescription className="full-width">
                  Copy this link & import as url into your favorite calendar app
                  to get Willow notifications in your calendar.
                </FullCheckboxDescription>
                <FullCheckboxDescription className="full-width link">
                  {webcalLink}
                </FullCheckboxDescription>
              </Flex>
              <FullCheckbox>
                <IconButton
                  size="sm"
                  iconSize={20}
                  variant="primaryMain"
                  icon="icon-autopilot"
                  onClick={handleSubcribeCalendarClick}
                />
              </FullCheckbox>
            </FullCheckboxContainer>
          </>
        )}
      </SettingsSection>
    </>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {
  updateAccount: accountActions.updateAccount
})(Notifications);
