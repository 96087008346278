import _ from "lodash";
import { useSelector } from "react-redux";
import React, { useState, useMemo } from "react";

import {
  ChannelGrid,
  AllChannelsToggle,
  ChannelSelectorContainer
} from "./styles";
import Channel from "./channel";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import Switch from "components/common/form/Switch";
import { Headline6 } from "components/common/styles";

const ChannelSelector: React.FC<{}> = () => {
  const accountChannels =
    useSelector<any, any>(state => state.account.data?.channels) ?? [];
  const postDataId = useComposerState(state => state.postData.id);
  const isDraft = useComposerState(state => state.postData.isDraft);
  const isPostGroup = useComposerState(state => state.postData.isPostGroup);
  const channels = useComposerState(state => state.postData.channels) ?? [];
  const inProMode = useComposerState(state => state.inProMode);
  const onChannelSelected = useComposerActions(
    actions => actions.onChannelSelected
  );
  const [allChannelsSelected, setAllChannelsSelected] = useState(false);

  const disabled = !!postDataId && !isDraft && !isPostGroup;

  const accountChannelsSorted = useMemo(() => {
    return _.sortBy(accountChannels, [
      a => {
        const serviceOrder = [
          "linkedin_business",
          "instagram",
          "facebook",
          "twitter",
          "linkedin_profile"
        ];
        return serviceOrder.findIndex(o =>
          `${a.service}_${a.serviceType}`.includes(o)
        );
      }
    ]);
  }, [accountChannels]);

  const handleSelectChannels = (selectedChannels: string[]) => {
    const validationErrors = {
      emptyChannels: selectedChannels.length === 0
    };
    onChannelSelected(selectedChannels, validationErrors);
  };

  const handleChannelToggle = (channelId: string) => {
    if (disabled) {
      return null;
    }

    const selectedChannels: string[] = [...channels];
    if (selectedChannels.includes(channelId)) {
      selectedChannels.splice(selectedChannels.indexOf(channelId), 1);
    } else {
      selectedChannels.push(channelId);
    }
    handleSelectChannels(selectedChannels);

    if (allChannelsSelected) {
      setAllChannelsSelected(false);
    }
  };

  const handleSelectAllChannelsToggle = () => {
    if (disabled) {
      return null;
    }

    const newState = !allChannelsSelected;

    let selectedChannels: string[];
    if (newState) {
      selectedChannels = accountChannelsSorted.map(channel => channel.id);
    } else {
      selectedChannels = [];
    }

    handleSelectChannels(selectedChannels);
    setAllChannelsSelected(newState);
  };

  return (
    <ChannelSelectorContainer>
      <AllChannelsToggle inProMode={inProMode}>
        <Switch
          htmlFor="all-channels-toggle"
          onToggle={handleSelectAllChannelsToggle}
          checked={allChannelsSelected}
        />
        <Headline6 className="label">Select all channels</Headline6>
      </AllChannelsToggle>
      <ChannelGrid>
        {accountChannelsSorted.map(
          channel =>
            !(disabled && !channels.includes(channel.id)) && (
              <Channel
                key={channel.id}
                channel={channel}
                handleChannelToggle={handleChannelToggle}
                checked={channels.includes(channel.id)}
              />
            )
        )}
      </ChannelGrid>
    </ChannelSelectorContainer>
  );
};

export default ChannelSelector;
