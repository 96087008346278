import { useSelector } from "react-redux";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import React, { useRef, useState, useEffect, useCallback } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";

import {
  TopSection,
  SideBarBody,
  Collections,
  AdjustableBar,
  SideBarWrapper,
  AdjustableHandle,
  AdjustableBarInfo,
  NavigationWrapper
} from "./styles";

import Panel from "./panel";
import Collapsable from "./collapsable";
import Divider from "components/common/Divider";
import { newsSideNav } from "constants/navigation";
import Loader from "components/common/loading/Loader";
import { ToolTip } from "components/schedule/common/styles";
import { fetchSections, reorderSection } from "services/news";
import SideNavigation from "components/navigation/SideNavigation";

import RSSIcon from "assets/images/contents/RSS.png";
import TopicIcon from "assets/images/contents/Topic.png";
import WebsiteIcon from "assets/images/contents/Website.png";
import TwitterIcon from "assets/images/contents/Twitter.png";
import KeywordIcon from "assets/images/contents/Keyword.png";
import RSSGrayIcon from "assets/images/contents/RSS-Gray.png";
import TopicGrayIcon from "assets/images/contents/Topic-Gray.png";
import WebsiteGrayIcon from "assets/images/contents/Website-Gray.png";
import TwitterGrayIcon from "assets/images/contents/Twitter-Gray.png";

interface ISideBarProps extends RouteComponentProps {}

const MIN_WIDTH = 192;

const SideBar: React.FC<ISideBarProps> = ({ match }) => {
  const history = useHistory();
  const sideBarRef = useRef<HTMLDivElement>(null);
  const storeSections = useSelector((state: any) => state.content.sections);
  const addToaster = useToaster();
  const account = useSelector((state: any) => state.account.data);

  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [sections, setSections] = useState<Array<any>>(storeSections);
  const [sidebarWidth, setSidebarWidth] = useState<number>(328);
  const [isResizing, setIsResizing] = useState<boolean>(false);
  const toggleIsCollapsed = () => setIsCollapsed(!isCollapsed);

  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);

  const navigateTo = (url: string) => {
    history.push(`/accounts/${account.id}/content/${url}`);
  };

  useEffect(() => {
    setSections(storeSections);
  }, [storeSections]);

  const handleFeedbackClick = () => {
    // @ts-ignore
    if (window.HubSpotConversations) {
      window.history.pushState(
        {},
        "hs_feature_feedback",
        "?hs_feature_feedback=true"
      );
      // @ts-ignore
      window.HubSpotConversations.widget.refresh({
        openToNewThread: true
      });
      // @ts-ignore
      window.HubSpotConversations.widget.open();
    }
  };

  const PANEL_DATA: any = {
    topics: {
      id: "topics",
      to: "topics",
      icon: "icon-tag",
      title: "All topics",
      label: "All topics",
      activeIcon: "icon-tag-filled",
      emptyState: {
        title: "No topics",
        infoIcon: [TopicGrayIcon],
        showSupport: false,
        message:
          "Follow curated topics around interests. Our AI selects all relevant content from trusted sources."
      },
      contextMenu: [
        {
          label: "Add topic",
          onClick: () => navigateTo("explore?type=topics"),
          image: [TopicIcon]
        },
        {
          label: "Request Topic",
          onClick: handleFeedbackClick,
          icon: "icon-chat"
        }
      ]
    },
    sources: {
      id: "sources",
      to: "influencers",
      title: "All sources",
      label: "All sources",
      icon: "icon-source",
      activeIcon: "icon-source",
      emptyState: {
        title: "No sources",
        infoIcon: [WebsiteGrayIcon, RSSGrayIcon, TwitterGrayIcon],
        showSupport: false,
        message:
          "Find trusted sources, add RSS Feeds or follow Twitter profiles."
      },
      contextMenu: [
        {
          label: "Add website",
          onClick: () => navigateTo("explore?type=domains"),
          image: WebsiteIcon
        },
        {
          label: "Add Twitter profile",
          onClick: () => navigateTo("explore?type=twitter-sources"),
          image: TwitterIcon
        },
        {
          label: "Add RSS feed",
          onClick: () => navigateTo("explore?type=rss-sources"),
          image: RSSIcon
        },
        {
          label: "Add Keyword monitor",
          onClick: () => navigateTo("keywords"),
          image: KeywordIcon
        },
        {
          label: "Explore all",
          onClick: () => navigateTo("explore?type=all"),
          icon: "icon-search"
        }
      ]
    }
  };

  useEffect(() => {
    const handleMouseMove = (e: any) => {
      e.preventDefault();
      if (!isResizing) return;

      if (sideBarRef.current) {
        sideBarRef.current.style.cursor = "col-resize";
      }

      if (e.clientX <= MIN_WIDTH) return;
      setSidebarWidth(e.clientX);
    };

    const handleMouseUp = () => {
      setIsResizing(false);

      if (sideBarRef.current) {
        sideBarRef.current.style.cursor = "default";
      }
    };

    if (sideBarRef.current) {
      sideBarRef.current.addEventListener("mousemove", handleMouseMove);
      sideBarRef.current.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      if (sideBarRef.current) {
        sideBarRef.current.removeEventListener("mousemove", handleMouseMove);
        sideBarRef.current.removeEventListener("mouseup", handleMouseUp);
      }
    };
  }, [isResizing]);

  const reorder = (
    list: any,
    startIndex: number,
    endIndex: number,
    type: string
  ) => {
    const section = list.find((item: any) => item.type === type).details;
    const [removed] = section.splice(startIndex, 1);
    section.splice(endIndex, 0, removed);
    return list;
  };

  const reorderPanelItems = (result: any) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }

    const type = destination.droppableId.toLowerCase();

    const orderedList = reorder(
      sections,
      source.index,
      destination.index,
      type
    );
    // reorder
    setSections(orderedList);

    // call api
    const details = orderedList.find((item: any) => item.type === type).details;

    const movedItemId = details[destination.index].id;
    const nextItemId = details[destination.index + 1]?.id;
    const sectionType =
      details[destination.index].type === "keywords" ? "keywords" : type;

    try {
      reorderSection(sectionType, movedItemId, nextItemId);
    } catch (err) {
      // move back the order
      const orderedList = reorder(
        sections,
        destination.index,
        source.index,
        type
      );
      setSections(orderedList);
      addToaster(err, "error");
    }
  };

  const fetchUserSections = useCallback(async () => {
    try {
      setIsFetching(true);
      const sections = await fetchSections();
      setSections(sections);
    } catch (_) {
      addToaster("Couldn't fetch your sources.", "error");
    } finally {
      setIsFetching(false);
    }
  }, [addToaster]);

  useEffect(() => {
    fetchUserSections();
  }, [fetchUserSections]);

  return (
    <SideBarWrapper>
      <SideBarBody
        ref={sideBarRef}
        width={sidebarWidth}
        isCollapsed={isCollapsed}
      >
        <TopSection>
          <NavigationWrapper>
            <SideNavigation
              strong
              px={8}
              py={6}
              match={match}
              links={newsSideNav}
              collapsed={isCollapsed}
            />
          </NavigationWrapper>
          {/* @ts-ignore */}
          <Divider my={-4} />
          {isFetching ? (
            <Loader location="center" size={32} />
          ) : (
            <Collections my={"6px"}>
              {sections.map((section: any, index: number) => (
                <Panel
                  key={index}
                  isCollapsed={isCollapsed}
                  match={match}
                  panel={PANEL_DATA[section.type]}
                  dataSet={section.details}
                  type={PANEL_DATA[section.type].id}
                  reorder={reorderPanelItems}
                />
              ))}
            </Collections>
          )}
        </TopSection>
        <Collapsable collapsed={isCollapsed} onClick={toggleIsCollapsed} />
      </SideBarBody>
      <AdjustableBar>
        <AdjustableHandle
          data-tip
          data-for={"adjustable-bar"}
          onMouseDown={() => setIsResizing(true)}
        />
        {!isResizing && (
          <ToolTip
            id={"adjustable-bar"}
            place="right"
            effect="float"
            className="tooltip"
            delayShow={200}
          >
            <AdjustableBarInfo>
              <strong>Drag</strong> to resize
            </AdjustableBarInfo>
          </ToolTip>
        )}
      </AdjustableBar>
    </SideBarWrapper>
  );
};

export default SideBar;
