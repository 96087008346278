import React from "react";
import styled from "styled-components";

const ScoreContainer = styled.div`
  height: 12px;
  margin: 6px 0;
  position: relative;
  width: 23px;
`;

const scoreColorMap = {
  0: "red",
  1: "yellow",
  2: "green",
  3: "green"
};

const ScoreBar = styled.span`
  background-color: ${props =>
    props.value
      ? props.theme.colors[scoreColorMap[Math.floor(props.value * 3)]]
      : props.theme.colors.grey03};
  border-radius: 4px;
  display: block;
  height: 100%;
  width: 100%;
`;

const ScoreGrid = styled.div`
  display: grid;
  grid-column-gap: 2px;
  grid-template-columns: repeat(5, 3px);
  height: 12px;
  overflow: hidden;
  position: absolute;
  width: ${props =>
    props.score !== null ? (Math.ceil(props.score * 5) * 23) / 5 : 0}px;
`;

export default function WidgetMetricScore({ score = 0.0 }) {
  return (
    <ScoreContainer>
      <ScoreGrid>
        {[0, 1, 2, 3, 4].map(i => (
          <ScoreBar key={`scorebar-b${i}`} />
        ))}
      </ScoreGrid>
      <ScoreGrid score={score}>
        {[0, 1, 2, 3, 4].map(i => (
          <ScoreBar key={`scorebar-v${i}`} value={Math.max(0.2, score)} />
        ))}
      </ScoreGrid>
    </ScoreContainer>
  );
}
