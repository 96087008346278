import React from "react";
import { connect } from "react-redux";

import {
  SettingsTitle,
  SettingsHeader,
  SettingsSection,
  SettingsDescription
} from "./styles";
import { connectChannel } from "utils/channels";
import { NativeLink } from "components/common/Link";
import { EnclosedContent } from "components/common/styles";
import ConnectSocialProfiles from "components/channelConnect/ChannelConnectionList";

function SocialMediaChannels({ account, currentUser }) {
  const handleConnectChannel = service => {
    connectChannel(account, service);
  };

  return (
    <EnclosedContent>
      <SettingsHeader>
        <SettingsTitle>Social Media channels</SettingsTitle>
        <SettingsDescription>
          Looking for step-by-step instructions on how to connect your social
          media channels?
          <br />
          Visit our{" "}
          <NativeLink
            underline
            href="https://support.willow.co/knowledge"
            target="_blank"
            rel="noopener noreferrer"
          >
            <strong>Help Center</strong>
          </NativeLink>{" "}
          or talk to us through the chat.
        </SettingsDescription>
      </SettingsHeader>
      <SettingsSection>
        <ConnectSocialProfiles
          account={account}
          currentUser={currentUser}
          onConnect={handleConnectChannel}
        />
      </SettingsSection>
    </EnclosedContent>
  );
}

const mapStateToProps = (state, props) => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {})(SocialMediaChannels);
