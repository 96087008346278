import React from "react";

import { useComposerActions } from "contextApi/composerContext";
import { TooltipOption, TooltipMenu, TooltipMenuWrapper } from "../styles";

const NewActivityOptions = ({ date, close, float = false }) => {
  const createTask = useComposerActions(actions => actions.createTask);
  const createPostByDate = useComposerActions(
    actions => actions.createPostByDate
  );

  return (
    <TooltipMenu onClick={close} float={float} radius={16}>
      <TooltipMenuWrapper minWidth={180}>
        <TooltipOption
          onClick={() => {
            createPostByDate(date);
          }}
          variant="primary"
        >
          <span className="icon-write"></span>
          Write a post
        </TooltipOption>
        <TooltipOption
          onClick={() => {
            createPostByDate(date, true);
          }}
        >
          <span className="icon-draft"></span>
          Write draft
        </TooltipOption>
        <TooltipOption
          onClick={() => {
            createTask(date);
          }}
        >
          <span className="icon-task-circular" />
          Add task
        </TooltipOption>
      </TooltipMenuWrapper>
    </TooltipMenu>
  );
};

export default NewActivityOptions;
