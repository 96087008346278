import _ from "lodash";
import { connect } from "react-redux";
import React, { useState, useCallback } from "react";
import { withRouter, useHistory } from "react-router-dom";

import { TimeDisplay } from "./styles";
import { ComposerModal } from "./ComposerModal";
import { formatForScheduler } from "utils/date";
import { useContentoApi } from "utils/useContentoApi";
import { useQueryParams } from "utils/useQueryParams";
import * as draftActions from "state/actions/DraftActions";
import PostComposer from "components/composer/PostComposer";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import * as activityActions from "state/actions/ActivityActions";
import { openModal, closeModal } from "state/actions/ModalActions";
import { Status } from "components/composer/statusSelector/constants";

const CreateComposerModal = ({
  location,
  accountId,
  onboardingProgress,
  saveActivities,
  activityParams,
  openModal,
  closeModal,
  changeDraftCountByValue,
  removeDraftById,
  saveDraftCount,
  saveDrafts,
  ...props
}) => {
  const history = useHistory();
  const queryParams = useQueryParams();
  const [postStatus, setPostStatus] = useState(
    props.post?.isDraft || props.isDraft ? Status.DRAFT : Status.APPROVED
  );

  const [confirmationDialogVisible, setConfirmationDialogVisible] = useState(
    false
  );

  const [fetchActivities] = useContentoApi(
    `/accounts/${accountId}/activities/calendar`
  );
  const [fetchDrafts] = useContentoApi(`/accounts/${accountId}/draft-posts`);
  const [fetchDraftCount] = useContentoApi(
    `/accounts/${accountId}/draft-posts?count=true`
  );

  const reloadActivities = () => {
    fetchActivities({ params: activityParams }).then(activities => {
      saveActivities({
        data: activities,
        params: activityParams
      });
    });
  };

  const updateDraftCount = useCallback(() => {
    fetchDraftCount()
      .then(response => {
        saveDraftCount(response);
      })
      .catch(_ => {});
  }, [fetchDraftCount, saveDraftCount]);

  const updateDrafts = useCallback(() => {
    fetchDrafts()
      .then(response => {
        const drafts = response.map(draft => {
          return {
            ...draft,
            isDraft: true
          };
        });
        saveDrafts(drafts);
      })
      .catch(_ => {});
  }, [fetchDrafts, saveDrafts]);

  const isSchedulePage = location.pathname.includes("schedule");

  const closeComposerModal = () => {
    const search = _.omit(queryParams, ["postGroupId", "postId", "draftId"]);
    const newQueryParams = new URLSearchParams();
    _.forEach(search, (value, key) => {
      newQueryParams.set(key, value);
    });
    if (!_.isEqual(queryParams, Object.fromEntries(newQueryParams.entries()))) {
      history.push({
        search: newQueryParams.toString()
      });
    }
    closeModal("COMPOSER_MODAL");
  };

  const handlePosted = res => {
    closeComposerModal();
    // if posted content is draft update the draft count and list
    if (res.isDraft) {
      try {
        updateDraftCount();
        updateDrafts();

        // when in scheduling page, fetch new sets of activities
      } catch (_) {}
    }
    if (!props.skipAutoReload && isSchedulePage) {
      reloadActivities();
    }
    if (props.onPosted) {
      props.onPosted(res.posts || res.post || res);
    }

    const post = (res.posts ? res.posts[0] : undefined) || res.post || res;
    try {
      if (!res.isDraft) {
        openModal("ONBOARDING_INFO_MODAL", {
          id: OnboardingStepsEnum.CREATE_POST,
          variant: "success",
          title: res.isPostedNow
            ? "Your post has been published."
            : "Your post has been scheduled.",
          subTitle: !res.isPostedNow ? (
            <>
              Your post has been scheduled for <br />
              <TimeDisplay>
                {formatForScheduler(new Date(post.scheduledAt.split(".")[0]))}
              </TimeDisplay>
            </>
          ) : (
            "Your post has been sent and will appear on your social media."
          ),
          forOnboarding: !onboardingProgress.includes(
            OnboardingStepsEnum.CREATE_POST
          ),
          ...(!onboardingProgress.includes(OnboardingStepsEnum.CREATE_POST)
            ? { triggeredBy: OnboardingStepsEnum.CREATE_POST }
            : { secondaryAction: false })
        });
      }
    } catch (_) {}
  };

  const handleDeleted = post => {
    closeComposerModal();
    if (post.isDraft) {
      try {
        changeDraftCountByValue(-1);
        removeDraftById(post.id);

        // when in scheduling page, fetch new sets of activities
      } catch (_) {}
    }
    if (!props.skipAutoReload && isSchedulePage) {
      reloadActivities();
    }
    if (props.onDeleted) {
      props.onDeleted(post);
    }
  };

  const postType = postStatus === Status.DRAFT ? "Draft" : "Post";

  return (
    <ComposerModal
      title={
        props.title
          ? props.title
          : props.post
          ? `Edit ${postType}`
          : `New ${postType}`
      }
      onClose={closeComposerModal}
      postStatus={postStatus}
      setPostStatus={setPostStatus}
      isDraft={!!props.post?.isDraft}
      setConfirmationDialogVisible={setConfirmationDialogVisible}
    >
      <PostComposer
        {...props}
        close={closeComposerModal}
        onPosted={handlePosted}
        onDeleted={handleDeleted}
        postStatus={postStatus}
        setPostStatus={setPostStatus}
        confirmationDialogVisible={confirmationDialogVisible}
        setConfirmationDialogVisible={setConfirmationDialogVisible}
      />
    </ComposerModal>
  );
};

const mapStateToProps = state => ({
  accountId: state.account.data.id,
  onboardingProgress: state.account.data.onboardingProgress,
  activityParams: state.activity.params
});

export default withRouter(
  connect(mapStateToProps, {
    closeModal: closeModal,
    openModal: openModal,
    saveActivities: activityActions.saveActivities,
    changeDraftCountByValue: draftActions.changeDraftCountByValue,
    removeDraftById: draftActions.removeDraftById,
    saveDraftCount: draftActions.saveDraftCount,
    saveDrafts: draftActions.saveDrafts
  })(CreateComposerModal)
);
