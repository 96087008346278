import decode from "jwt-decode";

import { callApi } from "./ContentoApi";

import * as storageService from "utils/storage";
import { KEY_LAST_CALENDAR_VIEW } from "constants/calendar";

const ACCESS_TOKEN_KEY = "session_token";

function login({ authType, ...credentials }) {
  const path = authType || "login"; // google

  return callApi(
    {
      url: `auth/${path}`,
      method: "post",
      data: credentials // email & jwt
    },
    false
  )
    .then(({ accessToken }) => {
      setAccessToken(accessToken);
      return accessToken;
    })
    .catch(error => {
      throw error;
    });
}

function getUserInfo() {
  const userId = getUserIdFromToken();

  return callApi(
    {
      url: `users/${userId}`
    },
    false
  )
    .then(user => {
      return user;
    })
    .catch(err => {
      throw err;
    });
}

function getUserIdFromToken() {
  const token = getAccessToken();
  if (!token) {
    throw new Error("No token");
  }

  const tokenData = decode(token);

  if (!tokenData.userId) {
    throw new Error("Invalid token");
  }
  return tokenData.userId;
}

function updateUserInfo(values) {
  const userId = getUserIdFromToken();

  return callApi(
    {
      url: `users/${userId}`,
      method: "put",
      data: values
    },
    false
  )
    .then(user => {
      return user;
    })
    .catch(err => {
      throw err;
    });
}

function logout() {
  return new Promise(resolve => {
    cancelRenewal();
    clearAccessToken();
    clearValueForLastCalendarView();
    resolve();
  });
}

function nonceLogin(nonce) {
  return callApi(
    {
      url: `auth/nonce/${nonce}`,
      method: "get"
    },
    false
  )
    .then(({ accessToken }) => {
      setAccessToken(accessToken);
      return accessToken;
    })
    .catch(error => {
      throw error;
    });
}

function hasValidToken() {
  const accessToken = getAccessToken();
  return !!accessToken && !isTokenExpired(accessToken);
}

function startOnboarding(accountData) {
  return callApi({
    url: "signup",
    method: "post",
    data: accountData
  })
    .then(({ userData }) => {
      return userData;
    })
    .catch(error => {
      throw error;
    });
}

function renewToken() {
  return callApi(
    {
      url: "auth/refresh",
      method: "post"
    },
    false
  )
    .then(({ accessToken }) => {
      setAccessToken(accessToken);
    })
    .catch(error => {
      console.error(error);
    });
}

function resetPassword(email) {
  return callApi({
    url: "auth/password-reset",
    method: "post",
    data: { email }
  })
    .then(({ success }) => {
      return success;
    })
    .catch(error => {
      throw error;
    });
}

// Get and store access_token in local storage
function setAccessToken(accessToken) {
  storageService.set(ACCESS_TOKEN_KEY, accessToken);
  scheduleRenewal();
}

function getAccessToken() {
  return storageService.get(ACCESS_TOKEN_KEY);
}

function clearAccessToken() {
  storageService.remove(ACCESS_TOKEN_KEY);
  cancelRenewal();
}

function clearValueForLastCalendarView() {
  storageService.remove(KEY_LAST_CALENDAR_VIEW);
}

let tokenRenewalTimeout;

function scheduleRenewal() {
  cancelRenewal();
  const expiresAt = getTokenExpirationDate(getAccessToken());
  //Renew in an hour or before if it's close to expiring.
  const delay = Math.min(
    expiresAt.getTime() - Date.now() - 30 * 1000,
    20 * 60 * 1000
  );
  if (delay > 0) {
    tokenRenewalTimeout = setTimeout(renewToken, delay);
  }
}

function cancelRenewal() {
  clearTimeout(tokenRenewalTimeout);
}

function isTokenExpired(token) {
  const expirationDate = getTokenExpirationDate(token);
  return expirationDate && expirationDate < new Date();
}

function getTokenExpirationDate(accessToken) {
  try {
    const token = decode(accessToken);
    if (!token.exp) {
      return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(token.exp);
    return date;
  } catch (err) {
    console.error(err);
    return null;
  }
}

export default {
  login,
  renewToken,
  hasValidToken,
  getUserInfo,
  updateUserInfo,
  getAccessToken,
  setAccessToken,
  logout,
  startOnboarding,
  nonceLogin,
  resetPassword
};
