import styled, { css } from "styled-components";
import { Flex, Text, Image } from "rebass/styled-components";
import { Headline1, Brick } from "components/common/styles";
import { colors } from "theme";

// TODO: Rewrite with shared components once a proper
// design system has been built

// Settings

export const SettingsHeader = styled.header`
  margin-bottom: 40px;

  p {
    color: ${colors.text03};
  }

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    margin-bottom: 32px;
  }
`;

export const SettingsTitle = styled(Headline1)`
  /* Will extend common/typography/title */
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const SettingsIcon = styled.img`
  height: ${props => props.size ?? 56}px;
  margin-bottom: 16px;
  height: ${props => props.size ?? 56}px;
`;

export const SettingsDescription = styled.p`
  font-size: ${props => (props.size === "lg" ? "22px" : "14px")};
  line-height: ${props => (props.size === "lg" ? "28.6px" : "24px")};
  margin: 8px 0;
  max-width: ${props => (props.size === "sm" ? "480px" : "none")};

  & strong {
    font-weight: 700;
  }
`;

export const TrailDescription = styled.div`
  font-size: ${props => (props.size === "lg" ? "22px" : "16px")};
  line-height: ${props => (props.size === "lg" ? "28.6px" : "24px")};
  margin: 12px 0;
  max-width: ${props => (props.size === "sm" ? "480px" : "none")};
  font-weight: 700;
  color: ${props => props.theme.colors.text01};

  & strong {
    color: ${props => props.theme.colors.status.red};
  }
`;
export const SettingsSection = styled.div`
  /* Will extend common/Section */
  margin-bottom: 64px;

  &:last-child {
    margin-bottom: 16px;
  }

  p {
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -1%;
    color: ${props => props.theme.colors.text01};
  }

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    margin-bottom: 32px;
  }
`;

export const SettingsIconContainer = styled(Flex)`
  display: flex;
  flex-direction: row;
  gap: 38px;
`;

export const IconInfoContainer = styled.div`
  flex: 1;

  h3 {
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 700;
  }
  p {
    color: ${colors.text03};
  }
`;

export const SettingsSubtitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  span {
    font-weight: 600;
  }
`;

export const SettingsSubtitle = styled.h3`
  /* Will extend common/typography/subtitle */
  color: ${props => props.theme.colors.text01};
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 0.18px;
  margin: 16px 0;
  text-transform: capitalize;
  display: flex;
  justify-content: ${props =>
    props.justifyContent ? props.justifyContent : "space-between"};
  align-items: center;

  button {
    span {
      margin-right: 8px;
    }
  }
`;

export const AccountCount = styled.span`
  color: ${props => props.theme.colors.text03};
  margin-left: 4px;
`;

export const SettingsSubtitleWrapper = styled.div`
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop};
    `}
`;

export const SettingsBrick = styled(Brick)`
  border-radius: 16px;
  border: 1px solid rgba(102, 119, 128, 0.16);
  box-shadow: ${props => props.theme.shadows.shadowS};
  padding: 32px 32px ${props => (props.pb ? props.pb + "px" : "32px")};
`;

export const SettingsWell = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 16px;
  border: 1px solid ${props => props.theme.colors.grey02};
  flex-grow: 1;
  margin: 0 0 16px 0;
  padding: 24px 24px;
  box-sizing: border-box;
`;

export const SettingsForm = styled.form``;

export const SettingsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 16px;
  margin-bottom: 10px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.sm}) {
    display: block;
  }
`;

export const FullCheckboxContainer = styled(Flex)`
  border-bottom: 1px solid ${props => props.theme.colors.grey02};
  flex-direction: row;
  justify-content: space-between;
  margin-top: 24px;
  padding-bottom: 24px;
`;

export const FullCheckboxTitle = styled.p`
  color: ${props => props.theme.colors.text01};
  font-size: 16px;
  font-weight: bold;
  letter-spacing: -0.16px;
  margin: 2px 0;
  max-width: 480px;
`;

export const FullCheckboxDescription = styled.p`
  font-size: 14px;
  line-height: 24px;
  margin: 2px 0;
  max-width: 480px;
  color: ${props => props.theme.colors.text02};

  &.full-width {
    max-width: unset;
    margin: 2px 24px 2px 0;
  }

  &.link {
    font-size: 12px;
    text-decoration: underline;
    color: ${props => props.theme.colors.text04};
  }
`;

export const StyledForm = styled.form``;

export const AutoPilotSection = styled(Flex)`
  box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.108),
    0 1.6px 3.6px rgba(0, 0, 0, 0.132);
  border-radius: 8px;
  background: #f7f9fa;
  padding: 32px;
  align-items: center;
  margin-bottom: 32px;

  & > div:first-child {
    flex-grow: 1;
  }
`;

export const AutoPilotTitle = styled.h3`
  color: #494949;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 7px;
`;

export const SwitchState = styled.span`
  display: inline-block;
  color: #888;
  font-size: 14px;
  margin-right: 8px;
  position: relative;
  top: -2px;

  &.on {
    color: ${props => props.theme.colors.blue};
  }
`;

export const CTAPreviewWrapper = styled(Flex)`
  display: ${props => (props.isEnabled ? "flex" : "none")};
  justify-content: center;
  position: fixed;
  bottom: 16px;
  right: 16px;

  @media screen and (max-width: ${props => props.theme.stdBreakpoints.m}) {
    position: relative;
    bottom: auto;
    right: auto;
  }
`;

export const CTASettingsWrapper = styled.div`
  ${props =>
    !props.isEnabled &&
    css`
      pointer-events: none;
      opacity: 0.33;
    `}
`;

export const InvoiceHeader = styled(Flex)`
  min-height: 48px;
`;

export const InvoiceLine = styled(Flex)`
  align-items: center;
  border-top: 1px solid ${props => props.theme.colors.grey01};
  padding: 24px 0;

  & > :first-child {
    color: ${props => props.theme.colors.text01};
    font-weight: 700;
    text-indent: 4px;
  }

  &:last-of-type {
    border-bottom: 1px solid ${props => props.theme.colors.grey01};
  }
`;

export const PlanCardContainer = styled(Flex)`
  background: #ffffff;
  box-shadow: 0 0.3px 0.9px rgba(0, 0, 0, 0.108),
    0 1.6px 3.6px rgba(0, 0, 0, 0.132);
  border-radius: 8px;
`;

export const PlanImage = styled(Flex)`
  background-color: #ed73b3;
  border-radius: 8px 0 0 8px;
  width: 112px;
  height: 112px;
`;

export const PlanName = styled(Text)`
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #494949;
`;

export const PlanDescription = styled(Text)`
  font-size: 14px;
  line-height: 20px;
  color: #646464;
`;

export const TextInput = styled.input`
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  width: 100%;
  padding: 6px 12px;
  color: ${props => props.theme.colors.black};
  font-size: 14px;
  line-height: 20px;
  &:focus {
    border: #0061ff 1px solid;
    outline: none;
  }
  &::placeholder {
    color: #a8acb9;
  }
  @media screen and (max-width: ${props => props.theme.breakpoints[3]}) {
    width: 100%;
    box-sizing: border-box;
  }
`;

export const InvoiceHeading = styled.div`
  font-weight: 700;
  font-size: 14px;
  color: ${props => props.theme.colors.text02};
`;

export const InvoiceDescription = styled.div`
  font-weight: 400;
  margin: 2px 0;
  font-size: 14px;
  color: ${props =>
    props.subTitle ? props.theme.colors.text03 : props.theme.colors.text01};
`;

export const InvoicePrice = styled.div`
  font-weight: 600;
  color: ${props =>
    props.variant === "danger"
      ? props.theme.colors.status.red
      : props.theme.colors.text01};
`;

export const DownloadButton = styled.button`
  color: ${props => props.theme.colors.blue};
  text-align: left;
  background: none;
  margin: 2px 0;
  padding: 0;
  border: none;
  cursor: pointer;
  font-weight: 700;
  border-bottom: 1px solid transparent;

  &:hover {
    color: ${props => props.theme.colors.linkBlueHover};
    border-bottom: 1px solid ${props => props.theme.colors.linkBlueHover};
  }
  &:active {
    color: ${props => props.theme.colors.linkBlueActive};
    border-bottom: 1px solid ${props => props.theme.colors.linkBlueActive};
  }
`;

export const InvoiceStatus = styled.div`
  margin: 2px 0;
  text-transform: capitalize;

  ${props =>
    props.variant === "danger" &&
    css`
      color: ${props.theme.colors.status.red};
    `}
`;

export const SettingsWellEmpty = styled(Flex)`
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props =>
    !props.noBgStyle &&
    css`
      background: ${props => props.theme.colors.grey01};
      border-radius: 24px;
      box-shadow: 0 0 0 1px ${props => props.theme.colors.grey02};
      margin: 0 0 16px 0;
      padding: 106px 24px;
    `}


  ${props =>
    props.px &&
    css`
      padding-left: ${props.px}px;
      padding-right: ${props.px}px;
    `}

  ${props =>
    props.py &&
    css`
      padding-top: ${props.py}px;
      padding-bottom: ${props.py}px;
    `}
`;

export const EmptyMessageWrapper = styled.div`
  width: ${props => (props.width ? `${props.width}%` : "50%")};
  margin: auto;
  text-align: center;

  ${props =>
    props.center &&
    css`
      width: 80%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `}

  p {
    margin-bottom: 4px;
  }
`;

export const EmptyInfoIconWrapper = styled(Flex)`
  flex-direction: row;
  gap: 4px;
`;

export const EmptyInfoIcon = styled(Image)`
  width: ${props => props.size ?? 32}px;
  height: ${props => props.size ?? 32}px;
  border-radius: 8px;
  margin-bottom: 16px;
`;

export const EmptyMessageTitle = styled.p`
  color: ${props => props.theme.colors.text02};
  font-weight: 600;
`;

export const EmptyMessage = styled.p`
  color: ${props => props.theme.colors.text03};
`;

export const ButtonIcon = styled.span`
  font-size: 20px;
`;

export const FullCheckbox = styled.div`
  margin-left: auto;
  padding-left: 24px;
`;

export const ChromeExtensionAuthCodeContainer = styled(Flex)`
  max-width: fit-content;
  gap: 25px;
  padding: 25px;
  align-items: center;
  border-radius: 5px;
  border: 1px solid #60748014;
  background-color: #F7F9FA;
  box-shadow: 0px 4px 24px 0px #1C1E1F14;
  cursor: pointer;
`
export const ChromeExtensionAuthScreenshot = styled(Image)`
  width: 311px;
  height: 159px;
  border-radius: 5px;
  box-shadow: 0px 0px 0px 1px #1C1E1F0D;
`;