import React from "react";

import { Button, ButtonToggle } from "components/schedule/styles";

const TWITTER_PHOTO_LIMIT = 4;
const LINKEDIN_PHOTO_LIMIT = 9;
const TWITTER_VIDEO_DURATION_LIMIT = 140; //Seconds
const INSTAGRAM_VIDEO_DURATION_LIMIT = 90; //Seconds

export default function validateAttachments(
  account,
  attachment,
  setAttachmentOptions,
  enabledServices,
  isDraft = false
) {
  const {
    type: attachmentType,
    options: attachmentOptions,
    photoAttachments,
    videoAttachments,
    articleAttachments
  } = attachment;
  const photoCount = photoAttachments.length;
  const videoCount = videoAttachments.length;

  const hasVideo = attachmentType === "video" && videoCount > 0;
  const hasPhoto = attachmentType === "photo" && photoCount > 0;
  const notifications = [];

  const InstagramVideoTypeSelector = () => {
    return (
      <ButtonToggle maxOptions={2} minWidth={64}>
        <Button
          size="xs"
          isActive={!attachmentOptions.isReel}
          onClick={() => setAttachmentOptions({ isReel: false })}
        >
          Video
        </Button>
        <Button
          size="xs"
          isActive={attachmentOptions.isReel}
          onClick={() => setAttachmentOptions({ isReel: true })}
        >
          Reel
        </Button>
      </ButtonToggle>
    );
  };

  if (
    attachmentType === "article" &&
    attachment &&
    !!articleAttachments &&
    !articleAttachments.embeddable &&
    (account.features ?? []).includes("cta")
  ) {
    notifications.push({
      type: "info",
      message:
        "It won't be possible to show your call-to-action popup on this site"
    });
  }

  if (
    enabledServices.includes("twitter") &&
    hasPhoto &&
    photoCount > TWITTER_PHOTO_LIMIT
  ) {
    notifications.push({
      type: "warning",
      service: "twitter",
      message: `Only the first ${TWITTER_PHOTO_LIMIT} photos will be shared on Twitter`
    });
  }

  if (
    enabledServices.includes("twitter") &&
    hasVideo &&
    videoAttachments[0]?.metaData?.duration > TWITTER_VIDEO_DURATION_LIMIT
  ) {
    notifications.push({
      type: "error",
      service: "twitter",
      message:
        "Your video is too long for Twitter. The limit is 2 mins 20 sec. Deselect Twitter or upload another video."
    });
  }

  if (
    enabledServices.includes("instagram") &&
    hasPhoto === false &&
    hasVideo === false &&
    !isDraft
  ) {
    notifications.push({
      type: "error",
      service: "instagram",
      message:
        "Please make sure to upload a photo or video when posting to Instagram."
    });
  }

  if (enabledServices.includes("instagram") && hasPhoto === true) {
    photoAttachments
      .map(att => att.metaData)
      .forEach(metadata => {
        // metadata of existing post could be null
        if (metadata) {
          //check image aspect ratio
          const imageAspectRatio = metadata.width / metadata.height;

          if (imageAspectRatio < 0.8 || imageAspectRatio > 1.91) {
            notifications.push({
              type: "error",
              service: "instagram",
              message:
                "The image aspect ratio for instagram should be in the 4:5 to 1.91:1 range"
            });
          }
        }
      });
  }

  if (
    enabledServices.includes("instagram") &&
    hasVideo &&
    (videoAttachments[0]?.duration || videoAttachments[0]?.metaData?.duration) >
      INSTAGRAM_VIDEO_DURATION_LIMIT
  ) {
    notifications.push({
      type: "error",
      service: "instagram",
      message:
        "Sorry, Instagram only accepts videos with a duration of up to 90 seconds. Please deselect all Instagram channels below or upload a different video."
    });
  }

  if (
    enabledServices.includes("linkedin") &&
    hasPhoto &&
    photoCount.length > LINKEDIN_PHOTO_LIMIT
  ) {
    notifications.push({
      type: "warning",
      service: "linkedin",
      message: `Only the first ${LINKEDIN_PHOTO_LIMIT} photos will be shared on LinkedIn`
    });
  }

  // Commenting this out till we figure out the best way to resolve the instagram video conundrum
  // if (enabledServices.includes("instagram") && hasVideo) {
  //   notifications.push({
  //     type: "generic",
  //     service: "instagram",
  //     message: `How should it be posted to instagram?`,
  //     actionComponent: <InstagramVideoTypeSelector />
  //   });
  // }

    if (enabledServices.includes("instagram") && hasVideo) {
      notifications.push({
        type: "info",
        service: "instagram",
        message: `Video will be posted to Instagram as a reel`
      });
    }

    return notifications;
  }
