import React from "react";
import styled from "styled-components";
import { Box } from "rebass";
import { Check } from "styled-icons/entypo";

const CheckboxWrapper = styled(Box)`
  height: 20px;
  padding: 1px;
  width: 20px;
`;

const CheckboxInput = styled.input`
  position: absolute;
  visibility: hidden;
  z-index: -1;
`;

CheckboxInput.defaultProps = {
  type: "checkbox"
};

const CheckboxTrigger = styled.button`
  appearance: none;
  background: ${props =>
    props.checked ? props.theme.colors.blue : "transparent"};
  border: none;
  border-radius: 5px;
  box-shadow: ${props => {
    if (props.checked) {
      return `none`;
    } else if (props.hasError) {
      return `inset 0 0 0 1.4px ${props.theme.colors.red}`;
    } else {
      return `inset 0 0 0 1.4px ${props.theme.colors.text04}`;
    }
  }}}
  color: white;
  cursor: pointer;
  padding: 0;
  top: 0;
  height: 18px;
  width: 18px;

  &:hover,
  &:focus {
    background: ${props =>
      props.checked ? props.theme.colors.darkBlue : "white"};
    box-shadow: ${props =>
      props.checked ? "none" : `inset 0 0 0 1.4px ${props.theme.colors.blue}`};
  }
`;

const CheckboxIcon = styled(Check)`
  visibility: ${props => (props.checked ? "visible" : "hidden")};
  transform: scale(0.84) rotate(10deg);
`;

export default function Checkbox({
  name,
  checked,
  onChange,
  hasError = false
}) {
  return (
    <CheckboxWrapper>
      <CheckboxInput
        name={name}
        checked={checked}
        onChange={e => {
          e.preventDefault();
          onChange();
        }}
      />
      <CheckboxTrigger
        checked={checked}
        hasError={hasError}
        onClick={e => {
          e.preventDefault();
          onChange(!checked);
        }}
      >
        <CheckboxIcon checked={checked} size={18} />
      </CheckboxTrigger>
    </CheckboxWrapper>
  );
}
