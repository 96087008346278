import { omit, debounce } from "lodash";
import { useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { X } from "@styled-icons/boxicons-regular";
import { useToaster } from "@hellocontento/maillard";
import { Flex, Box } from "rebass/styled-components";
import React, { useState, useEffect, useCallback } from "react";

import {
  ScheduleFieldset,
  SchedulePanelFixer,
  SchedulePanelHeader,
  SchedulePanelFooter,
  SchedulePanelContainer
} from "../styles";
import {
  Headline5,
  Headline6,
  PropertyField,
  PropertyTable
} from "components/common/styles";
import {
  fetchTaskById,
  createTaskGroup,
  updateTaskGroup,
  fetchTaskGroupById,
  createTaskInstance,
  updateTaskInstance
} from "../services/taskServices";
import Schedule from "./Schedule";
import {
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import TimeSelect from "./TimeSelect";
import { capitalize } from "utils/string";
import { callApi } from "utils/ContentoApi";
import Button from "components/common/Button";
import { difference, today } from "utils/date";
import IconButton from "components/common/IconButton";
import Loader from "components/common/loading/Loader";
import { useQueryParams } from "utils/useQueryParams";
import * as modalActions from "state/actions/ModalActions";
import ChannelToggle from "components/common/ChannelToggle";
import ScheduleTitleInput from "../form/ScheduleTitleInput";
import { getDefaultTaskStartDate } from "../utils/taskUtils";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import TaskDescriptionInput from "../form/TaskDescriptionInput";
import * as activityActions from "state/actions/ActivityActions";
import EmptyChannelState from "components/common/EmptyChannelState";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import { removeTimezoneOffsetFromDateAsISO } from "../utils/dateUtils";
import ContentTypeSelect from "components/common/contentTypes/ContentTypeSelect";

const TaskPanel = ({
  account,
  onClose,
  openModal,
  userChannels,
  addPreviews,
  removePreviews,
  initData = {}
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const addToast = useToaster();
  const queryParams = useQueryParams();
  const onReloadEvent = useComposerState(state => state.events.onReloadEvent);
  const deleteEntry = useComposerActions(actions => actions.deleteEntry);
  const isEditMode =
    (queryParams.taskId && queryParams.taskId !== "new") ||
    queryParams.taskGroupId;

  const isEditModeSeries = !!queryParams.taskGroupId;

  //task properties keep the state of all settings, it is turned into a task-group or task when saving
  const [taskProperties, setTaskProperties] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const updateTaskProperties = property => {
    setTaskProperties(prop => {
      return {
        ...prop,
        ...property
      };
    });
  };

  const fetchTask = useCallback(
    async taskId => {
      setIsLoading(true);
      const data = await fetchTaskById(account.id, taskId);
      setIsLoading(false);

      const {
        id,
        taskGroupId,
        date,
        title,
        description,
        channels,
        contentTypeId
      } = data;

      return {
        taskId: id,
        taskGroupId: taskGroupId, //can be null when the task instance is not associated
        title,
        description,
        contentTypeId,
        channels: channels.map(c => c.id),
        date: new Date(date).toISOString()
      };
    },
    [account.id]
  );

  const fetchTaskGroup = useCallback(
    async taskGroupId => {
      setIsLoading(true);
      const data = await fetchTaskGroupById(account.id, taskGroupId);
      setIsLoading(false);

      const {
        id,
        dateStart,
        dateEnd,
        title,
        description,
        channels,
        settings,
        contentTypeId
      } = data;

      return {
        taskGroupId: id,
        title,
        description,
        contentTypeId,
        channels: channels.map(c => c.id),
        dateStart: new Date(dateStart).toISOString(),
        dateEnd: dateEnd ? new Date(dateEnd).toISOString() : null,
        settings: settings
      };
    },
    [account.id]
  );

  useEffect(() => {
    let fetchPromise = null;

    if (queryParams.taskId === "new" || queryParams.date) {
      //initiate default task we don't do at useState because it could cause flicker
      setTaskProperties({
        title: queryParams.title || initData.title || "",
        description: initData.description || "",
        contentTypeId:
          queryParams.contentTypeId || initData.contentTypeId || "educational",
        channels: account.channels.map(c => c.id),
        channelTypes: [
          ...new Set(account.channels.map(c => capitalize(c.service)))
        ],
        date: queryParams.date || getDefaultTaskStartDate(),
        settings: null,
        postIdea: location.idea,
        metaData: initData.metaData || {}
      });
    } else if (queryParams.taskId) {
      //load task
      fetchPromise = fetchTask(queryParams.taskId);
    } else if (queryParams.taskGroupId) {
      //load task group
      fetchPromise = fetchTaskGroup(queryParams.taskGroupId);
    }

    if (fetchPromise) {
      fetchPromise.then(setTaskProperties).catch(err => {
        addToast("There was an error fetching the details", "error");
        onClose();
      });
    }
  }, [
    queryParams.taskId,
    queryParams.taskGroupId,
    queryParams.date,
    queryParams.contentTypeId,
    queryParams.title,
    location.idea,
    account.channels,
    fetchTask,
    addToast,
    fetchTaskGroup,
    onClose
  ]);

  const debouncePreview = useCallback(debounce(fetchPreview, 500), []);

  useEffect(() => {
    debouncePreview(taskProperties);

    return () => {
      removePreviews();
    };
  }, [
    debouncePreview,
    account.id,
    addPreviews,
    isEditMode,
    removePreviews,
    taskProperties,
    userChannels
  ]);

  function fetchPreview(taskProperties) {
    if (taskProperties && userChannels?.length) {
      if (taskProperties.settings) {
        //call api for a preview
        callApi({
          method: "post",
          url: `/accounts/${account.id}/task-groups/preview`,
          data: {
            ...omit(taskProperties, [
              "taskId",
              "taskGroupId",
              "date",
              "channelTypes",
              "metaData"
            ]),
            dateStart: removeTimezoneOffsetFromDateAsISO(
              new Date(taskProperties.dateStart)
            ),
            title: taskProperties.title || taskProperties.contentTypeId
          }
        })
          .then(result => {
            const previewData = result.map(activity => {
              return {
                id: activity.id,
                type: "TASK",
                time: activity.date,
                task: activity,
                preview: true
              };
            });
            // if we are editing a task instance of a group and users set repitition
            if (taskProperties.taskId) {
              addPreviews(previewData, taskProperties.taskId);
            } else {
              addPreviews(previewData, null, taskProperties.taskGroupId);
            }
          })
          .catch(err => {
            addToast(`Couldn't fetch preview: ${err.message}`, "error");
            removePreviews();
          });
      } else {
        //mock a task
        const previewTask = {
          id: `PREVIEW_TASK/${taskProperties.date}`,
          type: "TASK",
          time: taskProperties.date,
          task: {
            ...omit(taskProperties, ["metaData"]),
            id: `PREVIEW_TASK/${taskProperties.date}`,
            date: taskProperties.date,
            channels: userChannels.filter(
              channel =>
                taskProperties.channels.includes(channel.id) && channel.active
            )
          },
          preview: true
        };

        addPreviews([previewTask], taskProperties.taskId);
      }
    }
  }

  const handleUpdate = async () => {
    let updateTaskPromise = null;

    setIsSaving(true);

    if (taskProperties.taskId) {
      //update one task instance
      const task = omit(taskProperties, [
        "taskId",
        "taskGroupId",
        "channelTypes",
        "metaData"
      ]);
      task.title = taskProperties.title || taskProperties.contentTypeId;

      if (!taskProperties.settings) {
        task.date = removeTimezoneOffsetFromDateAsISO(new Date(task.date));
        task.id = taskProperties.taskId;

        updateTaskPromise = updateTaskInstance(
          account.id,
          taskProperties.taskId,
          task
        );
      } else {
        // when user converts a task instance to repetition, create a new task series
        task.dateStart = removeTimezoneOffsetFromDateAsISO(new Date(task.date));
        delete task.date;

        updateTaskPromise = createTaskGroup(account.id, task);
      }
    } else if (taskProperties.taskGroupId) {
      const taskGroup = omit(taskProperties, [
        "taskId",
        "taskGroupId",
        "channelTypes",
        "metaData"
      ]);
      taskGroup.id = taskProperties.taskGroupId;
      taskGroup.title = taskProperties.title || taskProperties.contentTypeId;
      taskGroup.dateStart = removeTimezoneOffsetFromDateAsISO(
        new Date(taskGroup.dateStart)
      );
      taskGroup.dateEnd = taskGroup.dateEnd
        ? removeTimezoneOffsetFromDateAsISO(new Date(taskGroup.dateEnd))
        : null;

      //update task group
      updateTaskPromise = updateTaskGroup(
        account.id,
        taskProperties.taskGroupId,
        taskGroup
      );
    }

    if (!updateTaskPromise) {
      setIsSaving(false);
      return;
    }

    try {
      await updateTaskPromise;

      addToast("Task updated", "success");
      setIsSaving(false);
      onReloadEvent.dispatch();
      onClose();
    } catch (err) {
      setIsSaving(false);
      addToast(`Couldn't update task: ${err.message}`, "error");
    }
  };

  const handleSave = () => {
    let createTaskPromise = null;

    setIsSaving(true);

    if (taskProperties.settings) {
      //create new task group
      const taskGroup = omit(taskProperties, [
        "date",
        "channelTypes",
        "metaData"
      ]);
      taskGroup.title = taskProperties.title || taskProperties.contentTypeId;
      taskGroup.dateStart = removeTimezoneOffsetFromDateAsISO(
        new Date(taskGroup.dateStart)
      );
      taskGroup.dateEnd = taskGroup.dateEnd
        ? removeTimezoneOffsetFromDateAsISO(new Date(taskGroup.dateEnd))
        : null;

      createTaskPromise = createTaskGroup(account.id, taskGroup);
    } else {
      //create new task instance
      const task = omit(taskProperties, [
        "settings",
        "channelTypes",
        "metaData"
      ]);
      task.title = taskProperties.title || taskProperties.contentTypeId;
      task.date = removeTimezoneOffsetFromDateAsISO(new Date(task.date));

      createTaskPromise = createTaskInstance(account.id, task);
    }

    createTaskPromise
      .then(createdTask => {
        addToast("Successfully created task", "success");
        onClose();
        setIsSaving(false);
        onReloadEvent.dispatch();

        openModal("ONBOARDING_INFO_MODAL", {
          id: OnboardingStepsEnum.CREATE_CONTENT_CALENDAR,
          triggeredBy: OnboardingStepsEnum.CREATE_CONTENT_CALENDAR
        });

        dispatch(
          trackAnalyticsEvent("Task Scheduled", {
            channelType: taskProperties.channelTypes,
            repeating: !!taskProperties.settings ? "Yes" : "No",
            category:
              taskProperties.postIdea?.title ?? taskProperties.contentTypeId,
            scheduledDeltaDays:
              (parseFloat(
                (
                  difference(new Date(createdTask.date), today(), "hours") / 24
                ).toFixed(1)
              ) *
                10) /
              10,
            isExtensionPost: taskProperties.metaData.isExtensionPost
              ? "Yes"
              : "No"
          })
        );
      })
      .catch(err => {
        setIsSaving(false);
        addToast(`Couldn't create task: ${err.message}`, "error");
      });
  };

  if (!taskProperties || isLoading) {
    //show loader
    return (
      <SchedulePanelContainer>
        <SchedulePanelFixer>
          <Loader location={"center"} size={32} />
        </SchedulePanelFixer>
      </SchedulePanelContainer>
    );
  }

  const isRepeating = !!taskProperties.settings;

  return (
    <SchedulePanelContainer>
      <SchedulePanelFixer>
        <SchedulePanelHeader>
          <Headline5>
            {isEditMode
              ? `Edit Task ${isEditModeSeries ? "Series" : ""}`
              : "New task"}
          </Headline5>
          <Flex>
            {isEditMode && (
              <IconButton
                variant={"danger"}
                size={"sm"}
                onClick={() => deleteEntry({ task: taskProperties })}
                icon={"icon-delete"}
              />
            )}
            <IconButton
              variant={"secondary"}
              size={"sm"}
              onClick={onClose}
              icon={X}
            />
          </Flex>
        </SchedulePanelHeader>
        <Box py={3} px={"22px"}>
          <Flex flexDirection="column" mb={8}>
            <ScheduleFieldset my={12} flexDirection="column">
              <ScheduleTitleInput
                value={taskProperties.title}
                onChange={e => updateTaskProperties({ title: e.target.value })}
              />
            </ScheduleFieldset>
            <PropertyTable>
              <PropertyField>
                <Headline6 isLight>When</Headline6>
                <Schedule
                  taskProperties={taskProperties}
                  isRepeating={isRepeating}
                  updateTaskProperties={updateTaskProperties}
                />
              </PropertyField>
              <PropertyField>
                <Headline6 isLight>Time</Headline6>
                <Box>
                  <TimeSelect
                    settings={taskProperties.settings}
                    date={
                      isRepeating
                        ? new Date(taskProperties.dateStart)
                        : new Date(taskProperties.date)
                    }
                    onChange={updates => updateTaskProperties(updates)}
                    selectedChannels={taskProperties.channels}
                    channels={account.channels}
                  />
                </Box>
              </PropertyField>
              <PropertyField align="flex-start">
                <Headline6 isLight>Channels</Headline6>
                <Box>
                  {account.channels.length > 0 ? (
                    <ChannelToggle
                      adjustPosition={false}
                      channels={account.channels}
                      selectedIds={taskProperties.channels}
                      onSave={data => {
                        updateTaskProperties({ channels: data });
                      }}
                    />
                  ) : (
                    <EmptyChannelState />
                  )}
                </Box>
              </PropertyField>
              <PropertyField>
                <Headline6 isLight>Category</Headline6>
                <Box>
                  <ContentTypeSelect
                    position="bottom-end"
                    value={taskProperties.contentTypeId}
                    onChange={value =>
                      updateTaskProperties({ contentTypeId: value })
                    }
                  />
                </Box>
              </PropertyField>
            </PropertyTable>
            <TaskDescriptionInput
              value={taskProperties.description}
              onChange={e =>
                updateTaskProperties({ description: e.target.value })
              }
            />
          </Flex>
          <SchedulePanelFooter>
            <Button
              variant="primary"
              width={"100%"}
              size={"m"}
              onClick={isEditMode ? handleUpdate : handleSave}
              disabled={account.channels.length < 1}
            >
              <Flex justifyContent="center" alignItems="center">
                {isSaving && <Loader type="spin" size={24} />}
                {isEditMode ? "Save Task" : "Create Task"}
              </Flex>
            </Button>
          </SchedulePanelFooter>
        </Box>
      </SchedulePanelFixer>
    </SchedulePanelContainer>
  );
};

const mapStateToProps = state => {
  return {
    userChannels: state.account.data.channels
  };
};

export default connect(mapStateToProps, {
  openModal: modalActions.openModal,
  addPreviews: activityActions.addPreviews,
  removePreviews: activityActions.removePreviews
})(TaskPanel);
