import { useSelector } from "react-redux";
import React, { useRef, useEffect, useState } from "react";

import {
  CalendarEntryText,
  CalendarEntryFooter,
  CalendarPost as Post,
  CalendarEntryContent,
  CalendarEntryArticle,
  CalendarEntryThumbnail,
  CalendarEntryArticleText,
  CalendarEntryArticleSource,
  CalendarEntryArticleSourceIcon
} from "./styles";
import { POST_STATUS } from "constants/post";
import PostStatistics from "./PostStatistics";
import { formatForTimeline } from "utils/date";
import { ATTACHMENT_TYPES } from "constants/post";
import {
  RightClickPostContextMenu,
  PostContextMenuWithButton
} from "../common/postContextMenu/PostContextMenu";
import CalendarEntryHeader from "./CalendarEntryHeader";
import CalendarEntryActions from "./CalendarEntryActions";
import EntryText from "components/schedule/common/entryText";
import PostAttachmentVideo from "../common/postAttachmentVideo";
import { useComposerActions } from "contextApi/composerContext";
import EmptySourceImage from "assets/images/article/empty-source.png";
import EmptyArticleImage from "assets/images/article/empty-article-image.png";
import ContentTypeBadge from "components/common/contentTypes/ContentTypeBadge";
import PostAttachmentImage from "components/schedule/common/postAttachmentImage";

const minLines = 1;
const maxLines = 10;

const CalendarPost = ({
  height,
  entry,
  hover,
  contextWithPortal,
  parent,
  autoHeight,
  dashboardEntry,
  attachmentHeight,
  fullAttachmentHeight
}) => {
  const ref = useRef(null);
  const openPost = useComposerActions(actions => actions.openPost);
  const [contextMenuPos, setContextMenuPos] = useState([]);
  const draggingItemId = useSelector(state => state.dragDrop.draggingItemId);
  const hasAttachment = entry.post?.attachment;

  const isDraftPost = entry.post.isDraft;
  const isDragging = draggingItemId === entry.post.id;
  const isGroup = entry.channels?.length > 1 && !isDraftPost;

  const closeMenu = () => {
    setContextMenuPos([]);
  };

  // Context menu handler on right click
  useEffect(() => {
    const element = ref.current;

    const handleContextMenu = e => {
      e.preventDefault();
      setContextMenuPos([e.clientX, e.clientY]);
    };

    element.addEventListener("contextmenu", handleContextMenu);

    return () => {
      element.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  // Remove context menu on scroll
  useEffect(() => {
    const element = ref.current;

    const handleScroll = () => {
      setContextMenuPos([]);
    };

    if (!!contextMenuPos.length) {
      document.addEventListener("scroll", handleScroll);
      element.parentNode.parentNode.addEventListener("scroll", handleScroll);
    }

    return () => {
      document.removeEventListener("contextmenu", handleScroll);
      element.parentNode.parentNode.removeEventListener(
        "contextmenu",
        handleScroll
      );
    };
  }, [contextMenuPos]);

  return (
    <Post
      ref={ref}
      onClick={e => {
        e.stopPropagation();
        openPost(entry.post);
      }}
      contentType={entry.post.contentTypeId}
      isGroup={isGroup}
      isPhantom={entry.isPhantom}
      isDragging={isDragging}
      isContextMenuOpen={contextMenuPos.length > 0}
      height={height}
      autoHeight={autoHeight}
    >
      <CalendarEntryHeader
        status={entry.post.isDraft ? "DRAFT" : entry.post.status}
        time={entry.time}
        channels={entry.channels}
        isStuck={entry.post.isStuck}
        parent={parent}
        date={formatForTimeline(
          new Date(entry.post.postedAt || entry.post.scheduledAt),
          true
        )}
        dashboardEntry={dashboardEntry}
      />
      <CalendarEntryContent>
        {entry.text && (
          <>
            {autoHeight ? (
              <EntryText
                text={entry.text}
                hasAttachment={hasAttachment}
                service={entry.displayedService ?? ""}
              />
            ) : (
              <CalendarEntryText
                maxLines={
                  hasAttachment ? minLines : entry.title ? minLines : maxLines
                }
              >
                {entry.text}
              </CalendarEntryText>
            )}
          </>
        )}

        {entry.post.attachment &&
          entry.post.attachment.type === ATTACHMENT_TYPES.PHOTO &&
          !entry.title && (
            <PostAttachmentImage
              attachment={entry.post.attachment}
              maxHeight={
                autoHeight
                  ? !entry.post.attachment.metaData?.[0]?.height ||
                    !entry.post.attachment.metaData?.[0]?.width
                    ? undefined
                    : (entry.post.attachment.metaData[0].height /
                        entry.post.attachment.metaData[0].width) *
                      498
                  : attachmentHeight
              }
              height={
                fullAttachmentHeight
                  ? "100%"
                  : attachmentHeight
                  ? attachmentHeight / 2
                  : undefined
              }
            />
          )}
        {entry.post.attachment &&
          entry.post.attachment.type === ATTACHMENT_TYPES.VIDEO && (
            <PostAttachmentVideo
              height={
                autoHeight ? undefined : entry.channels.length > 3 ? 118 : 136
              }
              attachment={entry.post.attachment}
              videoLess={entry.isPhantom || isDragging}
            />
          )}
        {hasAttachment && entry.title && (
          <CalendarEntryArticle>
            <CalendarEntryThumbnail
              imgSrc={entry.picture || EmptyArticleImage}
            />
            <CalendarEntryArticleText>
              <p>{entry.title}</p>
              <CalendarEntryArticleSource>
                <CalendarEntryArticleSourceIcon
                  src={entry.post.attachment?.favicon || EmptySourceImage}
                  alt={entry.post.attachment.domain}
                  onError={e => {
                    e.target.onerror = null;
                    e.target.src = EmptySourceImage;
                  }}
                />
                <span>{entry.post.attachment.domain}</span>
              </CalendarEntryArticleSource>
            </CalendarEntryArticleText>
          </CalendarEntryArticle>
        )}
      </CalendarEntryContent>
      <RightClickPostContextMenu
        entry={entry}
        closeMenu={closeMenu}
        contextMenuPos={contextMenuPos}
      />
      <PostContextMenuWithButton
        entry={entry}
        contextWithPortal={contextWithPortal}
      />
      <CalendarEntryFooter rowReverse={entry.post.contentTypeId === "other"}>
        {entry.post.contentTypeId !== "other" && (
          <ContentTypeBadge
            contentTypeId={entry.post.contentTypeId}
            title={entry.post.postIdea?.title || entry.post.contentTypeLabel}
            size="sm"
            variant={"dark-info"}
          />
        )}
        {entry.post?.status === POST_STATUS.SENT ? (
          <PostStatistics stats={entry.post.stats} />
        ) : (
          <CalendarEntryActions visible={hover} entry={entry} />
        )}
      </CalendarEntryFooter>
    </Post>
  );
};

export default CalendarPost;
