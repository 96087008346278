export const ADD_DRAFT = "@@activities/ADD_DRAFT";
export const ADD_DRAFTS = "@@activities/ADD_DRAFTS";
export const ADD_DRAFT_COUNT = "@@activities/ADD_DRAFT_COUNT";
export const REMOVE_DRAFT_BY_ID = "@@activities/REMOVE_DRAFT_BY_ID";
export const CHANGE_DRAFT_COUNT = "@@activities/CHANGE_DRAFT_COUNT";

export const saveDraftCount = count => {
  return {
    type: ADD_DRAFT_COUNT,
    count
  };
};

export const saveDrafts = payload => {
  return {
    type: ADD_DRAFTS,
    payload
  };
};

export const addDraft = payload => {
  return {
    type: ADD_DRAFT,
    payload
  };
};

export const removeDraftById = id => {
  return {
    type: REMOVE_DRAFT_BY_ID,
    id
  };
};

export const changeDraftCountByValue = value => {
  return {
    type: CHANGE_DRAFT_COUNT,
    payload: value
  };
};
