import qs from "qs";
import { connect } from "react-redux";
import React, { useEffect } from "react";
import ReactLoading from "react-loading";
import { differenceInDays, parseISO } from "date-fns";
import { accountStatuses } from "@hellocontento/contento-common";
import { Route, Switch, Redirect, Link } from "react-router-dom";

import {
  Layout,
  Loading,
  MainContent,
  TrialBanner
} from "../components/common/styles";
import BlogPage from "./blog/BlogPage";
import IdeasPage from "./ideas/IdeasPage";
import * as localStorage from "utils/storage";
import ComposerV2 from "components/composerv2";
import ContactPage from "./contact/ContactPage";
import ContentPage from "./content/ContentPage";
import LockoutPage from "./checkout/LockoutPage";
import SchedulePage from "./schedule/SchedulePage";
import CheckoutPage from "./checkout/CheckoutPage";
import ConnectProfile from "./plan/ConnectProfile";
import { useAppState } from "contextApi/appContext";
import AnalyticsPage from "./analytics/AnalyticsPage";
import DashboardPage from "./dashboard/DashboardPage";
import ModalRoot from "../components/modals/ModalRoot";
import * as sessionStorage from "utils/sessionStorage";
import CloseAccountPage from "./checkout/CloseAccountPage";
import MainHeader from "../components/navigation/MainHeader";
import ConnectFacebookPage from "./plan/ConnectFacebookPage";
import * as modalActions from "../state/actions/ModalActions";
import AccountSettingsPage from "./settings/AccountSettingsPage";
import TelenetCheckoutPage from "./checkout/TelenetCheckoutPage";
import * as accountActions from "../state/actions/AccountActions";
import { NotificationProvider } from "contextApi/notificationContext";
import ConnectLinkedinBusinessPage from "./plan/ConnectLinkedinBusinessPage";
import ConnectInstagramBusinessPage from "./plan/ConnectInstagramBusinessPage";
import FeatureNotificationModal from "components/modals/pop-ups/FeatureNotificationModal";

const AppContainer = React.memo(
  ({
    match,
    account,
    location,
    openModal,
    fetchAccount,
    isFetchingAccount
  }) => {
    const client = useAppState(state => state.clientSettings.client);
    const isTelenetClient = !!client && client === "telenet";

    useEffect(() => {
      fetchAccount(match.params.accountId);
    }, [match.params.accountId, fetchAccount]);

    useEffect(() => {
      const queryParams = qs.parse(location.search, {
        ignoreQueryPrefix: true
      });
      if (queryParams.modal) {
        switch (queryParams.modal) {
          case "checkout_success":
            openModal("CHECKOUT_SUCCESS_MODAL");
            break;
          default:
            return;
        }
      }
    }, [location.search, openModal]);

    const channels = account?.channels;

    //show modal to reconnect channel
    useEffect(() => {
      if (channels) {
        const now = +new Date();
        const period = 6.048e8;

        for (const channel of channels.filter(
          c =>
            c.needsReconnection ||
            (c.service === "linkedin" &&
              !(c.linkedinVersion ?? "").includes("v2"))
        )) {
          const storageId = `reconnect_channel_${channel.id}`;
          const lastReconnectionPrompt =
            channel.service === "linkedin" &&
            !(channel.linkedinVersion ?? "").includes("v2")
              ? sessionStorage.get(storageId)
              : localStorage.get(storageId);

          if (
            !lastReconnectionPrompt ||
            now - lastReconnectionPrompt > period
          ) {
            openModal("CHANNEL_RECONNECT_MODAL");
          }
        }
      }
    }, [channels, location.pathname, openModal]);

    if (isFetchingAccount || !account) {
      return (
        <Loading>
          <ReactLoading type="spin" color="#bbb" />
        </Loading>
      );
    }

    const section = location.pathname.replace(match.url, "").split("/")[1];

    const getMainLayout = () => (
      <Layout>
        <NotificationProvider>
          <FeatureNotificationModal />
          <ComposerV2 />
          <MainHeader section={section} />
          <MainContent>
            <Switch>
              <Route
                path={match.url + "/dashboard"}
                component={DashboardPage}
              />
              <Route path={match.url + "/schedule"} component={SchedulePage} />
              <Route
                path={match.url + "/analytics"}
                component={AnalyticsPage}
              />
              {!isTelenetClient && (
                <Route path={match.url + "/content"} component={ContentPage} />
              )}
              <Route path={match.url + "/ideas"} component={IdeasPage} />
              <Route
                path={match.url + "/connect/facebook-page"}
                component={ConnectFacebookPage}
              />
              <Route
                path={match.url + "/connect/instagram-business"}
                component={ConnectInstagramBusinessPage}
              />
              <Route
                path={match.url + "/connect/linkedin-business"}
                component={ConnectLinkedinBusinessPage}
              />
              <Route
                path={match.url + "/connect/profile"}
                component={ConnectProfile}
              />
              <Route path={match.url + "/contact"} component={ContactPage} />
              <Route
                path={match.url + "/settings"}
                component={AccountSettingsPage}
              />
              <Route path={match.url + "/blog"} component={BlogPage} />
              <Redirect to={match.url + "/dashboard"} />
            </Switch>
          </MainContent>
          {account.billing && account.billing.billingStatus === "in_trial" && (
            <TrialBanner>
              You have{" "}
              {differenceInDays(parseISO(account.billing.trialEnd), new Date())}{" "}
              days left on your trial.{" "}
              <Link
                to={{ pathname: "https://meetings.hubspot.com/brent-schepers" }}
                target="_blank"
              >
                Speak to sales and upgrade today
              </Link>
              .
            </TrialBanner>
          )}
        </NotificationProvider>
      </Layout>
    );

    const layout =
      // status on our db
      account.status === accountStatuses.CANCELED ||
      // status on chargebee
      account.billing?.billingStatus === "cancelled" ? (
        <>
          <Route path={match.url + "/*"}>
            <Redirect to={match.url + "/deactivated"} />
          </Route>
          <Route path={match.url + "/"}>
            <Redirect to={match.url + "/deactivated"} />
          </Route>
        </>
      ) : (
        <>
          <Route path={match.url + "/*"}>{getMainLayout()}</Route>
        </>
      );
    return (
      <>
        <Switch>
          <Route
            path={match.url + "/checkout"}
            component={isTelenetClient ? TelenetCheckoutPage : CheckoutPage}
          />
          <Route path={match.url + "/deactivated"} component={LockoutPage} />
          <Route path={match.url + "/close"} component={CloseAccountPage} />

          {layout}
        </Switch>
        <ModalRoot />
      </>
    );
  }
);

const mapStateToProps = state => {
  return {
    account: state.account.data,
    isFetchingAccount: state.account.isFetching
  };
};

export default connect(mapStateToProps, {
  fetchAccount: accountActions.fetchAccount,
  openModal: modalActions.openModal
})(AppContainer);
