import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useRef, useState, useEffect, useCallback } from "react";

import {
  CardList,
  CardWrapper,
  DashboardSection,
  EmptyCalendarCard,
  PostTypeSelection,
  CalendarContentView
} from "containers/dashboard/styles";
import Button from "components/common/Button";
import {
  Scroller,
  ScrollerButton
} from "components/composer/attachment/styles";
import Loader from "components/common/loading/Loader";
import DashboardSectionHeader from "./dashboardSectionHeader";
import { useDashboardState } from "contextApi/dashboardContext";
import CalendarEntry from "components/schedule/calendar/CalendarEntry";

type DashboardPostType = "published" | "scheduled";

const DashboardNews: React.FC<{}> = () => {
  const history = useHistory();
  const account = useSelector<any, any>(state => state.account.data);
  const entries = useDashboardState(state => state.activities.entries);
  const isFetching = useDashboardState(state => state.activities.isFetching);
  const scrollOffset = 15;
  const isScrollRef = useRef<any>();
  const cardListRef = useRef<HTMLDivElement | null>();
  const [isLeftScrollable, setIsLeftScrollable] = useState(false);
  const [isRightScrollable, setIsRightScrollable] = useState(false);
  const [postType, setPostType] = useState<DashboardPostType>("published");

  const handleSeeMore = () => {
    history.push(`/accounts/${account.id}/schedule`);
  };

  const setMove = (state: any) => (isScrollRef.current = state);

  const lengthOfEntries = entries[postType]?.length ?? 0;

  const handleScrollerVisibility = useCallback(() => {
    if (!!cardListRef?.current) {
      const offsetWidth = cardListRef.current.offsetWidth;
      const scrollWidth = cardListRef.current.scrollWidth;
      const scrollLeftVal = cardListRef.current.scrollLeft;

      if (offsetWidth >= scrollWidth) {
        setIsLeftScrollable(false);
        setIsRightScrollable(false);
      } else {
        if (scrollLeftVal > 0) {
          setIsLeftScrollable(true);
        } else {
          setMove(false);
          setIsLeftScrollable(false);
        }

        if (scrollLeftVal + offsetWidth < scrollWidth) {
          setIsRightScrollable(true);
        } else {
          setMove(false);
          setIsRightScrollable(false);
        }
      }
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      handleScrollerVisibility();
    }, 200);
  }, [postType, lengthOfEntries, handleScrollerVisibility]);

  useEffect(() => {
    if (!!cardListRef?.current) {
      cardListRef.current.addEventListener("scroll", handleScrollerVisibility);
    }

    return () => {
      if (!!cardListRef?.current) {
        cardListRef.current.removeEventListener(
          "scroll",
          handleScrollerVisibility
        );
      }
    };
  });

  const scrollLeft = () => {
    if (!!cardListRef?.current && isScrollRef.current) {
      cardListRef.current.scrollLeft -= scrollOffset;
      requestAnimationFrame(scrollLeft);
    }
  };

  const scrollRight = () => {
    if (!!cardListRef?.current && isScrollRef.current) {
      cardListRef.current.scrollLeft += scrollOffset;
      requestAnimationFrame(scrollRight);
    }
  };

  const calendarPosts =
    postType === "published"
      ? [...entries["published"]].reverse()
      : entries["scheduled"];

  return (
    <DashboardSection>
      <DashboardSectionHeader
        buttonText="See all"
        title="Calendar"
        handleSeeMore={handleSeeMore}
        iconClass="icon-calendar-filled"
      />
      <PostTypeSelection>
        {/* @ts-ignore */}
        <Button
          size="xs"
          variant={postType === "published" ? "black" : "secondary"}
          onClick={() => setPostType("published")}
        >
          Published
        </Button>
        {/* @ts-ignore */}
        <Button
          size="xs"
          variant={postType === "scheduled" ? "black" : "secondary"}
          onClick={() => setPostType("scheduled")}
        >
          Scheduled
        </Button>
      </PostTypeSelection>
      <CalendarContentView>
        <CardList
          // @ts-ignore
          isLoading={isFetching}
          ref={cardListRef}
        >
          {isFetching ? (
            <Loader size={24} />
          ) : (
            <>
              {calendarPosts.slice(0, 8).map((entry: any) => (
                <CardWrapper key={entry.id}>
                  <CalendarEntry
                    entry={entry}
                    height={278}
                    dashboardEntry
                    // @ts-ignore
                    attachmentHeight={142}
                  />
                </CardWrapper>
              ))}
              <CardWrapper>
                <EmptyCalendarCard onClick={handleSeeMore}>
                  Go to the calendar <i className="icon-return" />
                </EmptyCalendarCard>
              </CardWrapper>
            </>
          )}
        </CardList>
        {isLeftScrollable && (
          // @ts-ignore
          <Scroller direction="left">
            <ScrollerButton
              // @ts-ignore
              direction="left"
              size={40}
              iconSize={18}
              onMouseDown={() => {
                setMove(true);
                scrollLeft();
              }}
              onMouseUp={() => setMove(false)}
            >
              <i className="icon-arrowleft" />
            </ScrollerButton>
          </Scroller>
        )}
        {isRightScrollable && (
          // @ts-ignore
          <Scroller direction="right">
            <ScrollerButton
              // @ts-ignore
              direction="right"
              size={40}
              iconSize={18}
              onMouseDown={() => {
                setMove(true);
                scrollRight();
              }}
              onMouseUp={() => setMove(false)}
            >
              <i className="icon-arrowright" />
            </ScrollerButton>
          </Scroller>
        )}
      </CalendarContentView>
    </DashboardSection>
  );
};

export default DashboardNews;
