import { connect } from "react-redux";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Warning } from "@styled-icons/ionicons-solid";
import { Flex, Box, Image } from "rebass/styled-components";

import {
  Username,
  CardHead,
  CardAction,
  ServiceCard,
  CardActions,
  Instruction,
  ServiceName,
  CardMoreMenu,
  ChannelStatus,
  CardActionWrapper
} from "./services/styles";
import Button from "components/common/Button";
import moreIcon from "assets/images/more.png";
import { ChannelAvatar, Avatar, Service } from "components/common/styles";
import DefaultAvatar from "assets/images/dashboard/avatar-empty-state.png";

const ChannelConnectionCard = props => {
  const {
    disabled,
    username,
    status,
    onConnect,
    channel,
    onDisconnect,
    squareIcon,
    avatar,
    instruction,
    account,
    connected
  } = props;
  const history = useHistory();

  let button = null;

  switch (status) {
    case "connected":
      button = null;
      break;
    case "toConnect":
      button = (
        <Button variant="primary" onClick={onConnect} disabled={disabled}>
          Connect
        </Button>
      );
      break;
    case "toUpgrade":
      button = (
        <Button
          variant="primaryLite"
          onClick={() =>
            history.push(`/accounts/${account.id}/settings/billing#usage`)
          }
        >
          Upgrade
        </Button>
      );
      break;
    case "toReconnect":
      button = <Button onClick={onConnect}>Reconnect</Button>;
      break;
    case "toUrgentlyReconnect":
      button = (
        <Button variant="danger" onClick={onConnect} icon={Warning}>
          Reconnect
        </Button>
      );
      break;
    default:
      break;
  }

  let channelAvatar;
  if (
    status === "connected" ||
    status === "toReconnect" ||
    status === "toUrgentlyReconnect"
  ) {
    channelAvatar = (
      <>
        <Avatar
          src={avatar ? avatar : DefaultAvatar}
          isRounded
          width={48}
          height={48}
          noShadow
          onError={e => {
            e.currentTarget.onerror = null;
            e.currentTarget.src = DefaultAvatar;
          }}
        />
        <Service size={24} title={channel.username} type={channel.service} />
      </>
    );
  } else {
    channelAvatar = (
      <>
        <Avatar
          enabled={true}
          src={squareIcon}
          width={48}
          height={48}
          isRounded
          noShadow
        />
      </>
    );
  }

  let channelStatus;
  switch (status) {
    case "toReconnect":
      channelStatus = "Disconnected";
      break;
    case "toUrgentlyReconnect":
      channelStatus = "Reconnection required";
      break;
    case "readOnly":
      channelStatus = "Read-only";
      break;
    default:
      channelStatus = "Connected";
      break;
  }

  return (
    <ServiceCard status={status} connected={connected}>
      <Flex px={32} py={20} alignItems="center" justifyContent="space-between">
        <Box my={20}>
          <ChannelAvatar
            clickable={
              !status ||
              status === "toConnect" ||
              status === "toReconnect" ||
              status === "toUrgentlyReconnect"
            }
            enabled={true}
            onClick={
              !status ||
              status === "toConnect" ||
              status === "toReconnect" ||
              status === "toUrgentlyReconnect"
                ? onConnect
                : null
            }
          >
            {channelAvatar}
          </ChannelAvatar>
        </Box>
        <ServiceName>
          {username && <Username>{username}</Username>}
          {(status === "connected" ||
            status === "toReconnect" ||
            status === "toUrgentlyReconnect") && (
            <ChannelStatus status={status}>{channelStatus}</ChannelStatus>
          )}
          {status !== "connected" &&
            status !== "toReconnect" &&
            status !== "toUrgentlyReconnect" &&
            instruction && <Instruction>{instruction}</Instruction>}
        </ServiceName>
        <CardActionWrapper>
          {button}
          {(!button ||
            status === "toReconnect" ||
            status === "toUrgentlyReconnect") &&
            onDisconnect && (
              <CardHeader showMore={true} onDisconnect={onDisconnect} />
            )}
        </CardActionWrapper>
      </Flex>
    </ServiceCard>
  );
};

function CardHeader({ showMore, onDisconnect }) {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuClosingTimeout, setMenuClosingTimeout] = useState(0);

  const closeMenu = () => {
    setMenuVisible(false);
    clearTimeout(menuClosingTimeout);
  };

  const showMenu = () => {
    clearTimeout(menuClosingTimeout);
    const timeout = setTimeout(closeMenu, 1000);
    setMenuClosingTimeout(timeout);
    setMenuVisible(true);
  };

  const preventClosing = () => {
    clearTimeout(menuClosingTimeout);
  };

  return (
    <CardHead height={40} width={40} p={10}>
      {showMore && (
        <CardMoreMenu
          height={20}
          width={20}
          onClick={menuVisible ? closeMenu : showMenu}
        >
          <Image src={moreIcon} alt="More icon" />
        </CardMoreMenu>
      )}{" "}
      &nbsp;
      <CardActions
        style={{ display: menuVisible ? "block" : "none" }}
        onMouseEnter={preventClosing}
        onMouseLeave={closeMenu}
      >
        <CardAction onClick={onDisconnect}>
          <Flex alignItems="center">
            <span className="icon-delete"></span>
            Remove this account
          </Flex>
        </CardAction>
      </CardActions>
    </CardHead>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.data,
    currentUser: state.auth.currentUser
  };
};

export default connect(mapStateToProps, {})(ChannelConnectionCard);
