import styled from "styled-components";
import { Flex, Text, Box, Image, Button } from "rebass/styled-components";

export const ChannelList = styled(Flex)`
  margin: 0 -8px 0 -8px;
`;

export const AdditionalChannels = styled(Box)`
  border-radius: 8px;
  border: 1px solid #e6e8eb;
  margin: 0 25px 32px 25px;
`;

export const AdditionalChannel = styled(Flex)`
  height: 80px;
  align-items: center;
  border-bottom: 1px solid #e6e8eb;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: space-between;
`;

export const ChannelName = styled(Text)`
  font-size: 16px;
  color: ${props => props.theme.colors.black};
  flex-grow: 1;
  padding-left: 17px;
`;

export const ConnectLink = styled(Text)`
  color: ${props => props.theme.colors.blue};
  padding-right: 12px;
  cursor: pointer;
`;

export const Notice = styled(Text)`
  text-align: center;
  font-size: 14px;
  margin: 0 25px 30px 25px;
  line-height: 18px;
`;

export const PageList = styled(Box)`
  border-top: solid 1px #e6e8eb;
  border-bottom: solid 1px #e6e8eb;
  margin: 0 -18px 0 -18px;
  max-height: 262px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px 0;
`;

export const Page = styled(Flex)`
  height: 80px;
  padding: 22px 44px;
  align-items: center;
  justify-content: space-between;
`;

export const PageName = styled(Text)`
  color: ${props => props.theme.colors.black};
  font-size: 16px;
  margin-left: 20px;
  flex-grow: 1;
  cursor: pointer;
`;

export const PageIcon = styled(Image)`
  border-radius: 25px;
`;

export const ActionButton = styled(Button)`
  cursor: pointer;
  width: 120px;
  text-align: center;
  height: 40px;
  border: none;
  display: inline-block;
`;
