import { useSelector } from "react-redux";
import styled, { css } from "styled-components";
import { Link, useHistory } from "react-router-dom";
import { Box, Flex } from "rebass/styled-components";
import React, { useState, useEffect, useCallback } from "react";
import { ChevronRight, RightArrowAlt } from "@styled-icons/boxicons-regular";

import { callApi } from "utils/ContentoApi";
import Button from "components/common/Button";
import SeeMore from "components/common/seeMore";
import Carousel from "components/common/Carousel";
import Loader from "components/common/loading/Loader";
import { useContentoApi } from "utils/useContentoApi";
import ContentItem from "components/content/ContentItem";
import DashboardSectionHeader from "./dashboardSectionHeader";
import KeywordIcon from "assets/images/contents/Keyword-Monitor.png";
import { DashboardSection, DashboardFooter } from "containers/dashboard/styles";

const DashboardContentWrapper = styled(Box)`
  background-color: #f2f4f5;
  border-radius: 12px;
  padding: 16px 0;
`;

const DashboardContentList = styled(Box)`
  padding: 12px 0px 16px;
`;

const StyledInfluencer = styled(Flex)`
  align-items: center;
  cursor: pointer;
  flex-direction: column;
  min-width: 80px;

  ${props =>
    props.isSelected &&
    css`
      &::after {
        border-color: transparent transparent #f2f4f5 transparent;
        border-style: solid;
        border-width: 0 14px 16px 14px;
        content: "";
        display: block;
        height: 0;
        width: 0;
      }
    `}
`;

const InfluencerAvatar = styled.figure`
  align-items: center;
  border-radius: 90px;
  background-color: ${props => props.theme.colors.grey01};
  box-shadow: ${props =>
    props.hasNewContent
      ? `0 0 0 1px ${props.theme.colors.grey02}, 0 0 0 2px white, 0 0 0 4px ${props.theme.colors.primary}`
      : props.isIcon
      ? "none"
      : `0 0 0 1px ${props.theme.colors.grey02}`};
  display: flex;
  height: 64px;
  justify-content: center;
  overflow: hidden;
  width: 64px;
`;

const InfluencerName = styled.span`
  color: ${props =>
    props.isSelected ? props.theme.colors.primary : props.theme.colors.text02};
  font-weight: ${props => (props.isSelected ? "700" : "400")};
  display: block;
  font-size: 10px;
  letter-spacing: -0.15px;
  margin: 8px 0;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
  max-width: 64px;
  width: 100%;
`;

const InfluencerLink = styled(Link)`
  font-weight: bold;
`;

function Influencer({ idx, item, selectedInfluencer, onClick }) {
  const [isSeen, setSeen] = useState(!item.hasNewContent);
  const handleClick = () => {
    setSeen(true);
    onClick(idx);
  };

  return (
    <StyledInfluencer
      isSelected={
        selectedInfluencer && selectedInfluencer.section.id === item.section.id
      }
      onClick={handleClick}
    >
      <InfluencerAvatar hasNewContent={!isSeen}>
        {item.sectionType === "keywords" ? (
          <img src={KeywordIcon} alt={`${item.section.name}`} width="64" />
        ) : (
          <img
            src={item.section.image}
            alt={`${item.section.title ||
              item.section.handle ||
              item.section.domain}`}
            width="64"
          />
        )}
      </InfluencerAvatar>
      <InfluencerName
        isSelected={
          selectedInfluencer &&
          selectedInfluencer.section.id === item.section.id
        }
      >
        {item.section.title ||
          item.section.handle ||
          item.section.domain ||
          item.section.name}
      </InfluencerName>
    </StyledInfluencer>
  );
}

function InfluencerSeeMore({ onClick }) {
  return (
    <StyledInfluencer onCLick={onClick}>
      <InfluencerAvatar isIcon>
        <RightArrowAlt size={24} color={"rgb(51, 51, 51)"} />
      </InfluencerAvatar>
      <InfluencerName>See more</InfluencerName>
    </StyledInfluencer>
  );
}

function DashboardInfluencers({ showFooter = true }) {
  const account = useSelector(state => state.account.data);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [influencerContent, setInfluencerContent] = useState([]);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [unseenCount, setUnseenCount] = useState(0);

  const [fetchContent] = useContentoApi(
    `/accounts/${account.id}/news/feeds/sources-and-domains`
  );

  const handleInfluencerClick = idx => {
    let influencer = influencerContent[idx];
    setSelectedInfluencer(influencer);
    markInfluencerSeen(influencer.section.id);
  };

  const markInfluencerSeen = useCallback(
    sourceId => {
      callApi({
        method: "put",
        url: `/accounts/${account.id}/news/sections/sources/${sourceId}/last-seen-date`
      });
    },
    [account.id]
  );

  useEffect(() => {
    if (influencerContent.length > 0) {
      let updatedInfluencer = selectedInfluencer
        ? influencerContent.find(
            influencer =>
              influencer.section.id === selectedInfluencer.section.id
          )
        : influencerContent[0];
      setSelectedInfluencer(updatedInfluencer);
      markInfluencerSeen(updatedInfluencer.section.id);
    }
  }, [influencerContent, selectedInfluencer, markInfluencerSeen]);

  useEffect(() => {
    setIsLoading(true);
    fetchContent()
      .then(res => {
        let sortedInfluencers = res.sort((x, y) =>
          x.hasNewContent === y.hasNewContent ? 0 : x.hasNewContent ? -1 : 1
        );
        let limit = sortedInfluencers.reduce(
          (acc, cur) => (cur.hasNewContent ? acc + 1 : acc),
          0
        );
        setUnseenCount(limit);
        setInfluencerContent(sortedInfluencers);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        console.error(err);
      });
  }, [fetchContent]);

  const sourceLink = (function() {
    if (!selectedInfluencer?.section) return;

    let topicUrl = "";

    const { type, id } = selectedInfluencer.section;

    if (type === "twitter") {
      topicUrl = `/accounts/${account.id}/content/influencers/${id}`;
    } else if (type === "rss") {
      topicUrl = `/accounts/${account.id}/content/rss/${id}`;
    } else if (selectedInfluencer.sectionType === "keywords") {
      topicUrl = `/accounts/${account.id}/content/keywords/${id}`;
    } else {
      topicUrl = `/accounts/${account.id}/content/domains/${id}`;
    }
    return topicUrl;
  })();

  if (isLoading)
    return (
      <Box
        height={150}
        sx={{
          position: "relative"
        }}
      >
        <Loader location="center" size={32} />
      </Box>
    );

  const handleSeeMore = () => {
    history.push(`/accounts/${account.id}/content/influencers`);
  };

  return influencerContent.length > 0 ? (
    <DashboardSection>
      <DashboardSectionHeader
        iconClass="icon-source"
        buttonText="Explore all"
        handleSeeMore={handleSeeMore}
        title="Sources and influencers"
      />
      <Box>
        <Flex maxWidth="100%" paddingTop="4px" overflowX="auto">
          {influencerContent
            .slice(0, Math.max(9, unseenCount))
            .map((item, idx) => (
              <Influencer
                idx={idx}
                key={item.section.id}
                item={item}
                selectedInfluencer={selectedInfluencer}
                onClick={handleInfluencerClick}
              />
            ))}
          {influencerContent.length > Math.max(9, unseenCount) && (
            <InfluencerSeeMore
              onClick={() =>
                history.push(`/accounts/${account.id}/content/influencers`)
              }
            />
          )}
        </Flex>
        <Box>
          {selectedInfluencer && (
            <DashboardContentWrapper>
              <Flex
                paddingX={32}
                alignItems="center"
                justifyContent="space-between"
              >
                <span>
                  New links from{" "}
                  <InfluencerLink to={sourceLink}>
                    {selectedInfluencer.section.title ||
                      selectedInfluencer.section.handle ||
                      selectedInfluencer.section.domain ||
                      selectedInfluencer.section.name}
                  </InfluencerLink>
                </span>
                <SeeMore
                  handleClick={() => {
                    history.push(sourceLink);
                  }}
                />
              </Flex>
              <DashboardContentList>
                <Carousel
                  listPadding={32}
                  items={selectedInfluencer.contents}
                  defaultItemWidth={270}
                >
                  {selectedInfluencer.contents.map(item => (
                    <ContentItem
                      key={item.id}
                      content={item}
                      isNew={item.isNew ?? false}
                      sourceId={selectedInfluencer.section?.id}
                      showDescription={false}
                      tooltipPlacement="bottom"
                      variant="small"
                      enableDismiss={true}
                    />
                  ))}
                </Carousel>
              </DashboardContentList>
            </DashboardContentWrapper>
          )}
        </Box>
      </Box>
      {showFooter && (
        <DashboardFooter>
          <Button
            size="lg"
            variant="black"
            onClick={() =>
              history.push(`/accounts/${account.id}/content/influencers`)
            }
          >
            Discover more stories
            <ChevronRight size={16} />
          </Button>
        </DashboardFooter>
      )}
    </DashboardSection>
  ) : null;
}

export default DashboardInfluencers;
