import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import {
  Headline3,
  ContentZone,
  MainWrapper,
  MainContainer,
  NavigationZone,
  EnclosedContent,
  AccountSettingsLayout
} from "components/common/styles";
import Team from "components/settings/Team";
import Account from "components/settings/Account";
import Billing from "components/settings/Billing";
import Channels from "components/settings/Channels";
import Hashtags from "components/settings/Hashtags";
import Security from "components/settings/Security";
import Referral from "components/settings/Referral";
import ConnectChromeExtension from "components/settings/ConnectChromeExtension";
import { useAppState } from "contextApi/appContext";
import { settingsSideNav } from "constants/navigation.js";
import CallToAction from "components/settings/CallToAction";
import Notifications from "components/settings/Notifications";
import SideNavigation from "components/navigation/SideNavigation";

const AccountSettingsPage = React.memo(({ match }) => {
  const client = useAppState(state => state.clientSettings.client);
  const showWillowExtension = useAppState(state => state.showWillowExtension);
  let sideBarLinks = [...settingsSideNav];

  if (!client || client !== "telenet") {
    sideBarLinks = sideBarLinks.filter(setting => setting.to !== "/team");
  }

  if (!showWillowExtension) {
    sideBarLinks = sideBarLinks.filter(setting => setting.to !== "/willow+");
  }

  return (
    <AccountSettingsLayout>
      <MainContainer centered>
        <MainWrapper>
          <NavigationZone flexDirection="row-reverse">
            <Headline3 marginBottom="24px">Settings</Headline3>
            <SideNavigation match={match} links={sideBarLinks} />
          </NavigationZone>
          <ContentZone>
            <EnclosedContent>
              <Switch>
                <Route path={match.url + "/account"} component={Account} />
                <Route path={match.url + "/channels"} component={Channels} />
                <Route path={match.url + "/hashtags"} component={Hashtags} />
                <Route path={match.url + "/security"} component={Security} />
                <Route
                  path={match.url + "/notifications"}
                  component={Notifications}
                />
                <Route
                  path={match.url + "/call-to-action"}
                  component={CallToAction}
                />
                {(!client || client !== "telenet") && (
                  <Route path={match.url + "/team"} component={Team} />
                )}
                <Route path={match.url + "/billing"} component={Billing} />
                <Route path={match.url + "/referral"} component={Referral} />
                {showWillowExtension && (
                  <Route
                    path={match.url + "/willow+"}
                    component={ConnectChromeExtension}
                  />
                )}
                <Redirect to={match.url + "/account"} />
              </Switch>
            </EnclosedContent>
          </ContentZone>
        </MainWrapper>
      </MainContainer>
    </AccountSettingsLayout>
  );
});

export default AccountSettingsPage;
