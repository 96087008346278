import React from "react";
import { connect } from "react-redux";

import { EnclosedContent, Paragraph } from "../common/styles";
import { SettingsSection, SettingsTitle, SettingsHeader, SettingsDescription } from "./styles";
import ChipWidget from "../common/chipWidget/ChipWidget";

function Hashtags({ account, onCompleted }) {
  const handleCompleted = (path, isCompleted) => {
    if (path === "keywords") {
      onCompleted("keywords", isCompleted);
    }
  };

  return (
    <EnclosedContent>
      <SettingsSection>
        <SettingsHeader>
          <SettingsTitle>
            Hashtags
          </SettingsTitle>
          <SettingsDescription>
            Willow lets you access your team members to share content on their channels. That way they can advocate for your business and also become thought leaders.
          </SettingsDescription>
        </SettingsHeader>
        <ChipWidget
          accountId={account.id}
          path="tags"
          fieldName="tag"
          prefix="#"
          allowSpaces={false}
          onCompleted={handleCompleted}
        >
          <Paragraph>
            Add your hashtags
          </Paragraph>
        </ChipWidget>
      </SettingsSection>
    </EnclosedContent>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {})(Hashtags);
