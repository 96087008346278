import React from "react";
import { TooltipContainer, TooltipTitle, TooltipDescription } from "./Metrics";

export default function WidgetTooltip({ title, description, children }) {
  return (
    <TooltipContainer>
      {title && <TooltipTitle>{title}</TooltipTitle>}
      {description && <TooltipDescription>{description}</TooltipDescription>}
      {children && (
        <TooltipDescription as={"div"}>{children}</TooltipDescription>
      )}
    </TooltipContainer>
  );
}
