import React from "react";
import styled from "styled-components";
import {contentDismissReasons} from "@hellocontento/contento-common";
import {
  StyledDismissReasons,
  DismissList,
  DismissTitle,
  DismissItem,
  DismissLabel,
  DismissButton,
  DismissFooter,
} from "./styles";
import Button from "../common/Button";

function ContentDismissReasons({ isModal, topic, onSubmit, onCancel, domain, image, name }) {
  return (
    <StyledDismissReasons isModal={isModal}>
      <DismissTitle>Tell us why you disliked</DismissTitle>
      <DismissList>
        <DismissItem>
          <DismissLabel>Not relevant</DismissLabel>
          <DismissButton onClick={e => onSubmit(e, contentDismissReasons.NOT_RELEVANT, {})}>Less of this</DismissButton>
        </DismissItem>
        <DismissItem>
          <DismissLabel>Behind a paywall</DismissLabel>
          <DismissButton onClick={e => onSubmit(e, contentDismissReasons.BEHIND_PAYWALL, {})}>Report</DismissButton>
        </DismissItem>
        {topic && <DismissItem>
          <DismissLabel>Not <span>Employer Branding</span></DismissLabel>
          <DismissButton onClick={e => onSubmit(e, contentDismissReasons.NOT_EMPLOYER_BRANDING, {})}>Report</DismissButton>
        </DismissItem>}
        <DismissItem>
          <DismissLabel>Promotional content</DismissLabel>
          <DismissButton onClick={e => onSubmit(e, contentDismissReasons.PROMOTIONAL_CONTENT, {})}>Report</DismissButton>
        </DismissItem>
        <DismissItem>
          <DismissLabel>Don&apos;t like <span>{domain}</span>?</DismissLabel>
          <DismissButton onClick={e => onSubmit(e, contentDismissReasons.MUTE_DOMAIN, { domain, image, name })}>Mute source</DismissButton>
        </DismissItem>
      </DismissList>
      <DismissFooter>
        <Button variant="secondary" onClick={onCancel}>Cancel</Button>
      </DismissFooter>
    </StyledDismissReasons>
  );
}

const ContentDismissWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(80vh - 72px);
`;

export { ContentDismissWrapper };
export default ContentDismissReasons;
