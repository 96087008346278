import ReactLoading from "react-loading";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";
import React, { useState, useEffect, useCallback } from "react";
import { RouteComponentProps, useParams } from "react-router-dom";

import Auth from "utils/Auth";
import { QuoteContainer } from "./styles";
import { Loading } from "components/common/styles";
import { chargebeePlansMapping } from "constants/index";
import { fetchQuoteDetails } from "services/onboarding";
import Testimonials from "components/quote/testimonials";
import PaymentDetails from "components/quote/paymentDetails";
import { fromUnixTime, startOf, tomorrow } from "utils/date";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import WelcomeScreen from "components/quote/welcomeScreen/WelcomeScreen";

const QuotePage: React.FC<RouteComponentProps> = React.memo(() => {
  const history = useHistory();
  const addToast = useToaster();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedStep, setSelectedStep] = useState<number>(0);
  const [quoteDetails, setQuoteDetails] = useState<{
    [key: string]: any;
  } | null>(null);
  const { quoteId } = useParams<{ quoteId: string }>();

  const getQuoteDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await fetchQuoteDetails(quoteId);
      const { plans, coachings, dbQuote, chargebeeQuote } = result;
      const { message } = dbQuote;
      const {
        status,
        valid_till: validTill,
        billing_address: billingAddress
      } = chargebeeQuote;
      const { period } = chargebeePlansMapping[plans?.[0] ?? ""] ?? {};

      setQuoteDetails(result);
      if (fromUnixTime(validTill) < startOf(tomorrow(), "day")) {
        history.replace("/");
        addToast("The link has already expired.", "error");
      } else {
        if (status !== "open") {
          history.replace("/");
          addToast("The invoice has been completed.", "error");
        } else {
          dispatch(
            trackAnalyticsEvent("Quote Viewed", {
              packageType:
                coachings?.length ?? 0 > 0 ? "Coaching" : "Tool Only",
              packageLength:
                period === "monthly"
                  ? "1 month"
                  : period === "biyearly"
                  ? "6 months"
                  : period === "yearly"
                  ? "12 months"
                  : period,
              accountName: billingAddress?.company,
              quoteDetails: !!message ? "Yes" : "No"
            })
          );
          setSelectedStep(1);
        }
      }
    } catch (_) {
      history.replace("/");
      addToast("There was an error with the quotation link.", "error");
    } finally {
      setIsLoading(false);
      Auth.logout();
    }
  }, [history, quoteId, addToast, dispatch]);

  useEffect(() => {
    getQuoteDetails();
  }, [getQuoteDetails]);

  return (
    <QuoteContainer
      blurScreen={selectedStep === 2}
      fullWidth={selectedStep === 3}
    >
      {isLoading || !quoteDetails || selectedStep === 0 ? (
        <Loading>
          {/* @ts-ignore */}
          <ReactLoading type="spin" color="#bbb" />
        </Loading>
      ) : (
        <>
          {selectedStep === 1 || selectedStep === 2 ? (
            <>
              <section>
                <PaymentDetails
                  quoteId={quoteId}
                  quoteDetails={quoteDetails}
                  selectedStep={selectedStep}
                  setSelectedStep={setSelectedStep}
                  setQuoteDetails={setQuoteDetails}
                />
              </section>
              <section>
                <Testimonials />
              </section>
            </>
          ) : (
            <section>
              <WelcomeScreen
                selectedStep={selectedStep}
                quoteDetails={quoteDetails}
              />
            </section>
          )}
        </>
      )}
    </QuoteContainer>
  );
});

export default QuotePage;
