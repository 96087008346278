// @ts-nocheck
import React, { useCallback, useEffect, useState } from "react";

import { suggestDomain } from "services/keyword";

import { ConditionWrapper, KeywordMainWrapper, KeywordInput } from "./styles";
import Popper from "components/common/Popper";
import Divider from "components/common/Divider";
import CategorySelection from "./components/CategorySelection";
import KeywordSelected from "./components/KeywordSelected";
import ContextMenu from "components/common/contextMenu";
import OrButton from "./components/OrButton";

import { CategoryData } from "./constants/category";
import ConjuctionOption from "./components/ConjuctionOption";
import { ConjunctionData } from "./constants/types";

import { isValidUrl, getDomainFromUrl } from "./utils/url";

interface IConditionFormProps {
  id: string;
  isFirst: boolean;
  category: CategoryData;
  haveConjunction: boolean;
  keywords: Array<{
    id: string;
    keyword: string;
  }>;
  conjunction: ConjunctionData;
  onRemove: (id: string) => void;
  removeKeyword: (alertId: string, id: string) => void;
  addKeyword: (id: string, keyword: string) => void;
  onChangeCategory: (id: string, category: CategoryData) => void;
  onChangeConjunction: (id: string, conj: ConjunctionData) => void;
  editKeyword: (id: string, keywordId: string, keyword: string) => void;
}

const ConditionForm: React.FC<IConditionFormProps> = ({
  id,
  isFirst,
  category,
  onRemove,
  keywords,
  addKeyword,
  conjunction,
  editKeyword,
  removeKeyword,
  haveConjunction,
  onChangeCategory,
  onChangeConjunction
}) => {
  const [keyword, setKeyword] = useState<string>("");

  const [suggestedDomains, setSuggestedDomains] = useState<any>([]);
  const [isFetchingSuggestedDomains, setFetchingSuggestedDomains] = useState<
    boolean
  >(false);
  const [isInvalidDomain, setIsInvalidDomain] = useState<boolean>(false);

  const [referenceElement, setReferenceElement] = useState<any>(null);
  const [showOptions, setShowOptions] = useState<boolean>(false);

  const [keywordInputDisplay, setKeywordInputDisplay] = useState(false);

  const onKeywordInputKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (
      e.key === "Enter" &&
      !!keyword &&
      !["language"].includes(category.value)
    ) {
      if (category.value === "website") {
        if (!isInvalidDomain) {
          addKeyword(id, getDomainFromUrl(keyword));
          setKeyword("");
        }
      } else {
        addKeyword(id, keyword);
        setKeyword("");
      }
    }
  };

  const searchDomainsByKeyword = useCallback(async () => {
    if (!keyword) return;

    try {
      setFetchingSuggestedDomains(true);
      const response = await suggestDomain(keyword);
      const options = response.map((res: any) => {
        return {
          label: res.domain,
          onClick: () => {
            setKeyword("");
            addKeyword(id, res.domain);
          },
          image: res.image
        };
      });

      setSuggestedDomains(options);
    } catch (_) {
      setSuggestedDomains([]);
    } finally {
      setFetchingSuggestedDomains(false);
    }
  }, [keyword]);

  useEffect(() => {
    let searchDelay = null;
    if (category.value === "website") {
      searchDelay = setTimeout(() => {
        searchDomainsByKeyword();
      }, 300);
    }
    return () => clearTimeout(searchDelay);
  }, [category, searchDomainsByKeyword]);

  const defaultOptions = [
    {
      label: (
        <>
          Please enter to add <strong>"{`${keyword}`}"</strong>
        </>
      ),
      onClick: () => {
        setKeyword("");
        addKeyword(id, keyword);
      }
    }
  ];

  let languageOptions: Array<{
    label: string | JSX.Element;
    onClick?: () => void;
  }> = [
    {
      label: "English",
      keyword: "en",
      onClick: () => {
        setKeyword("");
        addKeyword(id, "en");
      }
    },
    {
      label: "Dutch",
      keyword: "nl",
      onClick: () => {
        setKeyword("");
        addKeyword(id, "nl");
      }
    }
  ].filter(({ label }) =>
    keyword.length < 1 ? label : label.toLowerCase().includes(keyword)
  );

  return (
    <>
      {haveConjunction && (
        <ConjuctionOption
          id={id}
          data={conjunction}
          onChange={onChangeConjunction}
        />
      )}
      <ConditionWrapper>
        <CategorySelection
          id={id}
          isFirst={isFirst}
          onRemove={onRemove}
          setCategory={onChangeCategory}
          category={category}
        />
        <Divider my={2} />
        <KeywordMainWrapper>
          {keywords.map((keyword: { id: string; keyword: string }) => (
            <KeywordSelected
              alertId={id}
              key={keyword.id}
              id={keyword.id}
              category={category}
              onEdit={editKeyword}
              label={keyword.keyword}
              onDelete={removeKeyword}
              editable={category.value.toLowerCase() !== "language"}
            />
          ))}
          <>
            {!keywordInputDisplay && keywords.length > 0 ? (
              <OrButton onClick={() => setKeywordInputDisplay(true)} />
            ) : (
              <KeywordInput
                ref={setReferenceElement}
                placeholder={
                  category.value.toLowerCase() === "language"
                    ? `Search for available languages`
                    : `Type your keyword and press enter`
                }
                value={keyword}
                autoFocus={keywords.length > 0}
                onChange={e => {
                  if (category.value === "website") {
                    setIsInvalidDomain(!isValidUrl(e.target.value));
                  }
                  setKeyword(e.target.value);
                }}
                isInvalid={category.value === "website" && isInvalidDomain}
                onKeyPress={onKeywordInputKeyPress}
                onBlur={() => setKeywordInputDisplay(false)}
                onFocus={() => {
                  if (category.value === "language") {
                    setShowOptions(true);
                  }
                }}
              />
            )}
            <Popper
              offset={[0, 4]}
              referenceElement={referenceElement}
              visible={keyword.length || showOptions}
              onClose={() => {
                setShowOptions(false);
                setKeyword("");
              }}
              exceptions={[referenceElement]}
            >
              {category.value !== "website" ? (
                <ContextMenu
                  minWidth={
                    referenceElement?.offsetWidth
                      ? referenceElement?.offsetWidth
                      : 0
                  }
                  close={() => setShowOptions(false)}
                  options={
                    category.value === "language"
                      ? languageOptions.filter(option => {
                          return !_.map(keywords, "keyword").includes(
                            option.keyword
                          );
                        })
                      : defaultOptions
                  }
                />
              ) : (
                <ContextMenu
                  minWidth={
                    referenceElement?.offsetWidth
                      ? referenceElement?.offsetWidth
                      : 0
                  }
                  isLoading={isFetchingSuggestedDomains}
                  close={() => setShowOptions(false)}
                  options={suggestedDomains}
                />
              )}
            </Popper>
          </>
        </KeywordMainWrapper>
      </ConditionWrapper>
    </>
  );
};

export default ConditionForm;
