import React from "react";

import TASK_TYPES from "../constants/taskType";
import Divider from "components/common/Divider";
import { useComposerActions } from "contextApi/composerContext";
import { TooltipOption, TooltipMenu, TooltipMenuWrapper } from "../styles";

const TaskOptions = ({ close, entry }) => {
  const { task, isPast } = entry;
  const editTask = useComposerActions(actions => actions.editTask);
  const createPostByTask = useComposerActions(
    actions => actions.createPostByTask
  );
  const deleteEntry = useComposerActions(actions => actions.deleteEntry);

  return (
    <TooltipMenu onClick={close} radius={12}>
      <TooltipMenuWrapper>
        {!isPast && (
          <>
            <TooltipOption
              onClick={() => createPostByTask(task)}
              variant="primary"
            >
              <span className="icon-write"></span>
              Write a post
            </TooltipOption>
            <TooltipOption onClick={() => createPostByTask(task, true)}>
              <span className="icon-draft"></span>
              Write draft
            </TooltipOption>
            <Divider my={-6} mx={0} />
          </>
        )}
        <TooltipOption onClick={() => editTask(task, TASK_TYPES.INSTANCE)}>
          <span className="icon-edit"></span>
          Edit this task
        </TooltipOption>
        {task.taskGroupId && (
          <TooltipOption onClick={() => editTask(task, TASK_TYPES.SERIES)}>
            <span className="icon-edit-series"></span>
            Edit all tasks in this series
          </TooltipOption>
        )}
        <Divider my={-5} mx={0} />
        <TooltipOption onClick={() => deleteEntry(entry)} variant="danger">
          <span className="icon-delete"></span>Delete task
        </TooltipOption>
      </TooltipMenuWrapper>
    </TooltipMenu>
  );
};

export default TaskOptions;
