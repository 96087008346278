import React from "react";
import Modal from "react-modal";
import { X } from "styled-icons/feather";
import IconButton from "../common/IconButton";
import { ModalHeader, modalStyles, ModalDescription, ModalTitleWrapper } from "./styles";

export const ContentoModal = ({
  title,
  description,
  onClose,
  children,
  borderRadius = 16,
  maxWidth = 550,
  showFooter = false,
  showHeader = "true"
}) => {
  const styles = modalStyles(maxWidth, showFooter);

  styles.overlay.background = "rgba(0,0,0,0.4)";
  styles.content.borderRadius = `${borderRadius}px`;

  return (
    <Modal
      ariaHideApp={false}
      isOpen={true}
      style={styles}
      onRequestClose={onClose}
    >
      {showHeader && (
        <>
          <ModalHeader>
            <ModalTitleWrapper>
              <span>{title}</span>
              <IconButton
                variant={"secondary"}
                size={"sm"}
                onClick={onClose}
                icon={X}
              />
            </ModalTitleWrapper>
          </ModalHeader>
          {description && <ModalDescription>{description}</ModalDescription>}
        </>
      )}
      {children}
    </Modal>
  );
};
