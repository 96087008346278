import React from "react";
import { Flex, Box } from "rebass";
import styled from "styled-components";

import {
  Avatar,
  Service,
  Headline5,
  Paragraph,
  AvatarWrapper
} from "components/common/styles";
import { SOURCE_TYPE } from "services/news.types";
import SourceSubscribeButton from "../common/SourceSubscribeButton";
import VerifiedIcon from "assets/images/contents/VerifiedTwitter.png";
import defaultAvatar from "assets/images/dashboard/avatar-empty-state.png";

const Source = styled(Box)`
  background-color: white;
  box-shadow: 0 4px 24px rgba(28, 30, 31, 0.08),
    0 1px 2px rgba(28, 30, 31, 0.04);
  border-radius: 16px;
  display: grid;
  height: 248px;
  padding: 32px 24px 16px;
`;

const SourceTitle = styled(Headline5)`
  /* align-items: flex-start; */
  max-height: 40px;
  overflow: hidden;
  display: flex;
  text-wrap: ellipse;
  display: inline;

  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const VerifiedImage = styled.img`
  margin-left: 4px;
  width: 20px;
  height: 20px;
  vertical-align: middle;
`;

const TextWrapper = styled.span`
  display: inline;
  cursor: pointer;
  &:hover {
    h5 {
      color: ${props => props.theme.colors.text02};
    }
  }
`;

function getTitle(data) {
  const title =
    data.title || data.domain || data.handle || data.url || "Loading...";
  return title.length > 20 ? `${title.slice(0, 20)}...` : title;
}

export default function ExploreSource({
  data,
  type,
  onClick = () => {},
  onFollowSource,
  onUnfollowSource
}) {
  const title = getTitle(data);

  return (
    <Source>
      <Flex flexDirection={"column"} alignItems={"center"}>
        <AvatarWrapper height={64}>
          <Avatar
            src={data.image ?? defaultAvatar}
            width={64}
            height={64}
            noShadow
            isRounded
            onError={event => {
              event.target.src = defaultAvatar;
              event.onerror = null;
            }}
          />
          {[SOURCE_TYPE.TWITTER, SOURCE_TYPE.RSS].includes(type) && (
            <Service type={type} size={24} />
          )}
        </AvatarWrapper>
        <Flex
          flexDirection={"column"}
          alignItems={"center"}
          textAlign={"center"}
          my={3}
        >
          <TextWrapper onClick={onClick}>
            <SourceTitle>{title}</SourceTitle>
            {data.isVerified && <VerifiedImage src={VerifiedIcon} />}
          </TextWrapper>
          <Paragraph mb={0} variant={"light"}>
            {type === SOURCE_TYPE.TWITTER && `@${data.handle}`}
          </Paragraph>
        </Flex>
        <SourceSubscribeButton
          data={data}
          onFollowSource={onFollowSource}
          onUnfollowSource={onUnfollowSource}
        />
      </Flex>
    </Source>
  );
}
