import { useDispatch } from "react-redux";
import React, { useState, useCallback, useEffect } from "react";

import {
  Title,
  Subtitle,
  GreetingWrapper,
  WelcomeScreenContainer
} from "./styles";
import Breadcrumbs from "../breadcrumbs";
import Button from "components/common/Button";
import { login } from "state/actions/AuthActions";
import willowLogo from "assets/images/willow-text-white@2x.png";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";

interface IWelcomeScreen {
  selectedStep: number;
  quoteDetails: { [key: string]: any };
}

const WelcomeScreen: React.FC<IWelcomeScreen> = ({
  selectedStep,
  quoteDetails
}) => {
  const dispatch = useDispatch();
  const [isMeetingWidgetVisible, setIsMeetingWidgetVisible] = useState<boolean>(
    false
  );
  const { coachings, chargebeeQuote, accessToken } = quoteDetails;
  const {
    line_items: lineItems,
    billing_address: billingAddress
  } = chargebeeQuote;

  const gotoDashboard = useCallback(() => {
    dispatch(login({ accessToken }));
    dispatch(
      trackAnalyticsEvent("New User Created", {
        source: "sales"
        // existingAccount: false
      })
    );
  }, [dispatch, accessToken]);

  const handleGetStarted = useCallback(() => {
    const plan = lineItems?.[0]?.entity_id?.toLowerCase() ?? "";

    if (
      (coachings ?? []).length > 0 ||
      plan.includes("elite") ||
      plan.includes("pro")
    ) {
      setIsMeetingWidgetVisible(true);
    } else {
      gotoDashboard();
    }
  }, [coachings, gotoDashboard]);

  const handleHubspotMessage = useCallback(
    msg => {
      if (msg.origin.includes("hubspot") && msg.data.meetingBookSucceeded) {
        dispatch(trackAnalyticsEvent("Onboarding Meeting Booked"));
        setTimeout(() => {
          gotoDashboard();
        }, 3500);
      }
    },
    [dispatch, gotoDashboard]
  );

  useEffect(() => {
    window.addEventListener("message", handleHubspotMessage);

    return () => {
      window.removeEventListener("message", handleHubspotMessage);
    };
  }, [handleHubspotMessage]);

  return (
    <WelcomeScreenContainer>
      <GreetingWrapper>
        {isMeetingWidgetVisible ? (
          <>
            <Title className="meeting-title">Book your Onboarding Call</Title>
            <iframe
              className="meetings-iframe-container"
              title="Hubspot Coaching Onboarding Meeting"
              src="https://meetings.hubspot.com/virginie-dardenne/onboarding-customer-success?embed=true"
            />
          </>
        ) : (
          <>
            <img src={willowLogo} alt="Willow Logo" />
            <Title>Welcome onboard, {billingAddress.first_name}</Title>
            <Subtitle>
              We're excited to see what you create. Happy Posting 🚀
            </Subtitle>
            {/* @ts-ignore */}
            <Button
              size="xs"
              variant="primaryWhite"
              className="get-started-btn"
              onClick={handleGetStarted}
            >
              Get Started
            </Button>
          </>
        )}
      </GreetingWrapper>
      <Breadcrumbs selectedStep={selectedStep} />
    </WelcomeScreenContainer>
  );
};

export default WelcomeScreen;
