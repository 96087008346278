import React from "react";
import { AddServiceBox, AddServiceName } from "./styles";
import { Check } from "@styled-icons/boxicons-regular";
import { colors } from "../../../theme";
import iconLinkedIn from "../../../assets/images/channel-logo-linkedin.png";
import iconFacebook from "../../../assets/images/channel-logo-facebook.png";
import iconTwitter from "../../../assets/images/channel-logo-twitter.png";
import iconInstagram from "../../../assets/images/channel-logo-instagram.png";

const serviceList = {
  facebook_page: {
    name: "Facebook Page",
    socialIcon: iconFacebook
  },
  twitter_profile: {
    name: "Twitter Profile",
    socialIcon: iconTwitter
  },
  linkedin_business: {
    name: "LinkedIn Business",
    socialIcon: iconLinkedIn
  },
  linkedin_profile: {
    name: "LinkedIn Profile",
    socialIcon: iconLinkedIn
  },
  linkedin_page: {
    name: "LinkedIn Page",
    socialIcon: iconLinkedIn
  },
  instagram_profile: {
    name: "Instagram Profile",
    socialIcon: iconInstagram
  },
  instagram_business: {
    name: "Instagram Business",
    socialIcon: iconInstagram
  }
};

function AddService({ service, isConnected, onConnect }) {
  const handleClick = () => {
    if (!isConnected) onConnect(service);
  };

  return (
    <AddServiceBox isConnected={isConnected} onClick={handleClick}>
      {!isConnected ? (
        <img
          src={serviceList[service].socialIcon}
          width="32"
          height="32"
          alt={serviceList[service].name}
        />
      ) : (
        <Check size={32} color={colors.text03} />
      )}
      <AddServiceName isConnected={isConnected}>
        {serviceList[service].name}
      </AddServiceName>
    </AddServiceBox>
  );
}

export default AddService;
