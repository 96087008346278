import * as authActions from "../actions/AuthActions";
import Auth from "../../utils/Auth";

const initialState = {
  currentUser: null,
  isRenewingToken: false,
  hasValidToken: Auth.hasValidToken(),
  isFetchingUser: false,
  loginFailed: false,
  loginError: null,
  registrationError: null,
  resetPasswordSent: null,
  resetPasswordError: null,
  nonceLoginFailed: false,
  nonceLoginSuccess: false,
  onboardingStartSuccess: null,
  showSetupAccountModal: false
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.USER_INFO_REQUESTED:
      return Object.assign({}, state, {
        isFetchingUser: true
      });
    case authActions.USER_INFO_FETCHED:
      return Object.assign({}, state, {
        currentUser: action.userInfo,
        isFetchingUser: false,
        loginFailed: false
      });
    case authActions.RENEW_TOKEN:
      return Object.assign({}, state, {
        isRenewingToken: true
      });
    case authActions.TOKEN_RENEWED:
      return Object.assign({}, state, {
        isRenewingToken: false,
        loginFailed: false
      });
    case authActions.AUTHENTICATION_REQUIRED:
      return Object.assign({}, state, {
        currentUser: null,
        isFetchingUser: false,
        hasValidToken: false
      });
    case authActions.LOGOUT:
      return Object.assign({}, state, {
        isRenewingToken: false,
        isFetchingUser: false,
        currentUser: null,
        hasValidToken: false,
        loginFailed: false,
        nonceLoginSuccess: false,
        nonceLoginFailed: false
      });
    case authActions.LOGIN:
      return Object.assign({}, state, {
        loginFailed: false
      });
    case authActions.LOGIN_FAILED:
      return Object.assign({}, state, {
        loginFailed: true,
        loginError: action.errorMessage
      });
    case authActions.SHOW_SETUP_ACCOUNT_MODAL:
      return Object.assign({}, state, {
        showSetupAccountModal: action.value
      });
    case authActions.START_ONBOARDING_FAILED:
      return Object.assign({}, state, {
        onboardingStartError: action.errorMessage
      });
    case authActions.RESET_START_ERRORS:
      return Object.assign({}, state, {
        onboardingStartError: null
      });
    case authActions.RESET_PASSWORD:
      return Object.assign({}, state, {
        resetPasswordSent: false
      });
    case authActions.RESET_PASSWORD_SENT:
      return Object.assign({}, state, {
        resetPasswordSent: true
      });
    case authActions.RESET_PASSWORD_FAILED:
      return Object.assign({}, state, {
        resetPasswordSent: false,
        resetPasswordError: action.errorMessage
      });
    case authActions.NONCE_LOGIN:
      return Object.assign({}, state, {
        nonceLoginFailed: false,
        nonceLoginSuccess: false
      });
    case authActions.NONCE_LOGIN_SUCCESS:
      return Object.assign({}, state, {
        nonceLoginSuccess: true,
        nonceLoginFailed: false
      });
    case authActions.NONCE_LOGIN_FAILED:
      return Object.assign({}, state, {
        nonceLoginFailed: true,
        nonceLoginSuccess: false
      });
    default:
      return state;
  }
};

export default authReducer;
