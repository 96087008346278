import React from "react";
import { useSelector } from "react-redux";

import {
  TooltipTitle,
  StatListItem,
  TooltipContent,
  TooltipContainer
} from "../styles";
import Divider from "components/common/Divider";
import { Avatar } from "components/common/styles";
import { ICustomTooltip } from "../ExternalTooltipHandler";
import { serviceList } from "components/channelConnect/services/AvailableService";

const Tooltip: React.FC<ICustomTooltip> = ({ data }) => {
  const { channels } = useSelector<any, any>(state => state.account.data);
  const { score, individualData } = data;
  const linkedinProfiles: any[] = (channels || []).filter(
    (c: any) => `${c.service}_${c.serviceType}` === "linkedin_profile"
  );

  return (
    <TooltipContainer>
      <TooltipTitle>Post streak</TooltipTitle>
      <TooltipContent>
        {individualData
          .filter(
            (channel: any) =>
              channel.serviceAndType.toLowerCase() !== "linkedin_profile" ||
              linkedinProfiles.length === 1
          )
          .map((channel: any) => (
            <StatListItem key={channel.serviceAndType} bgcolor="transparent">
              <div className="title">
                <Avatar
                  src={
                    serviceList[
                      channel.serviceAndType as keyof typeof serviceList
                    ].socialIcon
                  }
                  width={16}
                  height={16}
                  // @ts-ignore
                  isRounded
                  noShadow
                />
                {serviceList[
                  channel.serviceAndType as keyof typeof serviceList
                ].name
                  .split(" ")[0]
                  .toLowerCase() === "linkedin"
                  ? serviceList[
                      channel.serviceAndType as keyof typeof serviceList
                    ].name
                  : serviceList[
                      channel.serviceAndType as keyof typeof serviceList
                    ].name.split(" ")[0]}
              </div>
              <div className="stats post-streak">
                <em>{channel.posts}</em> of {channel.target}
              </div>
            </StatListItem>
          ))}
        <Divider my={0} mx={-12} />
        <StatListItem bgcolor="transparent">
          <div className="title alternate">Score</div>
          <div className="stats">{score}%</div>
        </StatListItem>
      </TooltipContent>
    </TooltipContainer>
  );
};

export default Tooltip;
