// Based on https://stackoverflow.com/a/52749807

import { Image } from "rebass/styled-components";
import React, { useState, useCallback, useMemo } from "react";

const FallbackImage = React.memo(({ src, fallbackSrc, ...props }) => {
  const [errored, setErrored] = useState(false);

  const onError = useCallback(() => {
    //Only mark as errored once
    if (!errored) {
      setErrored({
        errored: true
      });
    }
  }, [errored]);

  const imgSrc = useMemo(() => (errored || !src ? fallbackSrc : src), [
    src,
    errored,
    fallbackSrc
  ]);

  return <Image src={imgSrc} onError={onError} {...props} />;
});

export default FallbackImage;
