export const handleOpenChat = () => {
  if (window.HubSpotConversations) {
    if (window.HubSpotConversations) {
      window.history.pushState({}, "hs_support", "?hs_support=true");
      window.HubSpotConversations.widget.refresh({
        openToNewThread: true
      });
      window.HubSpotConversations.widget.open();
    }
  }
};