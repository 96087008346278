import styled from "styled-components";

export const EntityWrapper = styled.span`
  position: relative;
`;

export const CaptionBubble = styled.div`
  padding: 8px 6px;
  left: 0px;
  top: -64px;
  position: absolute;
  border: 1px solid #ddd;
  background: #fff;
  /* box-shadow: 0 1.2px 3.6px 0 rgba(0, 0, 0, 0.11),
    0 6.4px 14.4px 0 rgba(0, 0, 0, 0.13); */
  z-index: 2;
  box-sizing: border-box;
  white-space: nowrap;
  display: inline-flex;
  gap: 8px;
  visibility: visible;
  transition: transform 0.15s cubic-bezier(0.3, 1.2, 0.2, 1);
  user-select: none;

  box-shadow: 0px 8px 16px -8px rgba(28, 30, 31, 0.12),
    0px 18px 27px -5px rgba(28, 30, 31, 0.15),
    0px 0px 0px 1px rgba(28, 30, 31, 0.05);
  border-radius: 24px;

  &:after,
  &:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 6px;
    margin-left: -6px;
  }

  &:before {
    border-color: rgba(221, 221, 221, 0);
    border-top-color: #ddd;
    border-width: 8px;
    margin-left: -8px;
  }
`;

export const GenerateButton = styled.div`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.14px;
  color: #1c1e21;
  cursor: pointer;
  user-select: none;
  background-color: ${props => props.theme.colors.grey02};
  padding: 7px 12px;
  border-radius: 24px;

  &:hover {
    background-color: ${props => props.theme.colors.grey03};
  }
  &:focus {
    box-shadow: 0 0 1px ${props => props.theme.colors.background1},
      0 0 0 1px ${props => props.theme.colors.background1},
      0 0 0 2px ${props => props.theme.colors.iconFill4} !important;
    background-color: ${props => props.theme.colors.grey03};
    transition: box-shadow 0.2s ease;
  }
`;

export const UseButton = styled.div`
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.14px;
  color: ${props => props.theme.colors.white};
  cursor: pointer;
  user-select: none;
  padding: 7px 12px;
  border-radius: 24px;
  background-color: ${props => props.theme.colors.blue};

  &:hover {
    background-color: #0059e2;
  }
  &:focus {
    box-shadow: 0 0 1px ${props => props.theme.colors.background1},
      0 0 0 1px ${props => props.theme.colors.background1},
      0 0 0 2px ${props => props.theme.colors.iconFill4} !important;
    background-color: #0059e2;
    transition: box-shadow 0.2s ease;
  }
`;
