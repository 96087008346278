import { Box } from "rebass";
import { connect } from "react-redux";
import React, { useEffect } from "react";

import { EmojiModal } from "../EmojiModal";
import * as localStorage from "utils/storage";
import Button from "components/common/Button";
import { connectChannel } from "utils/channels";
import { ModalTitle, ModalSubtitle } from "../styles";
import * as sessionStorage from "utils/sessionStorage";
import { closeModal } from "state/actions/ModalActions";
import AvailableService from "components/channelConnect/services/AvailableService";

const ChannelReconnectModal = ({ closeModal, account }) => {
  useEffect(() => {
    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "unset";
    };
  }, []);

  const channels = [
    ...account.channels.filter(channel => {
      const now = +new Date();
      const period = 6.048e8;

      const storageId = `reconnect_channel_${channel.id}`;
      const lastReconnectionPrompt =
        channel.service === "linkedin" &&
        !(channel.linkedinVersion ?? "").includes("v2")
          ? sessionStorage.get(storageId)
          : localStorage.get(storageId);

      return (
        (channel.needsReconnection ||
          (channel.service === "linkedin" &&
            !(channel.linkedinVersion ?? "").includes("v2"))) &&
        (!lastReconnectionPrompt || now - lastReconnectionPrompt > period)
      );
    })
  ];

  const handleChannelConnect = service => {
    connectChannel(account, service);
  };

  const handleDoLater = () => {
    const now = +new Date();

    for (let channel of channels) {
      if (
        channel.service === "linkedin" &&
        !(channel.linkedinVersion ?? "").includes("v2")
      ) {
        sessionStorage.set(`reconnect_channel_${channel.id}`, now);
      } else {
        localStorage.set(`reconnect_channel_${channel.id}`, now);
      }
    }

    closeModal();
  };

  const hasLinkedInV2Reconnection =
    channels.findIndex(
      c => c.service === "linkedin" && !(c.linkedinVersion ?? "").includes("v2")
    ) > -1;

  return (
    <EmojiModal maxWidth={840} title="" emoji="✋" onClose={handleDoLater}>
      <ModalTitle>
        {hasLinkedInV2Reconnection
          ? `You need to reconnect your channels`
          : `
          Hey, please reconnect ${
            channels.length === 1
              ? "your channel"
              : `these ${channels.length} channels`
          }
          `}
        .
      </ModalTitle>
      <ModalSubtitle>
        {hasLinkedInV2Reconnection
          ? "These channels need to be reauthenticated in order to use the latest features. Social networks regularly need to do this to ensure your account is kept safe. We've also recently made changes to our LinkedIn API which requires you to reconnect your account."
          : "Social channels will become disconnected from time to time. This is normal behavior of the social network to keep your account safe and secure."}
      </ModalSubtitle>
      <Box width="100%" marginY={24}>
        {channels
          .sort(c =>
            c.service === "linkedin" &&
            !(c.linkedinVersion ?? "").includes("v2")
              ? -1
              : 1
          )
          .map(channel => (
            <AvailableService
              key={channel.id}
              service={`${channel.service}_${channel.serviceType}`}
              channel={channel}
              status={
                channel.service === "linkedin" &&
                !(channel.linkedinVersion ?? "").includes("v2")
                  ? `toUrgentlyReconnect`
                  : `toReconnect`
              }
              onConnect={() =>
                handleChannelConnect(
                  `${channel.service}_${channel.serviceType}`
                )
              }
            />
          ))}
      </Box>
      <Button variant="secondary" onClick={handleDoLater}>
        I'll do this later
      </Button>
    </EmojiModal>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(ChannelReconnectModal);
