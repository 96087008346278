export const topicLanguages = {
  en: {
    label: "English",
  },
  nl: {
    label: "Dutch",
  },
  es: {
    label: "Spanish",
  },
  fr: {
    label: "French",
  }
};
