import { useHistory } from "react-router-dom";
import { Field, reduxForm } from "redux-form";
import { Box, Flex } from "rebass/styled-components";
import React, { SyntheticEvent, useEffect, useState } from "react";

import { required, validEmail } from "utils/Validation";
import TextInput from "components/common/form/TextInput";
import {
  Form,
  FormTitle,
  FormDescription,
  IconExclamationCircle,
  AlertMessage
} from "components/common/form/styles";

import {
  FormBox,
  Seperation,
  FormMessage,
  FieldWrapper,
  InputWrapper,
  RoundedButton,
  CheckboxWrapper
} from "../styles";

import { base64Encode } from "utils/string";
import { NativeLink } from "components/common/Link";
import Checkbox from "components/common/form/Checkbox";
import { GoogleButton } from "components/auth/google/GoogleButton";
import { LinkedinButton } from "components/auth/linkedin/LinkedinButton";

interface ISignUpFormProps {
  state: any;
  sent: boolean;
  isSigned: boolean;
  hasError: boolean;
  invite: any | undefined;
  isInviteError: boolean;
  isEmailDisabled: boolean;
  isErrorOnAgreement: boolean;
  onGoogleSignIn: () => void;
  onGoogleSignInError: () => void;
  setIsSigned: (value: boolean) => void;
  handleSubmit: (e: SyntheticEvent) => any;
}

const SignUpForm: React.FC<ISignUpFormProps> = ({
  sent,
  state,
  isSigned,
  hasError,
  setIsSigned,
  handleSubmit,
  invite,
  isInviteError,
  onGoogleSignIn,
  isEmailDisabled,
  isErrorOnAgreement,
  onGoogleSignInError
}) => {
  const history = useHistory();
  const [isSending, setIsSending] = useState<boolean>(false);

  useEffect(() => {
    if (sent || hasError) {
      setIsSending(false);
    }
  }, [sent, hasError]);

  const onSubmit = (values: SyntheticEvent) => {
    const result = handleSubmit(values);
    setIsSending(!!(result && result.type));
  };

  const redirectParams = base64Encode(`?code=${invite?.code || ""}`);
  const redirectUri = `/signUp/${redirectParams}`;

  return (
    <Flex flexDirection="column" alignItems="center">
      <FormTitle>Create Account</FormTitle>
      <FormDescription>
        Already have an account?{" "}
        <NativeLink onClick={() => history.push("/login")} bold underline>
          Sign in
        </NativeLink>
      </FormDescription>
      <>
        <LinkedinButton
          redirectPath={redirectUri}
          isSignUp={true}
          text={"Sign up with LinkedIn"}
        />
        <GoogleButton
          text="Sign up with Google"
          onSignIn={onGoogleSignIn}
          onSignInError={onGoogleSignInError}
          redirectUri={redirectUri}
        />
        <Seperation>or</Seperation>
      </>
      {/* @ts-ignore */}
      <Form onSubmit={onSubmit} isTransitioned={true} state={state} fullWidth>
        <FormBox maxWidth={"400px"}>
          <Box width="100%">
            <InputWrapper>
              <FieldWrapper>
                <Field
                  width={"100%"}
                  validate={[required]}
                  placeholder="First name"
                  component={TextInput}
                  showSuccessMark={true}
                  name="firstName"
                  className={"dim-input__field"}
                />
              </FieldWrapper>
              <FieldWrapper>
                <Field
                  width={"100%"}
                  validate={[required]}
                  placeholder="Last name"
                  showSuccessMark={true}
                  component={TextInput}
                  name="lastName"
                  className={"dim-input__field"}
                />
              </FieldWrapper>
            </InputWrapper>
            <FieldWrapper>
              <Field
                validate={[required, validEmail]}
                placeholder="Work email"
                component={TextInput}
                name="email"
                showSuccessMark={true}
                className={"dim-input__field"}
                disabled={isEmailDisabled}
              />
            </FieldWrapper>
            <Box pt={16}>
              <CheckboxWrapper justifyContent="flex-start">
                <Checkbox
                  name="sign"
                  checked={isSigned}
                  hasError={isErrorOnAgreement}
                  onChange={() => setIsSigned(!isSigned)}
                />
                <FormMessage mt={0} error={isErrorOnAgreement}>
                  I agree to the{" "}
                  <NativeLink
                    href="https://www.willow.co/legal/terms-of-service"
                    target="_blank"
                    bold
                    underline
                    hasError={isErrorOnAgreement}
                  >
                    Terms of Service
                  </NativeLink>{" "}
                  and{" "}
                  <NativeLink
                    href="https://www.willow.co/legal/privacy-policy"
                    target="_blank"
                    bold
                    underline
                    hasError={isErrorOnAgreement}
                  >
                    Privacy Policy
                  </NativeLink>
                </FormMessage>
              </CheckboxWrapper>
            </Box>
            <Box pb={16}>
              {!isInviteError ? (
                // @ts-ignore
                <RoundedButton size="lg" disabled={isSending} width="100%">
                  Sign Up with email
                </RoundedButton>
              ) : (
                <AlertMessage>
                  <h5>
                    <IconExclamationCircle color="#ffba00" />
                    Invalid invite
                  </h5>
                  Your invite code is either invalid or has expired.
                </AlertMessage>
              )}
            </Box>
          </Box>
        </FormBox>
      </Form>
    </Flex>
  );
};

export default reduxForm({
  // a unique name for the form
  form: "signUpUser",
  enableReinitialize: true
  // @ts-ignore
})(SignUpForm);
