import { Box, Flex } from "rebass";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Loader from "components/common/loading/Loader";
import { Headline3 } from "components/common/styles";
import ExploreSource from "components/content/widgets/ExploreSource";
import React, { useEffect, useCallback, useState } from "react";
import { getRecommendedTwitterAccounts, ISource } from "services/news";
import { unfollow, followExternalSources } from "services/news";
import { IExternSourceRequest } from "services/news.types";

import { RecommendationContainer, RecommendationGrid } from "../styles";
import HelperButton from "components/common/form/HelperButton";

const RecommendedInfluencers: React.FC<{ hideSeeAll?: boolean }> = ({
  hideSeeAll
}) => {
  const history = useHistory();
  const account = useSelector((state: any) => state.account.data);

  const [twitterAccounts, setTwitterAccounts] = useState<Array<ISource>>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigateTo = (url: string) => {
    history.push(`/accounts/${account.id}/content/${url}`);
  };

  const fetchRecommendedTopics = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getRecommendedTwitterAccounts();
      setTwitterAccounts(response);
      setIsLoading(false);
    } catch (e) {
      console.log(e);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchRecommendedTopics();
  }, [fetchRecommendedTopics]);

  const addTwitterSource = async (source: ISource) => {
    const twitterSubscribeData: IExternSourceRequest = {
      type: "twitter",
      handle: source.handle
    };

    try {
      await followExternalSources(twitterSubscribeData);
    } catch (_) {}
  };

  const deleteSource = async (source: ISource) => {
    try {
      await unfollow("sources", source.id);
    } catch (_) {}
  };

  return (
    <RecommendationContainer>
      <Flex alignItems={"center"} justifyContent={"space-between"}>
        <Headline3>Twitter Profiles</Headline3>
        {!hideSeeAll && (
          <HelperButton
            label={"Explore More"}
            onClick={() => navigateTo("explore?type=twitter-sources")}
          />
        )}
      </Flex>
      {isLoading ? (
        <Box
          height={150}
          sx={{
            position: "relative"
          }}
        >
          <Loader location="center" size={32} />
        </Box>
      ) : (
        <RecommendationGrid perColoum={5}>
          {twitterAccounts.slice(0, 5).map((twitter: ISource) => (
            <ExploreSource
              data={twitter}
              type={twitter.type}
              key={twitter.image}
              onFollowSource={addTwitterSource}
              onUnfollowSource={deleteSource}
              onClick={() =>
                history.push(
                  `/accounts/${account.id}/content/influencers/${twitter.id}`
                )
              }
            />
          ))}
        </RecommendationGrid>
      )}
    </RecommendationContainer>
  );
};

export default RecommendedInfluencers;
