import * as draftActions from "../actions/DraftActions";

const initialState = {
  count: 0,
  drafts: []
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case draftActions.ADD_DRAFT_COUNT:
      return Object.assign({}, state, {
        count: action.count
      });
    case draftActions.ADD_DRAFTS:
      return Object.assign({}, state, {
        drafts: action.payload
      });
    case draftActions.ADD_DRAFT: {
      return Object.assign({}, state, {
        drafts: [
          ...state.drafts.filter(draft => draft.id !== action.payload.id),
          action.payload
        ]
      });
    }
    case draftActions.REMOVE_DRAFT_BY_ID:
      return Object.assign({}, state, {
        drafts: state.drafts.filter(draft => draft.id !== action.id)
      });
    case draftActions.CHANGE_DRAFT_COUNT: {
      const count = state.count + action.payload;

      return Object.assign({}, state, {
        count: count < 1 ? 0 : count
      });
    }
    default:
      return state;
  }
};

export default accountReducer;
