import { Flex } from "rebass/styled-components";
import styled, { css } from "styled-components";
import { Check } from "@styled-icons/boxicons-regular";

import IconButton from "components/common/IconButton";

export const ContentTypeBadge = styled.div`
  border-radius: 16px;
  font-weight: 600;
  display: inline-block;
  cursor: ${props => (props.clickable ? "pointer" : "default")};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
    `};

  ${props =>
    props.size === "sm"
      ? css`
          padding: 4px 8px;
          font-size: 12px;
        `
      : css`
          padding: 6px 12px;
          font-size: 14px;
          line-height: 18px;
        `};
  ${props => {
    switch (props.variant) {
      case "dark":
        return css`
          background: ${props => props.color};
          color: white;
        `;
      case "dark-info":
        return css`
          background: ${props => props.bgColor};
          color: ${props => props.color};
        `;
      case "dark-inverse":
        return css`
          background: transparent;
          color: ${props.theme.colors.black};
        `;
      case "white":
        return css`
          background: white;
          color: ${props => props.color};
        `;
      default:
        css`
          background: ${props.bgColor};
          color: ${props.color};
        `;
    }
  }}
`;

export const EntryIndicator = styled.span`
  color: ${props => {
    switch (props.status) {
      case "SCHEDULED":
        return props.theme.colors.yellow;
      case "STUCK":
      case "PROCESSING_ERROR":
        return props.theme.colors.red;
      case "TASK_SCHEDULE":
        return props.theme.colors.black;
      case "TASK_SCHEDULE_PREVIEW":
        return props.theme.colors.white;
      default:
        return props.theme.colors.black;
    }
  }};

  line-height: 0;
`;

export const SentPostIndicator = styled(Check)`
  background: ${props => props.theme.colors.green};
  border-radius: 50%;
  color: ${props => props.theme.colors.white};
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export const ContentTypeContainer = styled(Flex)`
  background-color: ${props =>
    props.highlighted ? props.theme.colors.grey02 : "transparent"};
  border-radius: 48px;
  flex-direction: row-reverse;
  gap: 7px;
  padding: 4px 10px;

  .chevron {
    visibility: hidden;
  }

  &:hover {
    background-color: ${props => props.theme.colors.grey02};
    cursor: pointer;

    .chevron {
      visibility: visible;
    }
  }
`;

export const ChannelToggleButton = styled(IconButton)`
  background-color: transparent;

  &:hover {
    background-color: transparent;
  }
`;
