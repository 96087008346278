import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";
import React, { useState, useEffect, useCallback } from "react";

import { getFeedsByType } from "services/news";
import EmptyNewsSection from "./emptyNewsSection";
import Loader from "components/common/loading/Loader";
import ContentItem from "components/content/ContentItem";
import DashboardSectionHeader from "./dashboardSectionHeader";
import { NewsContentView, DashboardSection } from "containers/dashboard/styles";

const DashboardNews: React.FC<{}> = React.memo(() => {
  const history = useHistory();
  const addToast = useToaster();
  const account = useSelector<any, any>(state => state.account.data);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [suggestedContents, setSuggestedContents] = useState<any>([]);

  const handleSeeMore = useCallback(() => {
    history.push(`/accounts/${account.id}/content/for-you`);
  }, [history, account.id]);

  const refreshContentFeed = useCallback(async () => {
    try {
      setIsLoading(true);
      const response: any = await getFeedsByType("your-feed", { limit: 20 });
      setSuggestedContents(response.suggestedContents);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      if ((err as any).name !== "RequestCancelled") {
        addToast(
          "There was an error fetching your content. Please try again later or contact support.",
          "error"
        );
      }
    }
  }, [addToast]);

  useEffect(() => {
    refreshContentFeed();
  }, [refreshContentFeed]);

  const removeContent = useCallback((id: string, domainId?: string) => {
    if (domainId) {
      setSuggestedContents(
        suggestedContents.filter((content: any) => content.domain !== domainId)
      );
    } else {
      setSuggestedContents(
        suggestedContents.filter((content: any) => content.id !== id)
      );
    }
  }, []);

  return (
    <DashboardSection>
      <DashboardSectionHeader
        buttonText="Read all"
        title="News recommendations"
        handleSeeMore={handleSeeMore}
        iconClass="icon-compass-filled"
        hideSeeMore={suggestedContents.length < 1}
      />
      {/* @ts-ignore */}
      <NewsContentView isLoading={isLoading || suggestedContents.length < 1}>
        {isLoading ? (
          <Loader size={24} />
        ) : (
          <>
            {suggestedContents.length < 1 ? (
              <EmptyNewsSection />
            ) : (
              <>
                {suggestedContents.slice(0, 7).map((item: any) => (
                  <ContentItem
                    key={item.id}
                    // @ts-ignore
                    content={item}
                    onDismiss={removeContent}
                  />
                ))}
              </>
            )}
          </>
        )}
      </NewsContentView>
    </DashboardSection>
  );
});

export default DashboardNews;
