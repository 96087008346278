import React from "react";
import styled from "styled-components";
import { Box } from "rebass";
import { Headline5, Paragraph } from "../../common/styles";
import { topicLanguages } from "../../../constants/content";
import TopicSubscribeButton from "../common/TopicSubscribeButton";

const Topic = styled(Box)`
  align-items: center;
  border-radius: 16px;
  background-color: white;
  padding: 8px 16px 8px 8px;
  display: grid;
  grid-template-columns: 80px 1fr 36px;
  grid-template-rows: 80px;
  column-gap: 20px;
  box-shadow: 0 4px 24px rgba(28, 30, 31, 0.08),
    0 1px 2px rgba(28, 30, 31, 0.04);
`;

const TopicThumbnail = styled(Box)`
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 12px;
  overflow: hidden;
  height: 80px;
  width: 80px;

  & img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }
`;

const TopicTitle = styled(Headline5)`
  cursor: pointer;
  &:hover {
    color: ${props => props.theme.colors.text02};
  }
`;

export default function ExploreTopic({ data, onClick }) {
  const image = data.image ?? data.topic?.settings?.image;

  return (
    <Topic>
      <TopicThumbnail>
        {image && <img src={image} alt={data.name} />}
      </TopicThumbnail>
      <Box>
        <TopicTitle sx={{ cursor: "pointer" }} onClick={onClick}>
          {data.title}
        </TopicTitle>
        <Paragraph mb={0}>
          {topicLanguages[data.language]?.label ?? data.language}
        </Paragraph>
      </Box>
      <TopicSubscribeButton data={data} />
    </Topic>
  );
}
