import React, { useState } from "react";

import iconDone from "assets/images/done.svg";
import { ChannelWrapper, Avatar, ChannelIcon, ChannelUsername } from "./styles";

interface IChannel {
  checked: string;
  channel: { [key: string]: any };
  handleChannelToggle: (channelId: string) => void;
}

const Channel: React.FC<IChannel> = ({
  checked,
  channel,
  handleChannelToggle
}) => {
  const { id, service, avatar, username } = channel;
  const [isImageBroken, setIsImageBroken] = useState(false);

  const onImageError = () => {
    setIsImageBroken(true);
  };

  return (
    <ChannelWrapper checked={checked} onClick={() => handleChannelToggle(id)}>
      <Avatar>
        {!!avatar && !isImageBroken && (
          <img src={avatar} alt={username} onError={onImageError} />
        )}
      </Avatar>
      <ChannelIcon channel={service} />
      <ChannelUsername checked={checked}>
        <span>{username}</span>
        <img src={iconDone} alt={service} />
      </ChannelUsername>
    </ChannelWrapper>
  );
};

export default Channel;
