import styled, { css } from "styled-components";

import { Headline1, Headline6 } from "components/common/styles";
import ContentType from "components/common/contentTypes/data/content-types";

export const AnalyticsGrid = styled.div`
  box-sizing: border-box;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 16px;

  * {
    box-sizing: border-box;
  }
`;

interface IAnalyticsWidgetContainer {
  rowspan: number;
  colspan: number;
  spaced?: boolean;
  bgColor?: string;
}

export const AnalyticsWidgetContainer = styled.div<IAnalyticsWidgetContainer>`
  display: flex;
  max-height: 226px;
  flex-direction: column;
  grid-column-end: span ${props => props.colspan};
  grid-row-end: span ${props => props.rowspan};
  box-shadow: ${props => props.theme.shadows.shadowS};
  padding: 20px 16px 12px;
  border-radius: 24px;
  overflow: hidden;
  background-color: ${props => props.bgColor || props.theme.colors.white};

  ${props =>
    props.spaced &&
    css`
      gap: 8px;
    `};
`;

export const AnalyticsWidgetHeader = styled.div`
  display: flex;
  width: 100%;
  gap: 4px;
  flex-direction: column;
`;

export const WidgetTitle = styled(Headline6).attrs({
  isLight: true
})`
  &.alternate {
    color: ${props => props.theme.colors.darkText03};
  }
`;

export const WidgetHeaderContent = styled.div`
  display: flex;
  gap: 6px;
  align-items: flex-end;

  span.sub-content {
    font-weight: 400;
    font-size: 12px;
    line-height: 15.6px;
    color: ${props => props.theme.colors.darkText03};
  }
`;

export const WidgetStatsInfo = styled.div`
  display: flex;
  gap: 14px;
`;

export const PrimaryStatsInfo = styled(Headline1)`
  line-height: 33.6px;
  letter-spacing: -0.03em;
  color: ${props => props.theme.colors.white};
`;

export const SecondaryStatsInfo = styled(Headline6)`
  font-weight: 500;
  color: ${props => props.theme.colors.darkText01};
`;

interface IWidgetStatsLabel {
  variant?: "highlight";
}

export const WidgetStatsLabel = styled.div<IWidgetStatsLabel>`
  display: flex;
  gap: 4px;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 13px;
  line-height: 16.25px;
  letter-spacing: -0.005em;

  ${props => {
    switch (props.variant) {
      case "highlight":
        return css`
          color: ${props.theme.colors.text01};
          background-color: ${props.theme.colors.lightYellow};
          cursor: pointer;

          i:before {
            color: ${props.theme.colors.deepYellow};
          }
        `;
      default:
        return css`
          color: ${props.theme.colors.text02};
          background-color: ${props.theme.colors.grey02};
        `;
    }
  }}
`;

export const AnalyticsContentWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  &.content-mix {
    gap: 3px;
  }

  &.employee-advocacy {
    gap: 8px;
    align-items: center;
    justify-content: flex-start;
    overflow-x: auto;
  }

  .no-data {
    position: absolute;
    margin: auto auto;
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
    color: ${props => props.theme.colors.darkText03};

    &.inverted {
      color: ${props => props.theme.colors.text03};
    }
  }
`;

interface IContentMixBlock {
  ratio: number;
  contentType: string;
}

export const ContentMixBlock = styled.div<IContentMixBlock>`
  width: ${props => props.ratio * 100}%;
  min-width: 5%;
  height: 12px;
  border-radius: 4px;
  background-color: ${props =>
    props.contentType === "none"
      ? props.theme.colors.grey04
      : Object.keys(ContentType).includes(props.contentType)
      ? ContentType[props.contentType as keyof typeof ContentType].color
      : props.theme.colors.text04};

  &:hover {
    outline: 1px solid
      ${props =>
        props.contentType === "none"
          ? props.theme.colors.grey04
          : Object.keys(ContentType).includes(props.contentType)
          ? ContentType[props.contentType as keyof typeof ContentType].color
          : props.theme.colors.text04};
    outline-offset: 1px;
  }

  animation-name: bar-scale-animation;
  animation-duration: 1s;
  animation-timing-function: ease-out;

  @keyframes bar-scale-animation {
    0% {
      transform: scale(0, 1);
    }
    100% {
      transform: scale(1, 1);
    }
  }
`;

interface IEmployeeAvatar {
  score: number;
  avatar: string;
}

export const EmployeeAvatar = styled.div<IEmployeeAvatar>`
  display: flex;
  border-radius: 50%;
  flex-shrink: 0;
  
  ${props => {
    if (props.score >= 90) {
      return css`
        width: 90px;
        height: 90px;
      `;
    } else if (props.score >= 60) {
      return css`
        width: 68px;
        height: 68px;
      `;
    } else if (props.score >= 30) {
      return css`
        width: 45px;
        height: 45px;
      `;
    } else {
      return css`
        width: 34px;
        height: 34px;
        opacity: 0.5;
      `;
    }
  }};

  background: url("${props => props.avatar}") no-repeat center;
  background-size: contain;

  &:hover {
    opacity: 1;
    box-shadow: inset 0 0 0 1000px rgba(28, 30, 31, 0.25);
  }
`;

export const TooltipContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 220px;
  border-radius: 8px;
  padding: 12px;
  box-shadow: ${props => props.theme.shadows.shadowM};
  background: ${props => props.theme.colors.white};
`;

export const TooltipTitle = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 15.6px;
  color: ${props => props.theme.colors.text01};
`;

export const TooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

interface IStarListItem {
  bgcolor: string;
}

export const StatListItem = styled.div<IStarListItem>`
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    font-weight: 600;
    color: ${props => props.theme.colors.text03};
  }
  .title {
    display: flex;
    gap: 8px;
    font-weight: 500;
    font-size: 12px;
    line-height: 15.6px;
    text-transform: capitalize;
    color: ${props => props.theme.colors.text03};

    &.alternate {
      color: ${props => props.theme.colors.text01};
    }

    span.badge {
      width: 12px;
      height: 12px;
      border-radius: 50%;
      align-self: center;
      background-color: ${props =>
        props.bgcolor === "none" ? props.theme.colors.grey04 : props.bgcolor};
      ${props =>
        props.bgcolor !== "transparent" &&
        css`
          box-shadow: ${props => props.theme.shadows.shadowS};
        `}
    }
  }

  .stats {
    font-weight: 600;
    font-size: 12px;
    line-height: 15.6px;
    color: ${props => props.theme.colors.text01};

    &.post-streak {
      font-weight: normal;

      em {
        font-weight: 600;
      }
    }
  }
`;
