import Modal from "react-modal";
import { Flex, Box } from "rebass";
import styled from "styled-components";
import React, { useContext } from "react";

import Button from "components/common/Button";
import * as storageService from "utils/storage";
import { Headline2 } from "components/common/styles";
import { getModalStatus, IAlertData } from "./utils/modal";
import { FeatureAlertContext } from "./FeatureAlertProvider";
import { Status, KEY_ALERT_MODAL } from "./types/ModalStatus";
import * as sessionStorageService from "utils/sessionStorage";

const InfoImage = styled.img`
  width: 100%;
  max-height: 288px;
  object-fit: cover;
`;

const InfoWrapper = styled(Flex)`
  padding: 40px 56px;
  flex-direction: column;
  gap: 16px;
  text-align: center;
  justify-content: center;
`;

const InfoPara = styled.p`
  font-weight: 400;
  letter-spacing: -0.01em;
  text-align: center;
  font-size: 16px;
  line-height: 140%;
  margin-bottom: 78px;
  color: ${props => props.theme.colors.text01};
`;

const ButtonGroup = styled(Flex)`
  max-width: 392px;
  flex-direction: column;
  gap: 8px;
`;

const LearnMore = styled.a`
  color: ${props => props.theme.colors.blue};
  font-weight: 600;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;

const ContentWrapper = styled.div`
  max-width: 400px;
  margin: auto;
`;

export const modalStyles = (maxWidth = 520, showFooter = false) => {
  return {
    // dark background behind all modals
    overlay: {
      background: "rgba(0,0,0,0.25)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      //overflowY: 'visible',
      overflowX: "hidden",
      zIndex: 10,
      padding: "15px 20px 15px 20px"
    },
    // modal root
    content: {
      overflow: "auto",
      maxHeight: "95vh",
      boxSizing: "border-box",
      background: "#F7F8F9",
      backgroundClip: "padding-box",
      borderRadius: "24px",
      position: "relative",
      border: "1px",
      borderColor: "#EAEBEB",
      width: "100%",
      maxWidth: `${maxWidth}px`,
      top: "auto",
      bottom: "auto",
      left: "auto",
      right: "auto",
      backgroundColor: "white",
      boxShadow:
        "0px 4.8px 14.4px rgba(0, 0, 0, 0.18), 0px 25.6px 57.6px rgba(0, 0, 0, 0.22)",
      padding: 0
    }
  };
};

const FeatureAlertModal: React.FC<{}> = () => {
  const styles = modalStyles();
  const { state, dispatch } = useContext(FeatureAlertContext);
  const { id, persist, steps, currentStep } = state;

  if (!id) {
    return null;
  }

  const modalStatus = getModalStatus(id, persist);

  if (modalStatus?.isPaused || modalStatus?.status === "complete") {
    return null;
  }

  const saveState = () => {
    const newStorageItem = {
      id: id,
      status: Status.COMPLETE
    };

    const alertData = ((persist
      ? sessionStorageService.get(KEY_ALERT_MODAL)
      : storageService.get(KEY_ALERT_MODAL)) || []) as Array<IAlertData>;

    if (alertData.length > 0) {
      const storedIds = (alertData as Array<IAlertData>).map(item => item.id);

      let data: Array<IAlertData> = [];
      if (storedIds.includes(id)) {
        data = (alertData as Array<IAlertData>).map(item => {
          if (item.id === id) {
            return newStorageItem;
          } else {
            return item;
          }
        });
      } else {
        data = [...alertData, newStorageItem];
      }

      if (persist) {
        sessionStorageService.set(KEY_ALERT_MODAL, data);
      } else {
        storageService.set(KEY_ALERT_MODAL, data);
      }
    } else {
      if (persist) {
        sessionStorageService.set(KEY_ALERT_MODAL, [newStorageItem]);
      } else {
        storageService.set(KEY_ALERT_MODAL, [newStorageItem]);
      }
    }

    dispatch({
      type: "REMOVE"
    });
  };

  const prevStep = () => {
    dispatch({
      type: "PREV_STEP"
    });
  };

  const nextStep = () => {
    if (!!onProceed) {
      onProceed();
    }

    if (currentStep === steps.length - 1) {
      saveState();
    } else {
      dispatch({
        type: "NEXT_STEP"
      });
    }
  };

  const onCancel = () => {
    saveState();
  };

  const {
    title = "",
    banner,
    helpUrl,
    onProceed,
    description = "",
    returnButtonText,
    proceedButtonText
  } = steps[currentStep] ?? {};

  return (
    <Modal
      name={id}
      ariaHideApp={false}
      isOpen={true}
      // @ts-ignore
      style={styles}
      onRequestClose={onCancel}
    >
      {banner && <InfoImage src={banner} />}
      <InfoWrapper>
        <ContentWrapper>
          <Box mb={16}>
            <Headline2>{title}</Headline2>
          </Box>
          <InfoPara>
            {description}{" "}
            {helpUrl && (
              <LearnMore
                target="_blank"
                rel="noopener noreferrer"
                href={helpUrl}
              >
                {" "}
                Learn more
              </LearnMore>
            )}
          </InfoPara>
          <ButtonGroup>
            {/* @ts-ignore */}
            <Button variant="primary" onClick={nextStep}>
              {!!proceedButtonText
                ? proceedButtonText
                : steps.length - 1 === currentStep
                ? "Get Started"
                : "Next"}
            </Button>
            {currentStep > 0 && (
              // @ts-ignore
              <Button variant="secondaryQuiet" onClick={prevStep}>
                {!!returnButtonText ? returnButtonText : "Back"}
              </Button>
            )}
          </ButtonGroup>
        </ContentWrapper>
      </InfoWrapper>
    </Modal>
  );
};

export default FeatureAlertModal;
