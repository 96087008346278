import styled, { css } from "styled-components";
import { Flex } from "rebass/styled-components";
import ReactTooltip from "react-tooltip";

export const TooltipMenuWrapper = styled.div`
  min-width: ${props => props.minWidth || 240}px;
`;

export const TooltipMenu = styled.div`
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 8px 0 rgba(17, 17, 17, 0.05), 0 2px 4px rgba(0, 0, 0, 0.04);
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  z-index: 100;
  transform: translate(-24px, 8px);
`;

export const TooltipOption = styled.div`
  padding: 12px 8px;
  font-weight: 600;
  list-style: none;
  cursor: pointer;
  margin: 0px 8px;

  display: flex;
  gap: 10px;

  ${props =>
    ["primary", "danger"].includes(props.variant) &&
    css`
      color: ${props.theme.variants[props.variant].fontColor};
    `};

  &:hover {
    color: ${props => props.theme.colors.white};
    border-radius: 10px;
    background-color: ${props => props.theme.colors.blue};
  }
  span {
    font-size: ${props => (props.size ? props.size : 16)}px;
  }
`;

export const ToolTipCss = css`
  background: ${props => props.theme.colors.text01};
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  color: white;
  line-height: 1.25;
  font-size: 14px;
  padding: 6px 8px;
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  word-wrap: break-word;

  ${props => {
    switch (props.variant) {
      case "info":
        return css`
          max-width: 150px;
        `;
      default:
        return null;
    }
  }}

  ${props => {
    switch (props.size) {
      case "medium":
      default:
        return css`
          font-size: 12px;
          max-width: 120px;
        `;
      case "large":
        return css`
          font-size: 12px;
          width: 180px;
          font-weight: 400;
        `;
    }
  }}
`;

export const RawToolTip = styled.div`
  ${ToolTipCss};
`;

export const ToolTip = styled(ReactTooltip)`
  ${ToolTipCss};
`;

export const PostCreateButton = styled.button`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;

  & span {
    font-size: 24px;

    &:hover::before {
      color: ${props => props.theme.colors.green};
    }
  }
`;

export const TaskTitleWrapper = styled(Flex)`
  align-items: center;
  gap: 4px;
  margin-left: -5px;
`;
