import React, { useState } from "react";
import { Field, reduxForm } from "redux-form";
import { useHistory } from "react-router-dom";
// @ts-ignore
import { useToaster } from "@hellocontento/maillard";
import { Box } from "rebass/styled-components";
import qs from "qs";

import AlertMessage from "components/common/AlertMessage";
import TextInput from "components/common/form/TextInput";
import {
  FormTitle,
  Form,
  ErrorMessage,
  IconExclamationCircle,
  FormDescription
} from "components/common/form/styles";

import { ForgotBox, ForgotLink } from "./styles";

import {
  FormBox,
  FormLogo,
  FormContainer,
  RoundedButton,
  FieldWrapper,
  Seperation
} from "../styles";

import {
  GoogleUserData,
  IGoogleErrorEvent
} from "components/auth/google/types";

import { required, validEmail } from "utils/Validation";
import willowLogo from "assets/images/willow-text-color@2x.png";

import { NativeLink } from "components/common/Link";
import PasswordInput from "components/common/form/PasswordInput";
import { GoogleButton } from "components/auth/google/GoogleButton";
import { LinkedinButton } from "components/auth/linkedin/LinkedinButton";

interface ILoginFormProps {
  handleSubmit: () => void;
  onLogin: (data: any) => void;
  loginFailed: boolean;
  loginError: string;
  feedback: string;
}

const LoginForm = ({
  handleSubmit,
  onLogin,
  loginFailed,
  loginError,
  feedback
}: ILoginFormProps) => {
  const addToast = useToaster();
  const [authType, setAuthType] = useState<string | undefined>(undefined);
  const history = useHistory();

  const handleSignIn = (data: GoogleUserData) => {
    onLogin({ authType: "google", jwt: data.jwt });
  };

  const handleSingInError = (e: IGoogleErrorEvent) => {
    if (e.error === "popup_closed_by_user") return;
    addToast(
      "We were unable to login your account using Google. Please manually enter your details on the form.",
      "error"
    );
  };

  const queryParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });

  if (
    queryParams.authType &&
    queryParams.token &&
    authType !== queryParams.authType
  ) {
    // social media login
    // @ts-ignore
    setAuthType(queryParams.authType);
    onLogin({ authType: queryParams.authType, token: queryParams.token });
    return null;
  }

  return (
    <FormContainer>
      <FormLogo mb={"8px"}>
        <img src={willowLogo} alt="Willow" height="40" />
      </FormLogo>
      <FormTitle>Log In</FormTitle>
      <FormDescription>
        Don’t have an account?{" "}
        <NativeLink onClick={() => history.push("/signUp")} bold underline>
          Signup for free
        </NativeLink>
      </FormDescription>
      {feedback === "cancel" && (
        // @ts-ignore
        <AlertMessage variant="info" title="Account closed" maxWidth="480px">
          We have successfully received your request to cancel your account.
        </AlertMessage>
      )}
      <>
        <LinkedinButton
          redirectPath={"/login"}
          isSignUp={false}
          text={"Continue with LinkedIn"}
        />
        <GoogleButton
          text="Continue with Google"
          onSignIn={handleSignIn}
          onSignInError={handleSingInError}
        />
        <Seperation>or</Seperation>
      </>
      {/* @ts-ignore */}
      <Form onSubmit={handleSubmit} fullWidth>
        <FormBox minWidth={"400px"}>
          <FieldWrapper>
            <Field
              type="email"
              placeholder="you@company.com"
              validate={[required, validEmail]}
              component={TextInput}
              name="email"
              disableValidIndicator={true}
              className={"dim-input__field"}
            />
          </FieldWrapper>
          <FieldWrapper>
            <Field
              type="password"
              validate={[required]}
              placeholder="Password"
              component={PasswordInput}
              name="password"
              disableValidIndicator={true}
              className={"dim-input__field"}
            />
          </FieldWrapper>
          {loginFailed && (
            <ErrorMessage>
              <div>
                <IconExclamationCircle />
                {loginError === "Unauthorized"
                  ? "Oops! Incorrect email address or password."
                  : "We couldn't log you in. Please, try again later or contact support if the problem persists."}
              </div>
            </ErrorMessage>
          )}
          <Box pt={8} pb={16} width={"100%"}>
            {/* @ts-ignore */}
            <RoundedButton size="lg" width="100%">
              Log in
            </RoundedButton>
          </Box>
        </FormBox>
      </Form>
      <ForgotBox>
        <ForgotLink to={"/forgot-password"}>Forgot password?</ForgotLink>
      </ForgotBox>
      <Box marginTop="auto" height={80} />
    </FormContainer>
  );
};

export default reduxForm({
  // a unique name for the form
  form: "loginForm",
  enableReinitialize: true
  // @ts-ignore
})(LoginForm);
