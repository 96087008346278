import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import ReactLoading from "react-loading";
import ReactRotatingText from "react-rotating-text";
import { Box, Flex } from "rebass/styled-components";
import { useToaster } from "@hellocontento/maillard";

import { SystemMessage } from "../styles";
import withHookHOC from "utils/withHookHOC";
import ImageAttachment from "./ImageAttachment";
import VideoAttachment from "./VideoAttachment";
import { ATTACHMENT_TYPES } from "constants/post";
import ArticleAttachment from "./ArticleAttachment";
import { PostAttachment, AttachmentLoading } from "./styles";

class Attachment extends React.Component {
  static propTypes = {
    typeOfAttachment: PropTypes.string,
    isScraping: PropTypes.bool.isRequired,
    post: PropTypes.object.isRequired,
    onContentUpdated: PropTypes.func.isRequired,
    onUploadStart: PropTypes.func.isRequired, 
    enabledServices: PropTypes.array.isRequired,
    enabledServicesWithType: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);

    let images = [];
    const attachment = props.post.attachment;
    if (attachment && attachment.type === ATTACHMENT_TYPES.PHOTO) {
      if (Array.isArray(attachment.url)) {
        images = attachment.url.map((path, i) => ({
          path,
          metaData: attachment.metaData?.[i]
        }));
      } else {
        images.push({
          path: attachment.url,
          metaData: Array.isArray(attachment.metaData)
            ? attachment.metaData[0]
            : attachment.metaData
        });
      }
    }

    this.state = {
      uploadedImages: images,
      uploadedVideo:
        attachment && attachment.type === ATTACHMENT_TYPES.VIDEO
          ? { path: attachment.url, duration: null }
          : null,
      attachedArticle: null,
      attachmentType: null,
      forceLoading: false,
      surpassesTwitterDuration: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.typeOfAttachment !== prevProps.typeOfAttachment) {
      if (
        this.props.typeOfAttachment === ATTACHMENT_TYPES.PHOTO ||
        (this.props.typeOfAttachment === null &&
          prevProps.typeOfAttachment === ATTACHMENT_TYPES.PHOTO)
      ) {
        this.handleImageButtonClick();
      } else if (
        this.props.typeOfAttachment === ATTACHMENT_TYPES.VIDEO ||
        (this.props.typeOfAttachment === null &&
          prevProps.typeOfAttachment === ATTACHMENT_TYPES.VIDEO)
      ) {
        this.handleVideoButtonClick();
      }
    }
  }

  onImageUpload = newImages => {
    this.setState(
      prevState => {
        if (newImages === false) {
          return {};
        }
        //Take only the first 10 images.
        const images = prevState.uploadedImages.concat(newImages).slice(0, 10);
        return {
          attachedArticle: this.getAttachedArticle(),
          uploadedImages: images
        };
      },
      () => {
        const content = {
          images: this.state.uploadedImages.map(image => ({
            url: image.path,
            metaData: image.metaData
          }))
        };
        this.props.onContentUpdated(ATTACHMENT_TYPES.PHOTO, content, true);
      }
    );
  };

  onVideoUpload = (video, error, addToast) => {
    if (video === false) {
      if (error) {
        addToast(
          `There was an error uploading the video: "${error.message}"`,
          "error"
        );
      }
      this.props.onContentUpdated(ATTACHMENT_TYPES.VIDEO, {}, true);
      return;
    }

    this.setState(
      {
        uploadedVideo: video
      },
      () => {
        const content = {
          videoUrl: video.path,
          metaData: video.metaData
        };
        this.props.onContentUpdated(ATTACHMENT_TYPES.VIDEO, content, true);
      }
    );
  };

  handleRemoveImage = imageUrl => {
    this.setState(
      prevState => {
        let images = prevState.uploadedImages;
        images = images.filter(image => {
          return image.path !== imageUrl;
        });
        return {
          uploadedImages: images,
          attachmentType: ATTACHMENT_TYPES.PHOTO
        };
      },
      () => {
        const content =
          this.state.uploadedImages.length > 0
            ? {
                images: this.state.uploadedImages.map(image => ({
                  url: image.path,
                  metaData: image.metaData
                }))
              }
            : {};
        this.props.onContentUpdated(ATTACHMENT_TYPES.PHOTO, content, true);
      }
    );
  };

  handleImageReorder = (sourceIndex, destinationIndex) => {
    this.setState(
      prevState => {
        let images = prevState.uploadedImages;
        const [removed] = images.splice(sourceIndex, 1);
        images.splice(destinationIndex, 0, removed);
        return {
          uploadedImages: images,
          attachmentType: ATTACHMENT_TYPES.PHOTO
        };
      },
      () => {
        const content =
          this.state.uploadedImages.length > 0
            ? {
                images: this.state.uploadedImages.map(image => ({
                  url: image.path,
                  metaData: image.metaData
                }))
              }
            : {};
        this.props.onContentUpdated(ATTACHMENT_TYPES.PHOTO, content, true);
      }
    );
  };

  handleRemoveArticle = () => {
    this.props.onContentUpdated(ATTACHMENT_TYPES.ARTICLE, {}, true);
    this.setState({
      attachmentType: null,
      attachedArticle: null
    });
  };

  handleRemoveVideo = () => {
    this.setState(
      {
        uploadedVideo: null
      },
      () => {
        this.props.onContentUpdated(ATTACHMENT_TYPES.VIDEO, {}, true);
      }
    );
  };

  handleAttachmentSwitch = attachmentType => {
    if (this.props.isScraping) {
      return;
    }

    let content;
    switch (attachmentType) {
      case ATTACHMENT_TYPES.PHOTO:
        content = {
          images: this.state.uploadedImages.map(image => ({
            url: image.path,
            metaData: image.metaData
          }))
        };
        break;
      case ATTACHMENT_TYPES.ARTICLE:
        content = this.getAttachedArticle() || {};
        break;
      case ATTACHMENT_TYPES.VIDEO:
        content = this.state.uploadedVideo
          ? { videoUrl: this.state.uploadedVideo.path }
          : {};
        break;
      default:
        content = {};
        break;
    }

    this.props.onContentUpdated(attachmentType, content, true);

    this.setState({
      attachmentType: attachmentType,
      attachedArticle: this.getAttachedArticle()
    });
  };

  handleImageButtonClick = () => {
    if (
      this.state.attachmentType === ATTACHMENT_TYPES.PHOTO &&
      (!this.state.uploadedImages || this.state.uploadedImages.length === 0)
    ) {
      this.handleAttachmentSwitch(null);
    } else {
      this.handleAttachmentSwitch(ATTACHMENT_TYPES.PHOTO);
    }
  };

  handleVideoButtonClick = () => {
    if (
      this.state.attachmentType === ATTACHMENT_TYPES.VIDEO &&
      !this.state.uploadedVideo
    ) {
      return this.handleAttachmentSwitch(null);
    } else {
      this.handleAttachmentSwitch(ATTACHMENT_TYPES.VIDEO);
    }
  };

  getAttachedArticle = () => {
    const { post } = this.props;
    //If there is an article, save it in state.
    let attachedArticle = this.state.attachedArticle;
    if (post.attachment && post.attachment.type === ATTACHMENT_TYPES.ARTICLE) {
      attachedArticle = post.attachment;
    }
    return attachedArticle;
  };

  render() {
    const { isScraping, post, validationMessages, onUploadStart } = this.props;
    const addToast = this.props.defaultHook;

    //Let attachmentType vary with post unless explicitly set.
    let attachmentType =
      this.state.attachmentType || (post.attachment && post.attachment.type);
    let attachmentUrl = post.attachment ? post.attachment.url : undefined;

    const showLoading = isScraping && !post.attachment;
    const showArticle =
      attachmentType === ATTACHMENT_TYPES.ARTICLE && attachmentUrl;
    const showImage = attachmentType === ATTACHMENT_TYPES.PHOTO;
    const showVideo = attachmentType === ATTACHMENT_TYPES.VIDEO;

    const waitingMessages = [
      "Retrieving website contents...",
      "Still working...",
      "Still working...",
      ..._.shuffle([
        "“Patience is a conquering virtue.”",
        "“The two most powerful warriors are patience and time.”",
        "“To lose patience is to lose the battle.”",
        "“At the bottom of patience one finds heaven.”",
        "“The salt of patience seasons everything.”",
        "“Time is the wisest of all counselors.”",
        "“Patience is bitter, but its fruit is sweet.”",
        "“Our patience will achieve more than our force.”",
        "“Rivers know this: there is no hurry. We shall get there some day.”",
        "“Be patient and tough; someday this pain will be useful to you.”",
        "“Patience is a key element of success.”",
        "“All great achievements require time.”",
        "“One minute of patience, ten years of peace.”"
      ])
    ];

    return (
      <PostAttachment>
        <Box mb={12} mt={10}>
          {showLoading && (
            <AttachmentLoading>
              <Flex>
                <ReactLoading color="#1e62e0" type="spin" width={16} />
                &nbsp;&nbsp;&nbsp;
                <p>
                  <ReactRotatingText pause={1000} items={waitingMessages} />
                </p>
              </Flex>
            </AttachmentLoading>
          )}

          {showArticle && (
            <ArticleAttachment
              {...post.attachment}
              onRemove={this.handleRemoveArticle}
            />
          )}

          {showImage && (
            <ImageAttachment
              images={attachmentUrl}
              onRemove={this.handleRemoveImage}
              onReorder={this.handleImageReorder}
              onUpload={this.onImageUpload}
              onUploadStart={onUploadStart}
            />
          )}

          {showVideo && (
            <VideoAttachment
              video={attachmentUrl}
              onRemove={this.handleRemoveVideo}
              onUpload={(...args) => this.onVideoUpload(...args, addToast)}
              onUploadStart={onUploadStart}
            />
          )}
        </Box>

        {showVideo && !post.attachment?.url && (
          <Box mb={12}>
            <SystemMessage type="info">
              Only mp4 H.264 format videos are currently accepted
            </SystemMessage>
          </Box>
        )}

        {validationMessages.map(notification => (
          <Box key={notification.message} mb={12}>
            <SystemMessage
              type={notification.type}
              infoUrl={notification.infoUrl}
              service={notification.service}
              actionComponent={notification.actionComponent}
            >
              {notification.message}
            </SystemMessage>
          </Box>
        ))}
      </PostAttachment>
    );
  }
}

export default withHookHOC(Attachment, useToaster);
