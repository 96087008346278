import React, { useEffect } from "react";
import styled from "styled-components";
import { Flex } from "rebass/styled-components";

const TabMenuContainer = styled(Flex)`
  height: 100%;
`;

const Tab = styled.button`
  appearance: none;
  background: transparent;
  border: none;
  box-shadow: none;
  color: ${props =>
    props.isActive ? props.theme.colors.text02 : props.theme.colors.text03};
  cursor: pointer;
  font-family: ${props => props.theme.fonts.body};
  font-size: 14px;
  font-weight: 700;
  overflow: hidden;
  outline: none;
  padding: 0 16px;
  position: relative;

  &:hover,
  &:focus {
    color: ${props =>
      props.isActive ? props.theme.colors.text01 : props.theme.colors.text02};
  }

  &::after {
    background-color: ${props => props.theme.colors.text01};
    border-radius: 24px 24px 0 0;
    bottom: ${props => (props.isActive ? 0 : "-4px")};
    content: "";
    display: block;
    height: 4px;
    left: 2px;
    transition: 0.16s;
    position: absolute;
    width: calc(100% - 4px);
  }
`;

function TabMenu({ options = [], onClick = () => {} }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <TabMenuContainer>
      {options.map(option => (
        <Tab
          key={option.value}
          onClick={() => onClick(option.value)}
          isActive={window.location.pathname.endsWith(option.value)}
        >
          {option.label}
        </Tab>
      ))}
    </TabMenuContainer>
  );
}

export default TabMenu;
