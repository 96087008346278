import { Box } from "rebass/styled-components";
import styled, { css } from "styled-components";

export const MentionSuggestionEntryWrapper = styled.div`
  > * {
    box-sizing: border-box;
  }

  display: flex;
  flex-direction: row;
  padding: 8px;
  width: 100%;
  height: 56px;
  background: ${props =>
    props.isFocused ? props.theme.colors.blue : props.theme.colors.white};
  border-radius: 10px;
  box-sizing: border-box;
  transition: unset;
  cursor: pointer;

  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    margin-right: 10px;
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    background: url("/managers/none.png");
    background-size: contain;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      overflow: hidden;
    }

    /**
     * * For showing the first initial of the mention name when image is broken
     * ! Not used but available if needed
     */
    span {
      width: 100%;
      height: 100%;
      color: white;
      display: flex;
      font-weight: bold;
      background: #00b4ff;
      align-items: center;
      justify-content: center;
    }
  }

  .title-section {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;

    .title {
      font-weight: 500;
      font-size: 14px;
      color: ${props =>
        props.isFocused ? props.theme.colors.white : props.theme.colors.text01};
      margin: 4px 0px;
      line-height: 1.3;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
      position: relative;
    }

    .subtitle {
      font-weight: normal;
      font-size: 12px;
      line-height: 1.3;
      color: ${props =>
        props.isFocused
          ? props.theme.colors.darkText03
          : props.theme.colors.text03};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
      position: relative;

      &.emphasize {
        font-style: italic;
      }
    }
  }
`;

export const MentionSuggestionList = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 10px;
  max-height: 248px;
  box-sizing: border-box;
  overflow-y: ${props => (props.isLoading ? "hidden" : "auto")};

  &::-webkit-scrollbar {
    width: 0;
  }
`;

export const EmptyResultsWrapper = styled.div`
  * {
    box-sizing: border-box;
  }

  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;

  .guide-container {
    width: 100%;
    height: 120px;

    img {
      width: 100%;
      object-fit: contain;
      overflow: hidden;
    }
  }

  .info {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    ${props =>
      props.showGuide
        ? css`
            padding: 16px;
          `
        : css`
            padding: 40px 16px;
          `}

    .header {
      margin: 4px 0px;
      font-size: 14px;
      text-align: center;
      line-height: 17.5px;
      color: ${props => props.theme.colors.text01};
      font-weight: ${props => (props.showGuide ? 700 : 600)};
    }

    .content {
      margin: 4px 0px;
      font-size: 14px;
      text-align: center;
      line-height: 19.6px;
      color: ${props => props.theme.colors.text03};

      .subcontent {
        font-weight: 600;
        line-height: 17.5px;
      }
    }
  }
`;

export const ProfileListSwitch = styled.div`
  height: ${props =>
    !props.secondaryMenu ? "auto" : props.backSwitch ? "60px" : "68px"};
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  ${props =>
    props.backSwitch
      ? css`
          border-bottom: 1px solid ${props => props.theme.colors.grey02};
        `
      : css`
          border-top: 1px solid ${props => props.theme.colors.grey02};
        `}

  .content-wrapper {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: center;
    margin: 10px 6px;
    padding: 8px;
    color: ${props => props.theme.colors.text01};
    ${props =>
      props.backSwitch
        ? css`
            gap: 15px;
            cursor: default;
          `
        : css`
            cursor: pointer;
            justify-content: space-between;
            border-radius: 10px;

            &:hover {
              color: ${props => props.theme.colors.white};
              background: ${props => props.theme.colors.blue};
            }
          `}

    .content {
      display: flex;
      align-items: center;
      gap: 10px;

      .img-wrapper {
        width: 64px;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }

    i {
      font-size: 20px;
    }
  }
`;

export const TransitionView = styled(Box)`
  transition: 0.25s;
  opacity: ${({ state }) => (state === "entered" ? 1 : 0)};
  transform: ${({ state }) =>
    state === "entering"
      ? "translateX(24px)"
      : state === "exiting"
      ? "translateX(-24px)"
      : "translateX(0)"};
  display: ${({ state }) => (state === "exited" ? "none" : "block")};
`;
