import _ from "lodash";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { useToaster } from "@hellocontento/maillard";

import Captions from "./captions";
import Scheduler from "./scheduler";
import Attachment from "./attachment";
import { callApi } from "utils/ContentoApi";
import { SERVICES } from "constants/services";
import StatusSelector from "./statusSelector";
import Tooltip from "components/common/Tooltip";
import ChannelSelector from "./channelSelector";
import CategorySelector from "./categorySelector";
import { Status } from "./statusSelector/constants";
import IconButton from "components/common/IconButton";
import validateAttachments from "./attachment/validation";
import EmptyChannelState from "components/common/EmptyChannelState";
// import NotifyPeopleSelector from "./notifyPeopleSelector";
import { POST_STATUS, ATTACHMENT_TYPES } from "constants/post";
import { trackAnalyticsEvent } from "state/actions/AnalyticsActions";
import {
  SectionLabel,
  ComposerFooter,
  SectionWrapper,
  ComposerContent,
  SelectorWrapper,
  AttachmentToolbar
} from "./styles";
import { createUpdatePost, constructPostData, PostEvent } from "services/post";

function getInitialPost(props) {
  let post;
  if (props.post && !props.post?.isDraft && !props.post?.isPostGroup) {
    const caption = {
      all: props.post.caption,
      [props.post.channel.service]: props.post.caption
    };
    post = Object.assign(
      {},
      props.post,
      { caption },
      {
        channels: [props.post.channel.id],
        status: props.post.status ? props.post.status : POST_STATUS.SCHEDULED
      }
    );
  } else if (props.post && (props.post?.isDraft || props.post?.isPostGroup)) {
    // compose an object to accomodate draft and post group objects
    const all =
      props.post.caption.all ??
      Object.values(props.post.caption).sort((a, b) => {
        return b.length - a.length;
      })[0];
    post = Object.assign(
      {},
      props.post,
      { caption: { ...props.post.caption, all } },
      {
        channels: props.post.channels.map(channel => channel.id),
        status: props.post.status ? props.post.status : POST_STATUS.SCHEDULED
      }
    );
  } else {
    const linkedinProfileChannelCount = props.account.channels.filter(
      c => c.service === "linkedin" && c.serviceType === "profile"
    ).length;

    post = {
      channels:
        props.initialChannelIds ||
        props.account.channels
          .filter(channel => channel.service !== SERVICES.INSTAGRAM)
          .filter(c => {
            return !(
              linkedinProfileChannelCount > 1 &&
              c.service === SERVICES.LINKEDIN &&
              c.serviceType === "profile"
            );
          })
          .map(channel => channel.id),
      caption: {
        all: props.initialContent ? props.initialContent : ""
      },
      status: POST_STATUS.SCHEDULED,
      contentId: props.contentId,
      contentTypeId: props.initialContentTypeId || "educational",
      contentTypeLabel: props.contentTypeLabel,
      postIdea: props.postIdea || null,
      taskId: props.taskId
    };
  }

  return post;
}

function PostComposer(props) {
  const addToast = useToaster();
  const dispatch = useDispatch();

  const [wasSplit, setWasSplit] = useState(false);
  const [post, setPost] = useState(getInitialPost(props));

  const isCombined =
    post.caption.hasOwnProperty("all") &&
    Object.values(post.caption).every(value => value === post.caption.all) &&
    (!post.mentions || post.mentions.length < 1);

  const initialVisibleCaption = !post.id
    ? "all"
    : (post.isDraft || post.isPostGroup) && isCombined
    ? "all"
    : props.account.channels.find(channel => channel.id === post.channels[0])
        .service;
  const [visibleCaption, setVisibleCaption] = useState(initialVisibleCaption);
  const [isPosting, setIsPosting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isScraping, setIsScraping] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});
  const [typeOfAttachment, setTypeOfAttachment] = useState(null);
  const { account, initialScheduleTime, captions } = props;

  useEffect(() => {
    dispatch(trackAnalyticsEvent("Opened Editor"));
  }, [dispatch]);

  const onCaptionChange = (text, service, validationErrors) => {
    const caption = post.caption;
    caption[service] = text;

    if (service === "all" && !wasSplit) {
      const enabledServices = getEnabledServices();
      enabledServices.forEach(service => {
        caption[service] = text;
      });
    }

    const updatedPost = Object.assign(post, { caption });

    setPost(updatedPost);
    setValidationErrors(prevState => {
      return Object.assign({ ...prevState }, validationErrors);
    });
  };

  const onUrlAdded = (url, addToast) => {
    // if we dont need to scrape the article again
    const linkOnly = url === props.content?.url;
    setIsScraping(true);
    return callApi({
      url: "scrape",
      params: {
        url: url,
        accountId: props.account.id,
        linkOnly
      }
    })
      .then(urlInfo => {
        handleContentUpdated(
          ATTACHMENT_TYPES.ARTICLE,
          linkOnly ? { ...props.content, ...urlInfo } : urlInfo
        );
        return urlInfo;
      })
      .catch(error => {
        addToast(
          `We were unable to process the URL: ${error.message?.replace(
            "Failed calling service well-v2 get /scrape with error: ",
            ""
          )}`
        );
      })
      .finally(() => {
        setIsScraping(false);
      });
  };

  const handleContentUpdated = (
    attachmentType,
    content,
    forceUpdate = false
  ) => {
    //Don't replace attachment.
    if (post.attachment && !forceUpdate) {
      setIsUploading(false);
      return;
    }

    const updatedPost = Object.assign({}, post);

    if (attachmentType === ATTACHMENT_TYPES.ARTICLE) {
      if (content.shortUrl || content.url) {
        updatedPost.attachment = {
          type: attachmentType,
          title: content.title,
          description: content.description,
          image: content.image || content.thumbnail,
          url: content.shortUrl || content.url,
          domain: content.domain,
          favicon: content.favicon,
          embeddable: content.embeddable
        };
      } else {
        delete updatedPost.attachment;
      }
    } else if (attachmentType === ATTACHMENT_TYPES.PHOTO) {
      if (content.images && content.images.length > 0) {
        updatedPost.attachment = {
          type: attachmentType,
          url: content.images.map(i => i.url),
          metaData: content.images.map(i => i.metaData)
        };
      } else {
        delete updatedPost.attachment;
      }
    } else if (attachmentType === ATTACHMENT_TYPES.VIDEO) {
      if (content.videoUrl) {
        updatedPost.attachment = {
          type: attachmentType,
          url: content.videoUrl,
          metaData: content.metaData
        };
      } else {
        delete updatedPost.attachment;
      }
    } else {
      delete updatedPost.attachment;
    }

    setPost(updatedPost);
    setIsUploading(false);
  };

  const handleChannelSelected = (selectedChannels, validationErrors) => {
    setPost(prevState => {
      const post = { ...prevState, channels: selectedChannels };

      //Captions should only be set for the enabled channels.
      const enabledServices = getEnabledServices(selectedChannels);

      enabledServices.forEach(service => {
        if (
          post.caption[service] === undefined ||
          post.caption[service] === ""
        ) {
          post.caption[service] = post.caption.all;
        }
      });
      return post;
    });
    setValidationErrors(prevState => {
      return Object.assign({ ...prevState }, validationErrors);
    });
  };

  const handleContentTypeSelected = value => {
    setPost(prevState => {
      return { ...prevState, contentTypeId: value };
    });
  };

  const handleStatusSelected = value => {
    props.setPostStatus(value);
  };

  const handleUploadStart = () => {
    setIsUploading(true);
  };

  const getEnabledServices = selectedChannels => {
    return _.uniq(
      getEnabledServicesWithType(selectedChannels).map(s => s.service)
    );
  };

  const getEnabledServicesWithType = selectedChannels => {
    const channels = selectedChannels ? selectedChannels : post.channels;
    return _.uniq(
      channels.map(id => {
        const { service, serviceType } = props.account.channels.find(
          channel => channel.id === id
        );
        return { service, serviceType };
      })
    );
  };

  const createPost = (
    scheduleTime = "AUTO_SCHEDULE",
    scheduledAt = null,
    fromModal = false
  ) => {
    setIsPosting(true);

    let postData = {
      ...post,
      scheduleTime,
      scheduledAt
    };

    let postEvent = null;
    if (fromModal && !post.id) {
      postEvent = PostEvent.CREATE_DRAFT;
    } else if (props.postStatus === Status.DRAFT) {
      if (postData.isPostGroup) {
        postEvent = PostEvent.POST_GROUP_TO_DRAFT;
      } else if (!post.isDraft && post.id) {
        postEvent = PostEvent.POST_TO_DRAFT;
      } else if (!post.isDraft && !post.id) {
        postEvent = PostEvent.CREATE_DRAFT;
      } else if (post.isDraft && post.id) {
        postEvent = PostEvent.UPDATE_DRAFT;
      }
    } else {
      if (postData.isPostGroup) {
        postEvent = PostEvent.UPDATE_POST_GROUP;
      } else if (post.isDraft && post.id) {
        postEvent = PostEvent.DRAFT_TO_POST;
      } else if (!post.isDraft && post.id) {
        postEvent = PostEvent.UPDATE_POST;
      } else if (!post.isDraft && !post.id) {
        postEvent = PostEvent.CREATE_POST;
      }
    }

    postData = constructPostData(postData);

    createUpdatePost(
      postEvent,
      postData,
      (response, successToast) => {
        addToast(successToast, "success");
        setIsPosting(false);

        if (props.onPosted) {
          const res = {
            ...response,
            isPostedNow: postData.scheduleTime === "NOW"
          };

          if (
            props.postStatus === Status.DRAFT ||
            post.isDraft ||
            (fromModal && !post.id)
          ) {
            res.isDraft = true;
          }
          if (post.isPostGroup) {
            res.isPostGroup = true;
          }

          props.onPosted(res);
        }
      },
      errorToast => {
        setIsPosting(false);
        addToast(errorToast, "error");
      }
    );
  };

  const deletePost = async post => {
    setIsDeleting(true);
    const params = post.isPostGroup
      ? {
          method: "delete",
          url: `/accounts/${account.id}/post-groups/${post.id}`
        }
      : post.isDraft && props.postStatus === Status.DRAFT
      ? {
          method: "delete",
          url: `/accounts/${account.id}/draft-posts/${post.id}`
        }
      : { method: "delete", url: `/posts/${post.id}` };
    await callApi(params);
    await new Promise(resolve => setTimeout(resolve, 1000));
    props.onDeleted(post);
  };

  //Get the name of the enabled profile services
  const enabledServicesWithType = getEnabledServicesWithType();
  const enabledServices = _.uniq(enabledServicesWithType.map(s => s.service));

  const attachmentValidation = validateAttachments(
    account,
    post.attachment,
    setPost,
    enabledServices,
    props.postStatus === Status.DRAFT
  );

  const togglePhotoUpload = () => {
    if (typeOfAttachment === ATTACHMENT_TYPES.PHOTO) {
      setTypeOfAttachment(null);
    } else {
      setTypeOfAttachment(ATTACHMENT_TYPES.PHOTO);
    }
  };

  const toggleVideoUpload = () => {
    if (typeOfAttachment === ATTACHMENT_TYPES.VIDEO) {
      setTypeOfAttachment(null);
    } else {
      setTypeOfAttachment(ATTACHMENT_TYPES.VIDEO);
    }
  };

  const enableCustomizedCaptions = () => {
    if (visibleCaption === "all") {
      setVisibleCaption(enabledServices[0]);
      setWasSplit(true);
    } else {
      setVisibleCaption("all");
    }
  };

  const hasErrors =
    !!Object.values(validationErrors).find(val => !!val) ||
    attachmentValidation.filter(v => v.type === "error").length > 0;

  const isSchedulerDisabled =
    isScraping ||
    isPosting ||
    hasErrors ||
    isUploading ||
    isDeleting ||
    account.channels < 1;

  return (
    <>
      <ComposerContent>
        <Captions
          post={post}
          captions={captions}
          wasSplit={wasSplit}
          accountId={account.id}
          onUrlAdded={onUrlAdded}
          visibleCaption={visibleCaption}
          enabledServices={enabledServices}
          onCaptionChange={onCaptionChange}
          validationErrors={validationErrors}
          setVisibleCaption={setVisibleCaption}
          enableCustomizedCaptions={enableCustomizedCaptions}
        />
        <Attachment
          typeOfAttachment={typeOfAttachment}
          enabledServicesWithType={enabledServicesWithType}
          enabledServices={enabledServices}
          validationMessages={attachmentValidation}
          post={post}
          isScraping={isScraping}
          onContentUpdated={handleContentUpdated}
          onUploadStart={handleUploadStart}
        />
        <SelectorWrapper>
          <SectionWrapper>
            <SectionLabel>Channels</SectionLabel>
            {account.channels < 1 ? (
              <EmptyChannelState callback={props.close} />
            ) : (
              <ChannelSelector
                channels={account.channels}
                disabled={
                  !!(post && post.id && !post.isDraft && !post.isPostGroup)
                }
                selectedChannelIds={post.channels}
                onChannelSelected={handleChannelSelected}
              />
            )}
          </SectionWrapper>
          <SectionWrapper center>
            <SectionLabel>Category</SectionLabel>
            <CategorySelector
              contentTypeId={post.contentTypeId}
              onContentTypeSelected={handleContentTypeSelected}
            />
          </SectionWrapper>
          <SectionWrapper center>
            <SectionLabel>Status</SectionLabel>
            <StatusSelector
              status={props.postStatus}
              onStatusSelected={handleStatusSelected}
            />
          </SectionWrapper>
          {/* // TODO: Notify feature is yet to be implemented */}
          {/* <SectionWrapper center>
          <SectionLabel>Notify</SectionLabel>
          <NotifyPeopleSelector />
        </SectionWrapper> */}
        </SelectorWrapper>
      </ComposerContent>
      <ComposerFooter>
        <AttachmentToolbar>
          <Tooltip title="Add image">
            <IconButton
              size={40}
              iconSize={24}
              icon={
                typeOfAttachment === ATTACHMENT_TYPES.PHOTO
                  ? "icon-image-filled"
                  : "icon-image"
              }
              variant="secondaryLoud"
              onClick={togglePhotoUpload}
            />
          </Tooltip>
          <Tooltip title="Add video">
            <IconButton
              size={40}
              iconSize={24}
              icon={
                typeOfAttachment === ATTACHMENT_TYPES.VIDEO
                  ? "icon-video-filled"
                  : "icon-video"
              }
              variant="secondaryLoud"
              onClick={toggleVideoUpload}
            />
          </Tooltip>
        </AttachmentToolbar>
        <Scheduler
          post={post}
          account={account}
          isPosting={isPosting}
          isDeleting={isDeleting}
          onCloseModal={props.close}
          handlePostDeletion={deletePost}
          handlePostSubmission={createPost}
          isSchedulerDisabled={isSchedulerDisabled}
          initialScheduleTime={initialScheduleTime}
          isDraft={props.postStatus === Status.DRAFT}
          confirmationDialogVisible={props.confirmationDialogVisible}
          setConfirmationDialogVisible={props.setConfirmationDialogVisible}
        />
      </ComposerFooter>
    </>
  );
}

PostComposer.propTypes = {
  account: PropTypes.object.isRequired,
  post: PropTypes.object,
  contentId: PropTypes.string,
  onPosted: PropTypes.func,
  initialChannelIds: PropTypes.array,
  initialScheduleTime: PropTypes.object,
  initialContentTypeId: PropTypes.string,
  contentTypeLabel: PropTypes.string,
  postIdea: PropTypes.object,
  captions: PropTypes.array,
  onDeleted: PropTypes.func.isRequired
};

export default PostComposer;
