import { useSelector } from "react-redux";
import { useToaster } from "@hellocontento/maillard";
import { useCallback, useEffect, useMemo, useState } from "react";

import { tomorrow } from "utils/date";
import { useComposerActions } from "contextApi/composerContext";
import { fetchSavedPosts, deleteSavedPost } from "services/savedPosts";

export interface ISavedPost {
  id: string;
  accountId: string;
  creatorAvatar: string;
  creatorName: string;
  content: string;
  urn: string;
  article: string;
  createdAt: Date;
  updatedAt: Date;
}

const useSavedPosts = () => {
  const addToast = useToaster();
  const account = useSelector<any, any>(state => state.account.data);
  const [isFetchingSavedPosts, setIsFetchingSavedPosts] = useState<boolean>(
    true
  );
  const [savedPosts, setSavedPosts] = useState<ISavedPost[]>([]);
  const createTask = useComposerActions(actions => actions.createTask);
  const openComposer = useComposerActions(actions => actions.openComposer);

  const getSavedPosts = useCallback(async () => {
    if (!!account?.id) {
      try {
        setIsFetchingSavedPosts(true);
        const savedPosts = await fetchSavedPosts();

        setSavedPosts(savedPosts);
      } catch (error) {
        addToast((error as Error).message, "error");
      } finally {
        setIsFetchingSavedPosts(false);
      }
    }
  }, [addToast, account]);

  useEffect(() => {
    getSavedPosts();
  }, [getSavedPosts]);

  const createFromSavedPosts = useCallback(
    (post: ISavedPost, type: "post" | "task") => {
      if (type === "post") {
        openComposer(null, {
          initialContent: `${post.content} https://www.linkedin.com/feed/update/${post.urn}`,
          initialContentTypeId: "educational",
          contentTypeLabel: "News Article",
          isExtensionPost: true
        });
      } else if (type === "task") {
        createTask(tomorrow(), {
          title: `${post.content.trim().slice(0, 30)}${post.content.length >
            30 && "..."}`,
          description: post.content.trim(),
          metaData: {
            isExtensionPost: true
          }
        });
      }
    },
    [createTask, openComposer]
  );

  const removeSavedPost = useCallback(
    async (urn: string) => {
      if (!!account?.id) {
        try {
          await deleteSavedPost(urn);

          setSavedPosts(savedPosts =>
            savedPosts.filter(post => post.urn !== urn)
          );
        } catch (error) {
          addToast((error as Error).message, "error");
        }
      }
    },
    [account, addToast]
  );

  return useMemo(
    () => ({
      savedPosts,
      createFromSavedPosts,
      removeSavedPost,
      isFetchingSavedPosts
    }),
    [savedPosts, createFromSavedPosts, isFetchingSavedPosts, removeSavedPost]
  );
};

export default useSavedPosts;
