import React, { useState } from "react";

import { Image } from "rebass/styled-components";

import TASK_TYPES from "../constants/taskType";
import TasksIcon from "assets/images/icon-tasks.png";
import TaskIcon from "assets/images/icon-task.png";

import Button from "components/common/Button";
import { RadioButtonGroup, ButtonGroup } from "../styles";
import RadioButton from "components/common/form/RadioButton";
import { ContentoModal } from "components/modals/ContentoModal";

const TaskConfirmationModal = ({
  isOpen = false,
  toggle,
  title,
  description,
  buttonProps,
  type = TASK_TYPES.INSTANCE,
  showOptions
}) => {
  const [mode, setMode] = useState(TASK_TYPES.INSTANCE);

  const handleClick = e => {
    e.stopPropagation();
    buttonProps.action(mode);
    toggle();
  };

  if (!isOpen) return null;

  return (
    <ContentoModal
      maxWidth={420}
      title={title}
      description={description}
      onClose={toggle}
    >
      {!showOptions ? null : (
        <RadioButtonGroup>
          <RadioButton
            type="descriptive"
            label="This task"
            name="taskOption"
            checked={mode === TASK_TYPES.INSTANCE}
            value={TASK_TYPES.INSTANCE}
            gap={20}
            marginLeft={-10}
            onChange={e => {
              e.stopPropagation();
              setMode(e.target.value);
            }}
          >
            <Image src={TaskIcon} width={48} height={40} />
          </RadioButton>
          <RadioButton
            type="descriptive"
            label="All tasks"
            name="taskOption"
            value={TASK_TYPES.SERIES}
            gap={20}
            marginLeft={-10}
            checked={mode === TASK_TYPES.SERIES}
            onChange={e => {
              e.stopPropagation();
              setMode(e.target.value);
            }}
            disabled={type === TASK_TYPES.INSTANCE}
          >
            <Image src={TasksIcon} width={48} height={40} />
          </RadioButton>
        </RadioButtonGroup>
      )}
      <ButtonGroup>
        <Button size={"m"} variant={"secondary"} onClick={toggle}>
          Cancel
        </Button>
        <Button size={"m"} variant={buttonProps.variant} onClick={handleClick}>
          {buttonProps.label}
        </Button>
      </ButtonGroup>
    </ContentoModal>
  );
};

export default TaskConfirmationModal;
