import { Box, Flex } from "rebass";
import { connect } from "react-redux";
import styled from "styled-components";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { useToaster } from "@hellocontento/maillard";

import Button from "../common/Button";
import { Headline3 } from "../common/styles";
import * as modalActions from "state/actions/ModalActions";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import { PageList, Page, PageName, PageIcon, Notice } from "./styles";

const Heading = styled(Headline3)`
  text-align: center;
  margin: 16px 0 46px;
`;

const InstagramPageList = ({
  pages,
  onClose,
  history,
  account,
  openModal,
  connectPages
}) => {
  const addToast = useToaster();

  let defaultSelectedPageIds = pages.map(page => page.id);
  if (pages.length > 5) {
    defaultSelectedPageIds = [];
  }

  const [selectedPageIds, setSelectedPageIds] = useState(
    defaultSelectedPageIds
  );

  const handleToggle = pageId => {
    let newSelectedPages = [];
    if (selectedPageIds.includes(pageId)) {
      newSelectedPages = selectedPageIds.filter(
        selectedPageId => selectedPageId !== pageId
      );
    } else {
      newSelectedPages = selectedPageIds.concat([pageId]);
    }
    setSelectedPageIds(newSelectedPages);
  };

  const handleAddPage = () => {
    const selectedPages = pages.filter(page =>
      selectedPageIds.includes(page.id)
    );
    if (selectedPages.length === 0) {
      return addToast("Please select one page at least.", "error");
    }
    connectPages(selectedPages);
    if (
      !account.onboardingProgress.includes(OnboardingStepsEnum.CONNECT_CHANNEL)
    ) {
      openModal("ONBOARDING_INFO_MODAL", {
        id: OnboardingStepsEnum.CONNECT_CHANNEL,
        triggeredBy: OnboardingStepsEnum.CONNECT_CHANNEL
      });
    }

    onClose();
  };

  return (
    <Box>
      {pages.length < 1 ? (
        <Heading>Instagram pages not available for the profile</Heading>
      ) : (
        <>
          <Heading>Select the Instagram accounts you want to add</Heading>
          <PageList>
            {pages.map(page => (
              <InstagramPage
                key={page.id}
                page={page}
                selected={selectedPageIds.includes(page.id)}
                onToggle={() => handleToggle(page.id)}
              />
            ))}
          </PageList>
        </>
      )}
      <Notice></Notice>
      <Flex justifyContent="center">
        <Button mr="12px" variant="secondary" size="lg" onClick={onClose}>
          {pages.length < 1 ? "Close" : "Cancel"}
        </Button>
        {pages.length > 0 && (
          <Button size="lg" onClick={handleAddPage}>
            Add Page
          </Button>
        )}
      </Flex>
    </Box>
  );
};

const InstagramPage = ({ page, selected, onToggle }) => {
  return (
    <Page>
      <PageIcon src={page.profile_picture_url} height={36} width={36} />
      <PageName>
        <label htmlFor={`checkbox-${page.id}`}>{page.username}</label>
      </PageName>
      <div className="mdc-checkbox">
        <input
          type="checkbox"
          className="mdc-checkbox__native-control"
          id={`checkbox-${page.id}`}
          defaultChecked={selected}
          onChange={onToggle}
        />
        <div className="mdc-checkbox__background">
          <svg className="mdc-checkbox__checkmark" viewBox="0 0 24 24">
            <path
              className="mdc-checkbox__checkmark-path"
              fill="none"
              d="M1.73,12.91 8.1,19.28 22.79,4.59"
            />
          </svg>
          <div className="mdc-checkbox__mixedmark"></div>
        </div>
      </div>
    </Page>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default withRouter(
  connect(mapStateToProps, {
    openModal: modalActions.openModal
  })(InstagramPageList)
);
