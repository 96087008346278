import numeral from "numeral";

numeral.register('locale', 'eur', {
  delimiters: {
      thousands: '.',
      decimal: ','
  }
});

numeral.register('locale', 'usd', {
  delimiters: {
      thousands: ',',
      decimal: '.'
  }
});

const currencies = {
  USD: "$",
  EUR: "€"
};

const FormattedPrice = ({ price, currency = "USD", rounded = false }) => {
  if (currency === "USD") numeral.locale('usd')
  else numeral.locale('eur')

  let currencyFormat = '0,0.00';
  if (rounded) currencyFormat = '0,0'

  return `${numeral(price).format(`${currencyFormat}`)} ${currencies[currency.toUpperCase()]}`;
};

export default FormattedPrice;
