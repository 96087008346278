import "react-app-polyfill/stable";
import * as Sentry from "@sentry/browser";

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./state/store";

if (process.env.REACT_APP_ENV === "production") {
  Sentry.init({
    dsn: "https://aef88245133f4b3f8ed1f9f1a2688491@sentry.io/1459655",
    environment: process.env.REACT_APP_ENV,
    release: process.env.REACT_APP_VERSION
  });
}

ReactDOM.render(
  <Provider store={store}>
    {/* @ts-ignore */}
    <ConnectedRouter history={history}>
      {/* @ts-ignore */}
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root")
);
