import store from "state/store";
import {
  IVote,
  ISubscription,
  ITopicalCalendar,
  ITopicalCalendarEvent
} from "utils/useTopicalCalendars";
import { endOf, today } from "utils/date";
import { callApi } from "utils/ContentoApi";

const parseTopicalCalendarEvent = (
  topicalCalendarEvent
): ITopicalCalendarEvent => {
  const {
    startsAt,
    endsAt,
    createdAt,
    updatedAt,
    ...rest
  } = topicalCalendarEvent;

  return {
    ...rest,
    startsAt: new Date(startsAt),
    endsAt: endsAt ? new Date(endsAt) : endOf(new Date(startsAt), "day"),
    createdAt: new Date(createdAt),
    updatedAt: new Date(updatedAt)
  };
};

const parseTopicalCalendar = (topicalCalendar): ITopicalCalendar => {
  const {
    createdAt,
    updatedAt,
    calendars_events: calendarsEvents,
    ...rest
  } = topicalCalendar;

  const parsedCalendarEvents: ITopicalCalendarEvent[] = calendarsEvents
    .map(ce => parseTopicalCalendarEvent(ce))
    .sort(
      (a: ITopicalCalendarEvent, b: ITopicalCalendarEvent) =>
        a.startsAt.getTime() - b.startsAt.getTime()
    );

  return {
    ...rest,
    createdAt: new Date(createdAt),
    updatedAt: new Date(updatedAt),
    startDate: parsedCalendarEvents[0]?.startsAt ?? today(),
    endDate:
      parsedCalendarEvents.sort(
        (a: ITopicalCalendarEvent, b: ITopicalCalendarEvent) =>
          b.endsAt.getTime() - a.endsAt.getTime()
      )[0]?.endsAt ?? today(),
    calendarsEvents: parsedCalendarEvents
  };
};

export const fetchTopicalCalendars = async (): Promise<ITopicalCalendar[]> => {
  const account = store.getState().account.data;

  try {
    const topicalCalendars: ITopicalCalendar[] = await callApi({
      method: "get",
      url: `/accounts/${account.id}/calendars/recommended`
    });

    return topicalCalendars.map(tc => parseTopicalCalendar(tc));
  } catch (error) {
    throw new Error(`Topical Calendars could not be fetched.`);
  }
};

export const fetchSubscriptions = async (): Promise<any> => {
  const account = store.getState().account.data;

  try {
    const { actions } = await callApi({
      method: "get",
      url: `/accounts/${account.id}/calendars/actions`
    });

    return actions;
  } catch (error) {
    throw new Error(`Calendar subscriptions could not be fetched.`);
  }
};

export const upvoteCalendar = async (
  topicalCalendarId: string
): Promise<{ vote: IVote; subscription: ISubscription }> => {
  const account = store.getState().account.data;

  try {
    const {
      vote_and_subscription: { vote, subscription }
    } = await callApi({
      method: "post",
      url: `/calendars/${topicalCalendarId}/actions/${account.id}`
    });

    return { vote, subscription };
  } catch (error) {
    throw new Error(`Vote change could not be registered.`);
  }
};

export const downvoteCalendar = async (
  topicalCalendarId: string
): Promise<void> => {
  const account = store.getState().account.data;

  try {
    await callApi({
      method: "delete",
      url: `/calendars/${topicalCalendarId}/actions/${account.id}`
    });
  } catch (error) {
    throw new Error(`Vote change could not be registered.`);
  }
};
