import { Flex } from "rebass";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import React, { useMemo, useState } from "react";

import {
  Flair,
  FocusInfo,
  ActionButton,
  FlairWrapper,
  SessionImage,
  SessionWrapper,
  OnboardingLayout,
  ActionButtonGroup,
  OnboardingListWrapper
} from "./styles";
import OnboardingItem from "./OnboardingItem";
import { skipStep } from "services/onboarding";
import { useAppState } from "contextApi/appContext";
import Loader from "components/common/loading/Loader";
import { OnboardingStepsEnum } from "types/Onboarding.types";
import { telenetCoachingAddonMapping } from "constants/index";
import { useComposerActions } from "contextApi/composerContext";
import PersonalGrowthExpert from "assets/images/dashboard/personal-growth-expert.png";

const OnboardingList: React.FC<{}> = () => {
  const client = useAppState(state => state.clientSettings.client);
  const onboardingVideoSettings = useAppState(
    state => state.clientSettings.onboardingVideoSettings
  );
  const isTelenetClient = client === "telenet";
  const openComposer = useComposerActions(actions => actions.openComposer);
  const [isSkipping, setIsSkipping] = useState<boolean>(false);
  const account = useSelector((state: any) => state.account.data);
  const history = useHistory();

  const connect_channel = account.onboardingProgress.includes(
    OnboardingStepsEnum.CONNECT_CHANNEL
  );
  const create_content_calendar = account.onboardingProgress.includes(
    OnboardingStepsEnum.CREATE_CONTENT_CALENDAR
  );
  const follow_section = account.onboardingProgress.includes(
    OnboardingStepsEnum.FOLLOW_SECTION
  );
  const like_article = account.onboardingProgress.includes(
    OnboardingStepsEnum.LIKE_ARTICLE
  );
  const get_personalized_suggestions = account.onboardingProgress.includes(
    OnboardingStepsEnum.GET_PERSONALIZED_SUGGESTIONS
  );
  const create_post = account.onboardingProgress.includes(
    OnboardingStepsEnum.CREATE_POST
  );
  const invite_team_member = account.onboardingProgress.includes(
    OnboardingStepsEnum.INVITE_TEAM_MEMBER
  );

  const isTialOrSmallBiz = useMemo(
    () =>
      account?.billing?.willowPlan === "SMALL_BIZ" ||
      account?.billing?.billingStatus === "in_trial",
    [account]
  );

  const navigateTo = (url: string) => {
    history.push(`/accounts/${account.id}/${url}`);
  };

  const STEPS = [
    {
      id: "channel_connection",
      title: "Connect your channels",
      description: (
        <p>
          Welcome to Willow, your new Social Media Management Solution! Connect
          your social media channels to Willow in order to receive analytics and
          publish to all your socials at once.
        </p>
      ),
      conditions: [OnboardingStepsEnum.CONNECT_CHANNEL],
      isCompleted: connect_channel,
      completed: +!connect_channel,
      timeToCompleteInMinutes: 5,
      cta: (
        // @ts-ignore
        <ActionButton
          variant="primary"
          onClick={() => navigateTo("settings/channels")}
        >
          Connect
        </ActionButton>
      ),
      tutorialUrl: onboardingVideoSettings.linkId,
      tutorialTimestamp:
        onboardingVideoSettings.timeStamps["channel_connection"] || 0
    },
    {
      id: "content_calendar",
      title: "Create a content calendar",
      description: (
        <p>
          Posting consistently and having a nice variety is the key to starting
          the social media engine. With a clear content plan, you’re already
          halfway there.
        </p>
      ),
      isCompleted: create_content_calendar,
      conditions: [OnboardingStepsEnum.CREATE_CONTENT_CALENDAR],
      completed: +!create_content_calendar,
      timeToCompleteInMinutes: 5,
      cta: (
        // @ts-ignore
        <ActionButton
          variant="primary"
          onClick={() => navigateTo("schedule/month?taskId=new")}
        >
          Create a calendar
        </ActionButton>
      ),
      tutorialUrl: onboardingVideoSettings.linkId,
      tutorialTimestamp:
        onboardingVideoSettings.timeStamps["content_calendar"] || 0
    },
    {
      id: "news_feed",
      title: "Setup your news feed",
      description: (
        <p>
          The personal newsroom helps you find news articles relevant to your
          industry and followers and makes posting them to your social media
          channels very easy.
        </p>
      ),
      isCompleted:
        follow_section && like_article && get_personalized_suggestions,
      conditions: [
        OnboardingStepsEnum.FOLLOW_SECTION,
        OnboardingStepsEnum.LIKE_ARTICLE,
        OnboardingStepsEnum.GET_PERSONALIZED_SUGGESTIONS
      ],
      completed:
        +!follow_section + +!like_article + +!get_personalized_suggestions,
      timeToCompleteInMinutes: 5,
      cta: (
        // @ts-ignore
        <ActionButton
          variant="primary"
          onClick={() => navigateTo("content/for-you")}
        >
          Setup newsfeed
        </ActionButton>
      ),
      tutorialUrl: onboardingVideoSettings.linkId,
      tutorialTimestamp: onboardingVideoSettings.timeStamps["news_feed"] || 0
    },
    {
      id: "schedule_post",
      title: "Schedule your first post",
      description: (
        <p>
          Creating a post and publishing it to all your social media channels in
          one click is very easy with Willow.
        </p>
      ),
      conditions: [OnboardingStepsEnum.CREATE_POST],
      isCompleted: create_post,
      completed: +!create_post,
      timeToCompleteInMinutes: 5,
      cta: (
        // @ts-ignore
        <ActionButton variant="primary" onClick={() => openComposer()}>
          Create post
        </ActionButton>
      ),
      tutorialUrl: onboardingVideoSettings.linkId,
      tutorialTimestamp:
        onboardingVideoSettings.timeStamps["schedule_post"] || 0
    },
    {
      id: "invite_members",
      title: "Invite team members",
      isCompleted: invite_team_member,
      hide: !isTialOrSmallBiz,
      completed: +!invite_team_member,
      conditions: [OnboardingStepsEnum.INVITE_TEAM_MEMBER],
      description: (
        <p>
          We highly recommend inviting other team members to join your Willow
          account. It’s the best way to grow your impressions and following.
        </p>
      ),
      timeToCompleteInMinutes: 5,
      cta: (
        <ActionButtonGroup>
          {/* @ts-ignore */}
          <ActionButton
            variant="primary"
            onClick={() => navigateTo("settings/team")}
          >
            Start inviting
          </ActionButton>
          {!invite_team_member && (
            // @ts-ignore
            <ActionButton
              variant="secondary"
              disabled={isSkipping}
              onClick={async (e: React.MouseEvent<HTMLButtonElement>) => {
                e.stopPropagation();
                try {
                  setIsSkipping(true);
                  await skipStep(OnboardingStepsEnum.INVITE_TEAM_MEMBER);
                } catch (_) {
                } finally {
                  setIsSkipping(false);
                }
              }}
            >
              <Flex flexDirection={"row"} alignItems="center">
                {isSkipping && <Loader size={18} />}
                Skip step
              </Flex>
            </ActionButton>
          )}
        </ActionButtonGroup>
      ),
      tutorialUrl: onboardingVideoSettings.linkId,
      tutorialTimestamp:
        onboardingVideoSettings.timeStamps["invite_members"] || 0
    }
  ];

  const onboardingSteps = isTelenetClient
    ? STEPS.filter(step => !["news_feed", "invite_members"].includes(step.id))
    : STEPS;

  const nextStep = onboardingSteps.find(item => !item.isCompleted);

  const [activeItem, setActiveItem] = useState<string>(
    !!nextStep ? nextStep.id : ""
  );

  const telenetOnboardingAddon =
    account.isTelenetCustomer && !account.billing?.willowCoachingPlan
      ? null
      : (!!Object.entries(telenetCoachingAddonMapping["EUR"]).find(
          ([k, p]) =>
            p.toLowerCase() ===
              account.billing?.willowCoachingPlan?.toLowerCase() &&
            k === "LIGHT"
        ) &&
          "LIGHT") ||
        (!!Object.entries(telenetCoachingAddonMapping["EUR"]).find(
          ([k, p]) =>
            p.toLowerCase() ===
              account.billing?.willowCoachingPlan?.toLowerCase() &&
            k === "PREMIUM"
        ) &&
          "PREMIUM") ||
        null;

  const handleOpenSessionBooking = () => {
    if (!isTelenetClient) {
      window.open(
        "https://meetings.hubspot.com/rhaes/free-social-media-consult",
        "_blank"
      );
    } else {
      if (telenetOnboardingAddon === "LIGHT") {
        window.open(
          "https://meetings.hubspot.com/rhaes/free-social-media-consult",
          "_blank"
        );
      } else if (telenetOnboardingAddon === "PREMIUM") {
        window.open(
          "https://meetings.hubspot.com/rhaes/free-social-media-consult",
          "_blank"
        );
      } else {
        window.open("https://meetings.hubspot.com/brent-schepers", "_blank");
      }
    }
  };

  return (
    <OnboardingLayout>
      <OnboardingListWrapper>
        {onboardingSteps.map((item, idx) => {
          if (item.hide) return null;
          return (
            <OnboardingItem
              key={item.id}
              item={item}
              isFirst={idx === 0}
              setActiveItem={setActiveItem}
              isCompleted={item.isCompleted}
              isActive={item.id === activeItem}
              isNextStep={item.id === nextStep?.id}
            />
          );
        })}
      </OnboardingListWrapper>
      <SessionWrapper onClick={handleOpenSessionBooking}>
        <FlairWrapper>
          {!isTelenetClient && <Flair variant={"primary"}>Free</Flair>}
          <Flair variant={"secondary"}>
            {isTelenetClient
              ? telenetOnboardingAddon === "LIGHT"
                ? "Light onboarding call"
                : telenetOnboardingAddon === "PREMIUM"
                ? "Premium onboarding call"
                : "Onboarding call"
              : "30 minutes"}
          </Flair>
        </FlairWrapper>
        <Flex width={"100%"} justifyContent={"center"}>
          <SessionImage src={PersonalGrowthExpert}></SessionImage>
        </Flex>
        <FocusInfo>
          {isTelenetClient
            ? "Book your onboarding session with our experts today!"
            : "Book your free strategy session with our experts"}
        </FocusInfo>
      </SessionWrapper>
    </OnboardingLayout>
  );
};

export default OnboardingList;
