import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

export const CheckBoxWrapper = styled.div`
  position: relative;
`;

export const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 36px;
  height: 18px;
  border-radius: 10px;
  margin: 3px 3px 3px 4px;
  background: ${props => props.theme.colors.grey03};
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  cursor: pointer;
  &:hover {
    background: ${props => props.theme.colors.grey04};
  }
  &:focus {
    box-shadow: 0 0 2px ${props => props.theme.colors.background1},
      0 0 0 2px ${props => props.theme.colors.background1},
      0 0 0 3px ${props => props.theme.colors.iconFill4} !important;
    transition: box-shadow 0.2s ease;
  }
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin: 2px;
    background: ${props => props.theme.colors.white};
    transition: 0.2s;
  }
`;

export const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 15px;
  width: ${props => props.size * 2}px;
  height: ${props => props.size}px;

  &:checked + ${CheckBoxLabel} {
    background: ${props => props.theme.colors.blue};
    &:hover {
      background: #0059e2;
    }
    &:focus {
      box-shadow: 0 0 2px ${props => props.theme.colors.background1},
        0 0 0 2px ${props => props.theme.colors.background1},
        0 0 0 3px ${props => props.theme.colors.iconFill4} !important;
      transition: box-shadow 0.2s ease;
    }
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 14px;
      height: 14px;
      margin-left: 20px;
      transition: 0.2s;
    }
  }
`;

const Switch = ({
  htmlFor,
  checked,
  onToggle,
  disabled = false,
  size = 18
}) => {
  return (
    <CheckBoxWrapper>
      <CheckBox
        size={size}
        type="checkbox"
        checked={checked}
        disabled={disabled}
        onChange={onToggle}
        id={`checkbox-${htmlFor}`}
      />
      <CheckBoxLabel htmlFor={`checkbox-${htmlFor}`} disabled={disabled} />
    </CheckBoxWrapper>
  );
};

Switch.propTypes = {
  size: PropTypes.number,
  disabled: PropTypes.bool,
  checked: PropTypes.bool.isRequired,
  onToggle: PropTypes.func.isRequired,
  htmlFor: PropTypes.string.isRequired
};

export default Switch;
