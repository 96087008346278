import React from "react";
import { Box } from "rebass";
import styled from "styled-components";
import ExploreSource from "../widgets/ExploreSource";
import { Headline2 } from "../../common/styles";
import { follow, unfollow, followExternalSources } from "services/news";

const ExploreSourceGrid = styled(Box)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px 24px;
`;

export default function FollowingSources({ followingSources }) {
  const addTwitterSource = async source => {
    const twitterSubscribeData = {
      type: "twitter",
      handle: source.handle
    };

    try {
      await followExternalSources(twitterSubscribeData);
    } catch (_) {}
  };

  const addSource = async source => {
    try {
      await follow("domains", source.id);
    } catch (_) {}
  };

  const deleteSource = async source => {
    try {
      if (source.domain) {
        await unfollow("domains", source.id);
      } else {
        await unfollow("sources", source.id);
      }
    } catch (_) {}
  };

  return (
    <Box>
      <Box my={32}>
        {followingSources.filter(source => source.type === "twitter").length >
          0 && <Headline2>Following Twitter Accounts</Headline2>}
        <ExploreSourceGrid>
          {followingSources
            .filter(source => source.type === "twitter")
            .map(source => (
              <ExploreSource
                data={source}
                type={source.type}
                key={source.image}
                onFollowSource={addTwitterSource}
                onUnfollowSource={deleteSource}
              />
            ))}
        </ExploreSourceGrid>
      </Box>
      <Box my={32}>
        {followingSources.filter(source => source.type !== "twitter").length >
          0 && <Headline2>Following Sources</Headline2>}
        <ExploreSourceGrid>
          {followingSources
            .filter(source => source.type !== "twitter")
            .map(source => (
              <ExploreSource
                data={source}
                type={source.type}
                key={source.image}
                onFollowSource={addSource}
                onUnfollowSource={deleteSource}
              />
            ))}
        </ExploreSourceGrid>
      </Box>
    </Box>
  );
}
