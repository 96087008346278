import React from "react";

import { useAppState } from "contextApi/appContext";
import { NavItemList, NavItem, NavItemIcon } from "./styles";

const MainNavigation = React.memo(({ accountId, wasClicked }) => {
  const client = useAppState(state => state.clientSettings.client);

  return (
    <NavItemList onClick={wasClicked}>
      <NavItem to={`/accounts/${accountId}/dashboard`}>
        <NavItemIcon className={"header-icon-homepage"} />
        <label>Home</label>
      </NavItem>
      <NavItem to={`/accounts/${accountId}/schedule`}>
        <NavItemIcon className={"header-icon-calendar"} />
        <label>Calendar</label>
      </NavItem>
      <NavItem to={`/accounts/${accountId}/ideas`}>
        <NavItemIcon className={"header-icon-ideas"} />
        <label>Ideas</label>
      </NavItem>
      {client !== "telenet" && (
        <NavItem to={`/accounts/${accountId}/content`}>
          <NavItemIcon className={"header-icon-news"} />
          <label>News</label>
        </NavItem>
      )}

      <NavItem to={`/accounts/${accountId}/analytics`}>
        <NavItemIcon className={"header-icon-analytics"} />
        <label>Analytics</label>
      </NavItem>
    </NavItemList>
  );
});

export default MainNavigation;
