import React from "react";
import styled, { css } from "styled-components";

const IconPlaceholder = styled.span`
  display: inline-block;

  ${props => {
    switch (props.variant) {
      case "light":
        return css`
          color: ${props.theme.colors.text04};
        `;
      case "warning":
        return css`
          color: ${props.theme.colors.yellow};
        `;
      case "progress":
        return css`
          color: ${props.theme.colors.orange};
        `;
      case "lg":
        return css`
          font-size: 24px;
          margin-right: 0px;
        `;
      default:
        return css`
          font-size: 18px;
          margin-right: 6px;
        `;
    }
  }};

  ${props => {
    switch (props.size) {
      case "sm":
        return css`
          font-size: 14px;
          margin-right: 0px;
        `;
      case "md":
        return css`
          font-size: 16px;
          margin-right: 0px;
        `;
      case "lg":
        return css`
          font-size: 24px;
          margin-right: 0px;
        `;
      default:
        return css`
          font-size: 18px;
          margin-right: 6px;
        `;
    }
  }};
`;

function Icon({ icon, size, variant }) {
  return <IconPlaceholder variant={variant} size={size} className={icon} />;
}

export default Icon;
