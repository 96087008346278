import React from "react";
import { ShowMoreWrapper, ShowMoreButton } from "./styles";

import { ToolTip } from "components/schedule/common/styles";
interface IShowMoreProps {
  id: string;
  info: string;
  showAll: boolean;
  collapsed: boolean;
  remainingCount: number;
  onClick: (e: any) => void;
}

const ShowMore: React.FC<IShowMoreProps> = ({
  id,
  info,
  showAll,
  onClick,
  collapsed,
  remainingCount
}) => {
  return (
    <ShowMoreWrapper collapsed={collapsed}>
      <ShowMoreButton
        onClick={onClick}
        collapsed={collapsed}
        data-tip
        data-for={id}
      >
        {!collapsed && (
          <span className="label">{`Show ${
            !showAll ? remainingCount + " more" : "less"
          }`}</span>
        )}
      </ShowMoreButton>
      {collapsed && (
        <ToolTip id={id} place="right" effect="solid" className="tooltip">
          {info}
        </ToolTip>
      )}
    </ShowMoreWrapper>
  );
};

export default ShowMore;
