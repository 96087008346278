import styled from "styled-components";

import videoPlayIcon from "assets/images/timeline/video-play.png";

export const PostAttachmentVideo = styled.div`
  width: 100%;
`;

export const AttachmentVideo = styled.div`
  display: block;
  width: 100%;
  position: relative;
  height: ${props => props.height ?? 275}px;
  border-radius: ${props => (props.height ? "8px" : "0")};
  overflow: hidden;
`;

export const PostVideo = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: auto;
  border-radius: 0;
  outline: none;
  object-fit: cover;
`;

export const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background: rgba(0, 0, 0, 0.3);
  }
`;

export const VideoPlay = styled.div`
  background: url("${videoPlayIcon}") no-repeat center center;
  background-size: cover;
  width: 62px;
  height: 62px;
  cursor: pointer;
`;
