import React, { useState } from "react";
import useWindowSize from "../../utils/useWindowSize";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Flex } from "rebass/styled-components";
import { Button, ButtonToggle } from "./styles";

import { CALENDAR_VIEW, KEY_LAST_CALENDAR_VIEW } from "constants/calendar";
import * as storageService from "utils/storage";

function ScheduleViewToggle({ account, defaultValue }) {
  const history = useHistory();
  const windowSize = useWindowSize();
  const [currentActive, setCurrentActive] = useState(defaultValue);

  const handleToggleClick = value => {
    setCurrentActive(value);
    storageService.set(KEY_LAST_CALENDAR_VIEW, value);
    history.push(`/accounts/${account.id}/schedule/${value}`);
  };

  if (windowSize.width < 768) {
    return null;
  }

  return (
    <Flex justifyContent="center">
      <ButtonToggle maxOptions={3}>
        <Button
          isActive={currentActive === CALENDAR_VIEW.DAY}
          onClick={() => handleToggleClick(CALENDAR_VIEW.DAY)}
        >
          Day
        </Button>
        <Button
          isActive={currentActive === CALENDAR_VIEW.WEEK}
          onClick={() => handleToggleClick(CALENDAR_VIEW.WEEK)}
        >
          Week
        </Button>
        <Button
          isActive={currentActive === CALENDAR_VIEW.MONTH}
          onClick={() => handleToggleClick(CALENDAR_VIEW.MONTH)}
        >
          Month
        </Button>
      </ButtonToggle>
    </Flex>
  );
}

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {})(ScheduleViewToggle);
