import React from "react";
import { IDomain, ISource, ITopic } from "services/news";
import EmptySearchState from "../EmptySearchState";
import SourceList from "../sources/SourceList";
import TopicList from "../topics/TopicList";

interface IAllSearchResultsProps {
  hasResults: boolean;
  topics: Array<ITopic>;
  domains: Array<IDomain>;
  influencers: Array<ISource>;
  otherSources: Array<ISource>;
  searchContext: string;
  changeSearchContext: (goto: string) => void;
}

const AllSearchResults: React.FC<IAllSearchResultsProps> = ({
  topics,
  domains,
  hasResults,
  influencers,
  otherSources,
  searchContext,
  changeSearchContext
}) => {
  return hasResults ? (
    <>
      {topics.length > 0 && (
        <TopicList
          topics={topics}
          searchContext={searchContext}
          changeSearchContext={() => changeSearchContext("topics")}
        />
      )}
      {domains.length > 0 && (
        <SourceList
          type="domains"
          title="Websites"
          sources={domains}
          searchContext={searchContext}
          changeSearchContext={() => changeSearchContext("domains")}
        />
      )}
      {influencers.length > 0 && (
        <SourceList
          type="twitter"
          title="Twitter Influencers"
          sources={influencers}
          searchContext={searchContext}
          changeSearchContext={() => changeSearchContext("twitter-sources")}
        />
      )}
      {otherSources.length > 0 && (
        <SourceList
          type="rss"
          title="RSS Feeds"
          sources={otherSources}
          searchContext={searchContext}
          changeSearchContext={() => changeSearchContext("rss-sources")}
        />
      )}
    </>
  ) : (
    <EmptySearchState />
  );
};

export default AllSearchResults;
