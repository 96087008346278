import { Link } from "react-router-dom";
import styled, { css } from "styled-components";

export default styled(Link)<{ underline?: boolean }>`
  text-decoration: none;
  color: inherit;
  &:hover {
    text-decoration: ${props => (props.underline ? "underline" : "")};
  }
`;

export const NativeLink = styled.a<{
  bold?: boolean;
  underline?: boolean;
  hasError?: boolean;
}>`
  text-decoration: none;
  color: ${props =>
    props.hasError ? props.theme.colors.red : props.theme.colors.blue};
  display: inline-flex;
  cursor: pointer;

  ${props =>
    props.bold &&
    css`
      font-weight: 600;
    `}

  &:hover {
    color: ${props => props.theme.colors.linkBlueHover};
    text-decoration: ${props => (props.underline ? "underline" : "")};
  }
  &:active {
    color: ${props => props.theme.colors.linkBlueActive};
    text-decoration: ${props => (props.underline ? "underline" : "")};
  }
`;
