import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import { connectRouter } from "connected-react-router";

import authReducer from "./AuthReducer";
import scheduledPostReducer from "./ScheduledPostReducer";
import accountReducer from "./AccountReducer";
import modalReducer from "./ModalReducer";
import layoutReducer from "./LayoutReducer";
import activityReducer from "./ActivityReducer";
import contentReducer from "./ContentReducer";
import draftReducer from "./DraftReducer";
import dragDropReducer from "./DragDropReducer";
import * as authActions from "../actions/AuthActions";
import regionFilterReducer from "./RegionFilterReducer";

const reducers = {
  auth: authReducer,
  scheduledPosts: scheduledPostReducer,
  account: accountReducer,
  modals: modalReducer,
  layout: layoutReducer,
  activity: activityReducer,
  draft: draftReducer,
  form: formReducer,
  regionFilter: regionFilterReducer,
  dragDrop: dragDropReducer,
  content: contentReducer
};

export function createReducers(history) {
  const appReducer = combineReducers({
    router: connectRouter(history),
    ...reducers
  });

  //Use a root reducer to be able to reset the state of everything on logout.
  //https://stackoverflow.com/a/35641992
  return (state = {}, action) => {
    if (action.type === authActions.LOGOUT) {
      state = undefined;
    }
    return appReducer(state, action);
  };
}
