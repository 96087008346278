import _ from "lodash";
import React from "react";

import {
  ToolkitTools,
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import ImageSuggestion from "./ImageSuggestion";
import CaptionSuggestion from "./CaptionSuggestion";
import IconButton from "components/common/IconButton";
import { ToolkitContainer, Toolbar, ToolContent, ToolIcon } from "./styles";

interface ITool {
  order: number;
  icon: string;
  activeIcon: string;
  component: React.ComponentType;
}

const TOOLS: { [key in ToolkitTools]: ITool } = {
  captionSuggestion: {
    order: 0,
    icon: "icon-draft",
    activeIcon: "icon-draft-filled",
    component: CaptionSuggestion
  },
  imageSuggestion: {
    order: 1,
    icon: "icon-image",
    activeIcon: "icon-image-filled",
    component: ImageSuggestion
  }
};

const Toolkit: React.FC<{}> = () => {
  const activeToolkit = useComposerState(state => state.activeToolkit);
  const newImagesIndication = useComposerState(
    state => state.suggestedImages.newImagesIndication
  );
  const setActiveToolkit = useComposerActions(
    actions => actions.setActiveToolkit
  );

  const ActiveContent: any = TOOLS[activeToolkit].component;

  return (
    <ToolkitContainer>
      <Toolbar>
        {(Object.keys(TOOLS) as (keyof typeof TOOLS)[])
          .sort((a, b) => TOOLS[a].order - TOOLS[b].order)
          .map(key => (
            <ToolIcon
              key={key}
              indicator={key === "imageSuggestion" && newImagesIndication}
            >
              <IconButton
                // @ts-ignore
                size={"sm"}
                iconSize={20}
                icon={
                  activeToolkit === key
                    ? TOOLS[key].activeIcon
                    : TOOLS[key].icon
                }
                onClick={() => setActiveToolkit(key)}
                variant={"secondaryLoud"}
              />
            </ToolIcon>
          ))}
      </Toolbar>
      <ToolContent>
        <ActiveContent />
      </ToolContent>
    </ToolkitContainer>
  );
};

export default Toolkit;
