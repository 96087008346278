import React from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { ContentoModal } from "../ContentoModal";
import { closeModal } from "state/actions/ModalActions";
import LinkedInBusinessList from "components/channelConnect/LinkedInBusinessList";

const SelectLinkedinBusinessModal = ({ closeModal, account, ...props }) => {
  const history = useHistory();

  const handleCloseModal = () => {
    closeModal();
    history.push(`/accounts/${account.id}/settings/channels`);
  };

  return (
    <ContentoModal title="" onClose={handleCloseModal}>
      <LinkedInBusinessList {...props} onClose={handleCloseModal} />
    </ContentoModal>
  );
};

const mapStateToProps = state => {
  return {
    account: state.account.data
  };
};

export default connect(mapStateToProps, {
  closeModal: closeModal
})(SelectLinkedinBusinessModal);
