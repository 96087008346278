import styled from "styled-components";
import { Box, Flex } from "rebass/styled-components";
import Button from "../common/Button";
import { Paragraph } from "../common/styles";

export const DashboardGrid = styled(Box)`
  display: grid;
  filter: drop-shadow(0 25px 50px rgba(0, 0, 0, 0.1));
  grid-template-columns: 400px auto;
  grid-gap: 16px;
  overflow: visible;
  margin-bottom: 42px;
`;

export const NewsGrid = styled(Box)`
  display: grid;
  grid-gap: 24px 16px;
  grid-template-columns: repeat(12, 1fr);
`;

export const NewsMainItem = styled(Box)`
  grid-column: 1 / 9;
`;

export const NewsLargeItem = styled(Box)`
  grid-column: 9 / 13;
`;

export const NewsItem = styled(Box)`
  grid-column-end: span 3;
`;

export const NewFeatureContainer = styled(Box)`
  background-color: ${props => props.theme.colors.text01};
  border-radius: 16px;
  box-shadow: 0 25px 50px rgba(0, 0, 0, 0.1), 0 12px 30px rgba(0, 0, 0, 0.08);
  color: white;
  display: grid;
  grid-template-columns: 1fr 340px;
  grid-column-gap: 24px;
  margin: 24px 0;
  padding: 16px 16px 16px 48px;
`;

export const NewFeatureVideo = styled(Box)`
  background-color: ${props => props.theme.colors.grey01};
  border-radius: 8px;
  overflow: hidden;
  height: 192px;
  width: 100%;
`;

export const NewFeatureVideoContainer = styled(Box)`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const NewFeatureThumbnail = styled(Flex)`
  align-items: center;
  border-radius: 8px;
  height: 100%;
  justify-content: center;
  overflow: hidden;

  & svg {
    position: absolute;
    z-index: 1;
  }

  & img {
    height: 100%;
    object-fit: cover;
    width: 100%;
  }

  &:hover {
    cursor: pointer;
    & img {
      opacity: 0.75;
      transition: 0.25s;
    }
  }
`;

export const NewFeatureDescription = styled.p`
  color: white;
  margin: 8px 0;
`;

export const NewFeatureTitle = styled.h2`
  color: white;
  font-size: 32px;
  line-height: 41.6px;
  font-weight: bold;
  margin: 12px 0 8px;
`;

export const FeedbackButton = styled(Button)`
  background-color: white;
  color: ${props => props.theme.colors.text01};

  &:hover,
  &:active {
    background-color: rgba(255, 255, 255, 0.75);
  }

  &:focus {
    outline: none;
    border: none;
    box-shadow: 0 0 0 4px rgba(255, 255, 255, 0.25) !important;
  }
`;

export const DismissButton = styled(Button)`
  background-color: transparent;
  color: rgba(235, 235, 245, 0.6);

  &:hover,
  &:active {
    background-color: transparent;
    color: white;
  }

  &:focus {
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
  }
`;

export const EmptyBox = styled(Flex)`
  align-items: center;
  background-color: white;
  border-radius: 24px;
  box-shadow: 0 12px 24px rgba(17, 17, 17, 0.08);
  flex-direction: column;
  padding: 72px 48px;
`;

export const EmptyDescription = styled(Paragraph)`
  color: ${props => props.theme.colors.text02};
  text-align: center;
`;

export const EmptyBody = styled(Flex)`
  align-items: center;
  flex-direction: column;
  margin: 56px 0;
  text-align: center;
`;

export const EmptyTopic = styled(Box)`
  align-items: center;
  background-color: ${props =>
    props.isFollowed ? "rgba(0, 99, 251, 0.13)" : props.theme.colors.grey01};
  border-radius: 90px;
  cursor: pointer;
  display: inline-grid;
  grid-column-gap: 12px;
  grid-template-columns: 48px 1fr 48px;
  margin: 4px;
  padding: 4px;

  & span {
    flex: 1;
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.colors.text01};
  }

  &:hover {
    background-color: ${props =>
      props.isFollowed ? "rgba(245, 51, 63, 0.13)" : props.theme.colors.grey02};
  }
`;

export const EmptyTopicImage = styled(Box)`
  border-radius: 48px;
  background-color: ${props => props.theme.colors.grey01};
  height: 48px;
  overflow: hidden;
  width: 48px;

  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
`;
