import React from "react";

import {
  IAttachment,
  useComposerState,
  useComposerActions
} from "contextApi/composerContext";
import Tooltip from "components/common/Tooltip";
import { ATTACHMENT_TYPES } from "constants/post";
import IconButton from "components/common/IconButton";
import { AttachmentToolbar } from "components/composerv2/styles";

interface IAttachmentTools {
  size?: number;
  iconSize?: number;
  showBorder?: boolean;
}

const AttachmentTools: React.FC<IAttachmentTools> = ({
  size,
  iconSize,
  showBorder = false
}) => {
  const attachments = useComposerState(state => state.attachments);
  const setAttachmentType = useComposerActions(
    actions => actions.setAttachmentType
  );

  const { type } = attachments["all"];

  const changeAttachmentType = (attachmentType: IAttachment["type"]) => {
    if (type !== attachmentType) {
      // ? add channel as 2nd argument if attachments are added separately for each channel
      setAttachmentType(attachmentType);
    } else {
      // ? add channel as 2nd argument if attachments are added separately for each channel
      setAttachmentType(null);
    }
  };

  return (
    // @ts-ignore
    <AttachmentToolbar showBorder={showBorder}>
      <Tooltip title="Add image">
        <IconButton
          // @ts-ignore
          size={size || 40}
          iconSize={iconSize || 24}
          icon={type === "photo" ? "icon-image-filled" : "icon-image"}
          variant="secondaryLoud"
          onClick={() => changeAttachmentType("photo")}
        />
      </Tooltip>
      <Tooltip title="Add video">
        <IconButton
          // @ts-ignore
          size={size || 40}
          iconSize={iconSize || 24}
          icon={
            type === ATTACHMENT_TYPES.VIDEO ? "icon-video-filled" : "icon-video"
          }
          variant="secondaryLoud"
          onClick={() => changeAttachmentType("video")}
        />
      </Tooltip>
    </AttachmentToolbar>
  );
};

export default AttachmentTools;
