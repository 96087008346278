import React from "react";
import ChannelConnectionCard from "../ChannelConnectionCard";

import iconLinkedIn from "../../../assets/images/icon-linkedIn-circ.png";
import iconFacebook from "../../../assets/images/icon-facebook.png";
import iconFacebookSquare from "../../../assets/images/channel-logo-facebook.jpg";
import iconTwitter from "../../../assets/images/icon-twitter.png";
import iconTwitterSquare from "../../../assets/images/channel-logo-twitter.jpg";
import iconInstagram from "../../../assets/images/icon-instagram-132.png";
//import iconZapierInstagram from "../../../assets/images/icon-zapier-instagram.png";

// TODO: Sprite this
export const serviceList = {
  linkedin_business: {
    name: "LinkedIn Business",
    socialIcon: iconLinkedIn,
    squareIcon: iconLinkedIn,
    disclaimer: "Connect your bussiness’ page with Willow."
  },
  linkedin_profile: {
    name: "LinkedIn Profile",
    socialIcon: iconLinkedIn,
    squareIcon: iconLinkedIn,
    disclaimer: "Connect your personal profile with Willow."
  },
  facebook_page: {
    name: "Facebook Page",
    socialIcon: iconFacebook,
    squareIcon: iconFacebookSquare,
    disclaimer: "You need to have the Admin or Editor role."
  },
  twitter_profile: {
    name: "Twitter Profile",
    socialIcon: iconTwitter,
    squareIcon: iconTwitterSquare,
    disclaimer: "Connect your personal profile with Willow."
  },
  linkedin_page: {
    name: "LinkedIn Page",
    socialIcon: iconLinkedIn,
    squareIcon: iconLinkedIn
  },
  instagram_profile: {
    name: "Instagram Profile",
    socialIcon: iconInstagram,
    squareIcon: iconInstagram,
    disclaimer: "Connect your personal profile with Willow."
  },
  instagram_business: {
    name: "Instagram Business",
    socialIcon: iconInstagram,
    squareIcon: iconInstagram,
    disclaimer:
      "You need to have a Instagram Business account and be a administrator."
  }
};

function AvailableService({
  disabled,
  service,
  channel,
  status,
  onConnect,
  onDisconnect,
  connected
}) {
  return (
    <ChannelConnectionCard
      disabled={disabled}
      status={status}
      onConnect={onConnect}
      onDisconnect={onDisconnect}
      socialIcon={serviceList[service].socialIcon}
      squareIcon={serviceList[service].squareIcon}
      channel={channel}
      avatar={channel ? channel.avatar : null}
      username={channel ? channel.username : serviceList[service].name}
      instruction={
        serviceList[service].disclaimer ? serviceList[service].disclaimer : null
      }
      connected={connected}
    />
  );
}

export default AvailableService;
