import Modal from "react-modal";
import { X } from "styled-icons/feather";
import React, { useCallback } from "react";

import {
  ModalBodyComposer,
  ModalTitleComposer,
  ModalHeaderComposer,
  ModalHeaderActionContainer
} from "./styles";
import { modalStyles } from "../styles";
import useWindowSize from "utils/useWindowSize";
import Switch from "components/common/form/Switch";
import IconButton from "components/common/IconButton";
import { Headline5, Headline6 } from "components/common/styles";

interface IComposerModal {
  title: string;
  isOpen: boolean;
  proMode: boolean;
  onClose: VoidFunction;
  toggleProMode: boolean;
}

const ComposerModal: React.FC<IComposerModal> = ({
  title,
  isOpen,
  proMode,
  onClose,
  children,
  toggleProMode
}: any) => {
  const windowSize = useWindowSize();
  const overlayPadding = 15;
  const styles = modalStyles(proMode ? 1590 : 550) as Modal.Styles;
  styles.content!.padding = 0;
  styles.content!.maxHeight = "95%";
  styles.content!.overflow = "hidden";
  styles.overlay!.overflow = "hidden";
  styles.overlay!.backdropFilter = "blur(5px)";
  styles.overlay!.paddingTop = `${overlayPadding}px`;
  styles.overlay!.background = "rgba(28, 30, 31, 0.4)";

  const onProModeToggle = useCallback(() => {
    toggleProMode();
  }, [toggleProMode]);

  return (
    <Modal
      style={styles}
      isOpen={isOpen}
      ariaHideApp={false}
      onRequestClose={onClose}
      bodyOpenClassName="overflow-hidden"
    >
      <ModalHeaderComposer>
        <ModalTitleComposer as="div">
          <Headline5>{title}</Headline5>
        </ModalTitleComposer>
        {!!toggleProMode && (
          <ModalHeaderActionContainer>
            <Switch
              htmlFor="pro-mode-toggle"
              onToggle={onProModeToggle}
              checked={proMode}
            />
            <Headline6>Pro Mode</Headline6>
          </ModalHeaderActionContainer>
        )}
        <IconButton
          // @ts-ignore
          variant={"secondary"}
          size={"sm"}
          onClick={onClose}
          icon={X}
        />
      </ModalHeaderComposer>
      <ModalBodyComposer
        // @ts-ignore
        proMode={proMode}
        screenHeight={
          typeof windowSize.height !== "undefined"
            ? (windowSize.height - overlayPadding * 2) * 0.95 - 56
            : null
        }
      >
        {children}
      </ModalBodyComposer>
    </Modal>
  );
};

export default ComposerModal;
