import { Flex } from "rebass";
import { NavLink } from "react-router-dom";
import styled, { css } from "styled-components";

import { ZINDEX } from "theme";

import SideNavigation from "components/navigation/SideNavigation";

export const Navigation = styled(SideNavigation)`
  color: ${props => props.theme.colors.text01};
`;

export const AdjustableHandle = styled.div`
  width: 12px;
  /* background: rgba(102, 119, 128, 0.16); */
  min-height: calc(100vh - 80px);
  height: 100%;
  cursor: col-resize;
  margin-left: -6px;
`;

export const AdjustableBar = styled.div`
  z-index: 15;
`;

export const AdjustableBarInfo = styled.p`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: ${props => props.theme.colors.text02};
  text-align: left;
  margin-bottom: 0px;
  strong {
    color: ${props => props.theme.colors.white};
  }
`;

export const SideBarWrapper = styled.div`
  position: relative;
  display: flex;
  z-index: ${ZINDEX.z5};

  max-height: calc(100vh - 64px);
  top: 64px;
  position: sticky;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const SideBarBody = styled.div<{
  isCollapsed: boolean;
  width?: number;
}>`
  position: relative;
  max-width: 480px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 12px;
  background: #f8f9fa;
  border-right: 1px solid rgba(102, 119, 128, 0.16);
  z-index: 10;

  min-width: ${props => {
    if (props.isCollapsed) {
      return css`
        -webkit-transition: width 0.3s ease-in-out;
        -moz-transition: width 0.3s ease-in-out;
        -o-transition: width 0.3s ease-in-out;
        transition: width 0.3s ease-in-out;

        width: 50px;
      `;
    } else {
      return css`
        -webkit-transition: width 0.3s ease-in-out;
        -moz-transition: width 0.3s ease-in-out;
        -o-transition: width 0.3s ease-in-out;
        transition: width 0.3s ease-in-out;

        width: ${props.width ?? 322}px;
      `;
    }
  }};
`;

export const Panel = styled.div`
  margin: 8px 0px;
`;

export const PanelWrapper = styled.div`
  flex-direction: column;
`;

export const NavigationWrapper = styled.div`
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
`;

export const Collections = styled(Flex)`
  flex-direction: column;
  gap: 8px;
  overflow: scroll;
  padding-left: 8px;
  padding-right: 8px;
  max-height: calc(100vh - 290px);
  overflow-x: hidden;
  overflow-y: auto;
`;

export const NavigationItem = styled(NavLink)<{ py?: number; px?: number }>`
  align-items: center;
  border-radius: 8px;
  color: ${props => props.theme.colors.text03};
  display: flex;
  padding: ${props => props.py || 0}px ${props => props.px || 8}px;
  min-height: 32px;
  justify-content: space-between;

  .panel_text {
    color: ${props => props.theme.colors.text02};
  }

  &:hover {
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.blue};
    .panel_text {
      color: ${props => props.theme.colors.white};
    }
  }
  &.active {
    color: ${props => props.theme.colors.text01};
    background-color: ${props => props.theme.colors.grey01};
    font-weight: bold;
    .panel_text {
      color: ${props => props.theme.colors.text01};
    }
  }
`;

export const AccordionContent = styled.div<{ open: boolean }>`
  max-height: ${props => (props.open ? "auto" : "0")};
  transition: all 0.4s ease-in-out;
  overflow: hidden;
`;

export const AccordionButton = styled.button<{
  variant: string;
  collapsed: boolean;
  rotate?: number;
  hover?: boolean;
  px?: number;
  py?: number;
  isCurrent?: boolean;
  isContextMenuActive?: boolean;
}>`
  display: flex;
  flex: 1;
  align-items: center;
  border: 0;
  outline: none;
  background: none;
  cursor: pointer;
  gap: 12px;
  border-radius: 6px;

  width: 24px;
  height: 24px;
  align-items: center;
  justify-content: center;

  padding-top: ${props => props.py || 0}px;
  padding-bottom: ${props => props.py || 0}px;
  padding-right: ${props => props.px || 0}px;
  padding-left: ${props => props.px || 0}px;

  font-weight: 600;
  font-size: 13px;
  line-height: 125%;
  color: ${props => props.theme.colors.text02};

  &:hover {
    background: rgba(217, 241, 255, 0.12);
    color: ${props => props.theme.colors.white};
    z-index: 100;
  }

  span {
    font-size: 18px;
    color: ${props => props.theme.colors.text03};
    ${props =>
      props.hover &&
      !props.isCurrent &&
      // !props.isContextMenuActive &&
      css`
        color: rgba(243, 244, 245, 0.62);
        background: "#D9F1FF1F";
        z-index: 100;
      `}
    ${props =>
      props.isCurrent &&
      css`
        color: ${props => props.theme.colors.black};
      `}
    ${props => {
      const degree = `${props.rotate || 180}deg`;

      if (props.collapsed) {
        return css`
          transform-origin: center;
          transform: rotate(${degree});
          -webkit-transform: rotate(${degree});
          -ms-transform: rotate(${degree});

          -webkit-transition: transform 0.2s ease-in-out;
          -moz-transition: transform 0.2s ease-in-out;
          -o-transition: transform 0.2s ease-in-out;
          transition: transform 0.2s ease-in-out;
        `;
      } else {
        return css`
          transform-origin: center;
          transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -ms-transform: rotate(0deg);

          -webkit-transition: transform 0.2s ease-in-out;
          -moz-transition: transform 0.2s ease-in-out;
          -o-transition: transform 0.2s ease-in-out;
          transition: transform 0.2s ease-in-out;
        `;
      }
    }}
  }

  
`;

export const TopSection = styled(Flex)`
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
`;

export const ExploreButton = styled.button<{
  hover: boolean;
  isCurrent: boolean;
  isContextMenuActive?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  outline: none;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.grey02};
  cursor: pointer;

  &:hover {
    background: rgba(217, 241, 255, 0.12);
    z-index: 100;
  }

  ${props =>
    props.isCurrent &&
    css`
      color: ${props => props.theme.colors.grey03};
      &:hover {
        background: ${props => props.theme.colors.grey02};
        z-index: 100;
      }
    `}

  ${props =>
    props.isContextMenuActive &&
    css`
      background: ${props => props.theme.colors.grey03};
    `}

  span {
    font-size: 18px;
    color: ${props =>
      props.hover ? props.theme.colors.white : props.theme.colors.text02};

    ${props =>
      props.hover &&
      props.isCurrent &&
      css`
        color: ${props => props.theme.colors.text01};
      `}
  }
`;
